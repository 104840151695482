
import productImg01 from "../images/457.png";
import productImg02 from "../images/LHD655.png";

import productImg03 from "../images/LHD655.png";
import productImg04 from "../images/LHD675BG.png";
import productImg05 from "../images/LHD687.png";
import productImg06 from "../images/CL87.png";

import productImg07 from "../images/CJ44.png";
import productImg08 from "../images/CJ45.png";
//ajout------------------------
import productImg10008 from "../images/cl98.png";
import productImg10016 from "../images/RNC7_y.png";
//-----------------------------
import productImg09 from "../images/xl5s.png";
import productImg10 from "../images/xl7s.png";

import productImg11 from "../images/on5.jpg";
import productImg12 from "../images/atmos.png";
import productImg13 from "../images/SH7Q.png";
import productImg14 from "../images/SQC1.png";
import productImg1401 from "../images/SQC1.png";
import productImg15 from "../images/SN4.png";
import productImg1501 from "../images/SN4.png";
import productImg16 from "../images/RNC7_y.png";


import productImg17 from "../images/RNC9_y.png";
import productImg18 from "../images/XG7QBK_y.png";
import productImg19 from "../images/SN7Y.png";
import productImg20 from "../images/CK99.png";

import productImg21 from "../images/rm2.png";
import productImg21001 from "../images/CL65B.jpg";

import productImg22 from "../images/SK1.png";
import productImg23 from "../images/qp5.png";
import productImg24 from "../images/S1.png";

import productImg25 from "../images/CG1.png";
import productImg26 from "../images/debout.png";

import productImg27 from "../images/lg_1.png";
import productImg28 from "../images/lg_1.png";

import productImg29 from "../images/Z07_DFB512FP_dz_7.jpg";
import productImg30 from "../images/Z01_DFB512FP_dz_1.jpg";

import productImg31 from "../images/NEOCHEF.png";
//import productImg32 from "../images/microLg/1100_1.avif";
//import productImg33 from "../images/MH8265DIS.png";


import productImg34 from "../images/lg_refraig_7.png";
import productImg35 from "../images/SLQ.png";
import productImg36 from "../images/222.png";

import productImg37 from "../images/322.png";
import productImg38 from "../images/BOTTOM.png";
import productImg39 from "../images/GC-F411ELDM_2.png";
import productImg40 from "../images/392.png";

import productImg41 from "../images/492.png";
import productImg42 from "../images/689.png";
import productImg43 from "../images/DOOR.png";
import productImg44 from "../images/REFGLT682HQCL_2.png";
import productImg45 from "../images/REFGLC682HLCL.png";

import productImg46 from "../images/722.png";
import productImg47 from "../images/lg_refraig_9.png";
import productImg48 from "../images/lg_refraig_1.png";
import productImg49 from "../images/RC67.png";
import productImg50 from "../images/ref68.png";
import productImg51 from "../images/RF3.png";

import productImg52 from "../images/RF4.png";
import productImg53 from "../images/257.png";
import productImg54 from "../images/RF6_1.png";


//import productImg55 from "../images/A.png";
//import productImg56 from "../images/A.png";
import productImg57 from "../images/B.png";

import productImg58 from "../images/large01.png";
import productImg59 from "../images/large01.png";
import productImg60 from "../images/large01.png";

import productImg61 from "../images/dual.png";
//import productImg62 from "../images/split_5.png";
import productImg63 from "../images/split_3.png";
import productImg64 from "../images/split_3.png";
import productImg65 from "../images/split_3.png";
import productImg66 from "../images/24000BTUANT.png";

import productImg67 from "../images/24000BTUANT.png";
import productImg68 from "../images/24000BTUANT.png";
import productImg69 from "../images/24000BTUANT.png";
import productImg70 from "../images/FLOOR.png";
import productImg71 from "../images/FLOOR.png";
import productImg72 from "../images/100KBTU.png";


import productImg73 from "../images/LM500.jpg";
import productImg74 from "../images/LM500.jpg";
import productImg75 from "../images/LM500.jpg";

import productImg76 from "../images/LM500.jpg";
import productImg77 from "../images/LM500.jpg";
import productImg78 from "../images/OLED55C1.jpg";


import productImg79 from "../images/LM500.jpg";

import productImg791 from "../images/LM500.jpg";
import productImg792 from "../images/LM500.jpg";
import productImg801 from "../images/LM500.jpg";
//import productImg841 from "../images/LM500.jpg";
import productImg891 from "../images/LM500.jpg";
import productImg901 from "../images/LM500.jpg";
import productImg921 from "../images/LM500.jpg";
//import productImg2101 from "../images/LM500.jpg";
import productImg2102 from "../images/LM500.jpg";
import productImg2103 from "../images/LM500.jpg";
//import productImg2104 from "../images/LM500.jpg";




import productImg80 from "../images/LM500.jpg";
import productImg85 from "../images/OLED55C1.jpg";
import productImg86 from "../images/oled48.jpg";
import productImg87 from "../images/oled48.jpg";
import productImg88 from "../images/LM500.jpg";
import productImg89 from "../images/oled55.jpg";
import productImg90 from "../images/LM500.jpg";

import productImg91 from "../images/LM500.jpg";
import productImg92 from "../images/OLED77.jpg";


import productImg931 from "../images/647f4191b6bb6.png";
import productImg93 from "../images/p2t.png";
import productImg94 from "../images/41M52FNx6L.jpg";
import productImg95 from "../images/lg_machine_5.png";

//import productImg96 from "../images/lg_machine_3.png";
import productImg97 from "../images/lg_machine_4.png";
import productImg98 from "../images/wash.png";
import productImg981 from "../images/medium01.jpg";

import productImg991 from "../images/f4v5vgp2t.png";
import productImg99 from "../images/f4v5vgp2t.png";
//import productImg100 from "../images/Dryer.png";
//import productImg101 from "../images/lv1.png";
//import productImg102 from "../images/lg_machine_3.png";
import productImg103 from "../images/rh90.png";
import productImg104 from "../images/Z09_Z9.png";

import productImg105 from "../images/2T2C.png";
import productImg106 from "../images/2T2C.png";
import productImg107 from "../images/2T2C.png";
import productImg1071 from "../images/13-10kg.png";
import productImg108 from "../images/mfc.png";

import productImg109 from "../images/air_1.png";

import productImg110 from "../images/AX3100G.png";
import productImg111 from "../images/320W.png";
import productImg112 from "../images/HS218.png";
//ajout---------
//import productImg1112 from "../images/320W.png";
import productImg1113 from "../images/HS205.jpg";

//------------

import productImg1123 from "../images/SPEAKER.jpeg";

//import productImg114 from "../images/96LAA.jpg";
import productImg115 from "../images/180SH.png";
import productImg116 from "../images/250FRUP2.jpeg";
import productImg1161 from "../images/250FRUP2.jpeg";

import productImg117 from "../images/260.png";
import productImg118 from "../images/260.png";
//ajout---------------------
import productImg11181 from "../images/260.png";
  //--------------------------
import productImg1181 from "../images/260.png";
import productImg119 from "../images/66DD_1.png";
import productImg120 from "../images/66DD_1.png";

import productImg121 from "../images/66dd4sa.png";
import productImg122 from "../images/hisense_refraig_9.png";
import productImg123 from "../images/FL37FC.png";
import productImg124 from "../images/FL50WHITE.jpeg";

import productImg125 from "../images/FL50WHITE.jpeg";
import productImg126 from "../images/show.jpg";

import productImg127 from "../images/Hisense 60CM WALL MOUNTED COOKER HOOD HH060TASS.jpg";
import productImg1271 from "../images/5BURNERS.png";
//import productImg128 from "../images/HBO6020203.jpeg";
import productImg129 from "../images/微信图片_20240323111928.jpg";

import productImg130 from "../images/HHU60CEEC.jpeg";
//ajout---------------
//import productImg1129 from "../images/HBO6020203.jpeg";
//import productImg1128 from "../images/HHO60TASS.png";
//-----------------

//import productImg131 from "../images/vaisselle_hisense.jpg";
import productImg132 from "../images/Hisense 6010.png";
import productImg133 from "../images/Hisense 7012.png";

import productImg134 from "../images/8014T.png";
import productImg135 from "../images/Hisense 8043 8 5 kg.png";
//ajout-------------------------
import productImg10035 from "../images/GN-Y331SLBB.avif";
//------------------------------
import productImg136 from "../images/Hisense 1043.png";
import productImg137 from "../images/Hisense 1043 10 6 kg.png";

import productImg138 from "../images/Hisense 5S 12Kg.png";
import productImg1381 from "../images/Hisense 5S 12Kg.png";
import productImg139 from "../images/machineHisense/WM 7.5KG TWIN TUB WSQB753.jpeg";
import productImg13911 from "../images/machineHisense/WM 5KG TWIN TUB WSPA503.jpeg"

import productImg140 from "../images/h20.png";
import productImg14111 from "../images/microwave/28L BLACK MINI OVEN H28TOBKPKL16.jpeg";
import productImg14012 from "../images/microwave/MWHIH26MOS5H WITH HANDLE.jpeg";
import productImg14013 from "../images/microwave/MWHIH34MOBS10HG BLACK GRILL.jpeg";
//import productImg14014 from "../images/h20.png";
import productImg141 from "../images/h25.png";


import productImg142 from "../images/046dr.png";
import productImg143 from "../images/93dr.png";
import productImg144 from "../images/121.png";

import productImg145 from "../images/REF20SA.jpg";
import productImg146 from "../images/172DRHIESENSE.jpg";
import productImg147 from "../images/REF20A.jpg";
import productImg148 from "../images/REF205AA.jpg";

import productImg149 from "../images/REF205REDA.jpg";
import productImg150 from "../images/REF60.png";
import productImg151 from "../images/411_1.png";
import productImg152 from "../images/35DCBHISENSE.jpg";
import productImg1521 from "../images/49DR.png";

import productImg153 from "../images/308DR.png";
import productImg154 from "../images/REF60.png";
import productImg155 from "../images/66WR.jpg";
import productImg156 from "../images/RC67.png";
import productImg157 from "../images/RC67.png";
import productImg158 from "../images/rc67bg.png";

import productImg159 from "../images/hisense_2.png";
import productImg160 from "../images/ref76ws.png";
import productImg161 from "../images/SILVERA.jpg";
import productImg162 from "../images/ref68.png";

import productImg163 from "../images/hisense_refraig_1.png";
import productImg164 from "../images/ref73W.png";
import productImg167 from "../images/hisense_1.png";

import productImg168 from "../images/hisense_1.png";
//import productImg169 from "../images/hisense_1.png";
import productImg170 from "../images/hisense_1.png";


//import productImg171 from "../images/INVERTER900BTU.png";
import productImg172 from "../images/INVERTER900BTU.png";
import productImg173 from "../images/INVERTER900BTU.png";
import productImg174 from "../images/hisense_split_6.png";
import productImg175 from "../images/hisense_split_6.png";
//import productImg176 from "../images/hisense_split_6.png";

import productImg177 from "../images/hisense_split_4.png";
//import productImg178 from "../images/hisense_split_4.png";
//import productImg179 from "../images/FLOOR.png";
import productImg180 from "../images/FLOOR.png";
//import productImg181 from "../images/FLOOR.png";
import productImg182 from "../images/FLOOR.png";

//import productImg183 from "../images/FLOOR.png";
//import productImg184 from "../images/FLOOR.png";


import productImg185 from "../images/tv_hisense.png";
import productImg186 from "../images/tv_hisense.png";
import productImg187 from "../images/tv_hisense.png";

//import productImg188 from "../images/tv_hisense.png";
import productImg189 from "../images/tv_hisense.png";
import productImg190 from "../images/tv_hisense.png";


import productImg191 from "../images/tv_hisense.png";
import productImg192 from "../images/tv_hisense.png";
import productImg193 from "../images/tv_hisense.png";
import productImg194 from "../images/tv_hisense.png";
import productImg195 from "../images/tv_hisense.png";
import productImg196 from "../images/tv_hisense.png";


import productImg197 from "../images/tv_hisense.png";
import productImg198 from "../images/tv_hisense.png";
import productImg199 from "../images/tv_hisense.png";
import productImg200 from "../images/tv_hisense.png";
import productImg201 from "../images/tv_hisense.png";
import productImg202 from "../images/tv_hisense.png";

import productImg203 from "../images/tv_hisense.png";
import productImg204 from "../images/quantum_B.png";
import productImg205 from "../images/tv_hisense.png";
import productImg206 from "../images/135.png";

import productImg207 from "../images/laser.png";

//ajout tv LG 5 fois
import productImg209 from "../images/LM500.jpg";
import productImg210 from "../images/LM500.jpg";
//import productImg211 from "../images/LM500.jpg";
import productImg212 from "../images/LM500.jpg";
import productImg213 from "../images/LM500.jpg";


// corredo
import productImg214 from "../images/1- 60x60 Ceramic Grey_11.jpg";
import productImg215 from "../images/1- 60x60 Ceramic Grey_4.jpg";
import productImg216 from "../images/1- 60x60 Ceramic Grey_10.jpg";
import productImg217 from "../images/corredo/corredo-2-plaques-COR-2VTC-BH.png";
import productImg218 from "../images/6--H9050-Encastre-CeramicA.png";
import productImg219 from "../images/6--H9050-Encastre-CeramicA.png";
import productImg220 from "../images/1- 60x60 Ceramic Grey_onze.jpg";
import productImg221 from "../images/1- 60x60 Ceramic Grey_9.jpg";
import productImg222 from "../images/corre_oven_2.png";
//import productImg21511 from "../images/corredo/"
//import productImg223 from "../images/corre_oven_2.png";
//import productImg224 from "../images/corre_oven_2.png";
import productImg225 from "../images/corre_oven_2.png";
import productImg226 from "../images/1- 60x60 Ceramic Grey_5.jpg";
import productImg227 from "../images/corredo/WINECOOLERSHELF.png";

//Paco
import productImg228 from "../images/paco_stab_2.jpg";
import productImg2281 from "../images/paco_stab_2.jpg";
//import productImg2282 from "../images/paco_stab_2.jpg";
import productImg2283 from "../images/paco_stab_2.jpg";
import productImg2284 from "../images/paco_stab_2.jpg";
import productImg2285 from "../images/paco_stab_3.jpg";

// meubles

//import productImg230 from "../images/double-sofa-01.png";
//import productImg231 from "../images/double-sofa-02.png";
//import productImg232 from "../images/double-sofa-03.png";
//import productImg233 from "../images/single-sofa-03.jpg";

//import productImg234 from "../images/single-sofa-02.jpg";

//import productImg235 from "../images/arm-chair-01.jpg";
//import productImg236 from "../images/arm-chair-02.jpg";
//import productImg237 from "../images/arm-chair-03.jpg";

//perkins

import productImg250 from "../images/perkins2.png";
import productImg251 from "../images/Perk2.jpeg"
import productImg252 from "../images/perkins3.png";
import productImg253 from "../images/perkins4.png";
import productImg254 from "../images/perkins5.png";

import productImg255 from "../images/saco2.png";
import productImg256 from "../images/saco1.png";



//Banner LG
import SliderAudioLg from "../images/l44.jpg";
import SliderTvLg from "../images/l37.jpg";
import SliderMicroLg from "../images/l64.jpg";
import SliderRefLg from "../images/g19.jpg";
import SliderMachineLg from "../images/g7.jpg";
import SliderCongelateurLg from "../images/h43.jpg";
import SliderLaveVaisselleLg from "../images/banner/p2.jpg";
import SliderSplitLg from "../images/l59.jpg";
import SliderCuisiniereLg from "../images/l64.jpg";

//banner Hisense
import SliderAudioHisense from "../images/hisense-2-1ch-soundbar-with-wireless-subwoofer-hs218.jpg";
import SliderTvHisense from "../images/20240530-banner.jpg";
import SliderMicroHisense from "../images/hisense-freestanding-microwave-h28mobs8hguk.jpg";
import SliderRefHisense from "../images/h47.jpg";
import SliderMachineHisense from "../images/hisense-10kg-front-load-washer-and-dryer-wdqy1014evjm.jpg";
import SliderCongelateurHisense from "../images/hisense-198l-chest-freezer-bd-199.jpg";
//import SliderLaveVaisselleHisense from "../images/hisense-laser-tv-4k-picture-quality.jpg";
import SliderSplitHisense from "../images/hisense-silentium-pro-split-air-conditioner.jpg";
import SliderCuisiniereHisense from "../images/hisense-induction-hob-i6433cb.jpg";
import SliderAirHisense from "../images/h35.jpg";

//Banner Corredo
//import SliderCorredo from "../images/hisense-laser-tv-4k-picture-quality.jpg";

//Banner Paco
import SliderPaco from "../images/hisense-laser-tv-4k-picture-quality.jpg";


const products = [
  {
    id: "01",
    productName: "LHD 71C speakER0 ",
    imgUrl: productImg01,
    category: "LG audio",
    price: 400,
    slider: SliderAudioLg,
    
    shortDesc:
      "Son puissant 1200W Lecture de DVD/CD Enregistrement et lecture directs USB",
    description:
      "LIEN SIMPLE	OuiAjustement du niveau des enceintes	Oui Distance des haut-parleurs (délai de canal)	OuiTonalité d'essai	Oui Muet	OuiDélai de synchronisation A/V (délai audio)	Oui Variateur	OuiDormir	Oui Volume à la télévision	OuiEnregistrement direct USB	Oui Optique (touche directe)	OuiContrôle de plage dynamique - Marche/Arrêt	Oui Karaoké - Volume micro/Volume écho	OuiFanfare	Oui Notation	OuiAnnuleur hurlant	Oui",
    reviews: [
      {
        rating: 4.7,
        text: "Profitez d'un son puissant avec un canal 5.1 de 1 200 watts et d'un son exquis avec des basses, fourni par le double caisson de basses.",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "02",
    productName: "5.1ch DVD Home Theatre (LHD457) 300W",
    imgUrl: productImg02,
    category: "LG audio",
    price: 300,
    slider: SliderAudioLg,
    shortDesc:
      "1000W 5.1 Ch. Système de cinéma maison Lecture de DVD et CD Mise à l'échelle des disques DVD jusqu'à 1080P Lecture de fichiers vidéo USB",
    description:
      "Principal	360 X 60,5 X 299Haut-parleur avant	250x1100x250 Haut-parleur central	260x93x74Haut-parleur arrière	250x1100x250 Caisson de basses	191x388,5x318 AMPLIFICATEUR CH	5.1PUISSANCE DE SORTIE - Totale	1000W Puissance de sortie – Avant L/R	167WX2Puissance de sortie - Centre	167W Puissance de sortie - Surround L/R	167WX2Puissance de sortie - Caisson de basses	",
    reviews: [
      {
        rating: 4.8,
        text: "Lire des vidéos directement depuis un périphérique de stockage USB",
      },
      {
        rating: 4.8,
        text: "Découvrez des basses puissantes grâce à une matrice sonore spécialement réglée. Bass Blast réalise une qualité sonore de basses dynamiques et profondes.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "03",
    productName: "5.1ch DVD Home Theatre (LHD655) 1000W",
    imgUrl: productImg03,
    category: "LG audio",
    price: 400,
    slider: SliderAudioLg,
    shortDesc:
      "1000W 5.1 Ch. Système de cinéma maison Lecture de DVD et CD Mise à l'échelle des disques DVD jusqu'à 1080P Lecture de fichiers vidéo USB",
    description:
      "PHYSIQUE - TAILLE (LXHXP)MM Principal	360 X 60,5 X 299Haut-parleur avant	250x1100x250 Haut-parleur central	260x93x74Haut-parleur arrière	250x1100x250 Caisson de basses	191x388,5x318 AMPLIFICATEUR CH	5.1PUISSANCE DE SORTIE - Totale	1000W Puissance de sortie – Avant L/R	167WX2Puissance de sortie - Centre	167W Puissance de sortie - Surround L/R	167WX2Puissance de sortie - Caisson de basses	167 W (passif) CONCEPTION (PRINCIPAL) Armoire avant et arrière (plateau)",
    reviews: [
      {
        rating: 4.6,
        text: "Lire des vidéos directement depuis un périphérique de stockage USB",
      },
      {
        rating: 4.9,
        text: "Découvrez des basses puissantes grâce à une matrice sonore spécialement réglée. Bass Blast réalise une qualité sonore de basses dynamiques et profondes.",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "04",
    productName: "LHD675 DVD Home Theater System 1000W 4.2Ch Dualsubwoofers built_in",
    imgUrl: productImg04,
    category: "LG audio",
    price: 400,
    slider: SliderAudioLg,
    shortDesc:
      "1000W 4.2Ch. Cinéma maison Lecture de DVD/CD Deux caissons de basses intégrés Gain de place et installation simple Veille Bluetooth Diffusion audio sans fil depuis les téléviseurs LG",
    description:
      "AMPLIFICATEUR/HAUT-PARLEURS Puissance totale	1000W RMSCanaux audio	4.2 Puissance de sortie - Avant	167 L x 4Puissance de sortie - Caisson de basses	167 L x 2 CAPACITÉS DE LECTURE DE DISQUE DVD ± R/RW	OuiDVD (PAL)	Oui (Région 4) CD-R/-RW	OuiDTS-CD	Oui CD audio	Oui FORMAT DE DÉCODAGE VIDÉO Décodage vidéo USB	VOB/WMV/Xvid CAPACITÉS VIDÉO Mise à l'échelle de la résolution	Oui (jusqu'à 1080p Full HD)",
    reviews: [
      {
        rating: 4.8,
        text: "Utilisez votre propre média (entrée USB) Grâce au port USB intégré, vous pouvez lire du contenu vidéo et audio directement depuis votre périphérique de stockage USB",
      },
      {
        rating: 4.8,
        text: "Connectez simplement votre système à votre téléviseur LG et profitez d'un son puissant sans fil via Bluetooth®.",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "05",
    productName: "DVD HOME THEATER LHD687BG",
    imgUrl: productImg05,
    category: "LG audio",
    price: 430,
    slider: SliderAudioLg,
    shortDesc:
      "4,2 canaux et 1 250 watts Deux caissons de basses intégrés Gain de place et installation simple Diffusion de musique via Bluetooth Synchronisation du son du téléviseur",
    description:
      "ENTRÉE ET SORTIE (AVANT) Afficher	FLDUSB	1 Micro	2Entrée audio	Oui ENTRÉE ET SORTIE (ARRIÈRE) Sortie vidéo (composite)	OuiEntrée audio (Audio G/D)	Oui Entrée audio (optique)	1HDMI (sortie)	1 FM	OuiBorne de haut-parleur (composition du canal)	Avant (2) Entourage (2) Woofer (2) Borne de haut-parleur (type de borne)	Avant (à ressort) Srround (à ressort) Woofer (LAN) COMMODITÉ LIEN SIMPLE	OuiAjustement du niveau des enceintes	Oui Distance des haut-parleurs (délai de canal)	OuiTonalité d'essai	Oui Muet	OuiDélai de synchronisation A/V (délai audio)	Oui Variateur	OuiDormir	Oui Volume à la télévision	OuiEnregistrement direct USB	Oui (les suffixes EU et CIS ne le prennent pas en charge) Son TV (touche directe) / Connexion son TV (activation/désactivation automatique PWR par optique)	OuiSynchronisation du son du téléviseur (optique / Bluetooth)	Oui Contrôle de la plage dynamique	OuiBluetooth (réception/émission)	Oui KARAOKÉ Volume du micro / Volume de l'écho	OuiFanfare	Oui Notation	OuiSuppresseur de voix sur CD",
    reviews: [
      {
        rating: 4.6,
        text: "Écoutez la radio ou lisez un DVD ou un CD connecté via un câble Aux ou HDMI et USB. Avec diverses options de connectivité, configurez votre enceinte pour répondre à tous vos besoins de divertissement.",
      },
      {
        rating: 4.9,
        text: "Amenez le son de votre téléviseur à un niveau supérieur. Connectez simplement votre téléviseur LG via Bluetooth® et entendez la différence.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "06",
    productName: "2350W, Mini Audio MULTI COLOR LIGHTING PARTY ACCELERATOR KARAOKE CL87 speakER0",
    imgUrl: productImg06,
    category: "LG audio",
    price: 425,
    slider: SliderAudioLg,
    shortDesc:
      "2350 watts Éclairage multicolore Accélérateur de fête Application DJ Étoile du karaoké Lien de fête sans fil",
    description:
      "GÉNÉRAL Pouvoir	2350 wattsUSB	2 Bluetooth	OuiDJ automatique	Oui Lumières LED rythmées	OuiConsole horizontale	Oui Accélérateur de fête+	OuiSynchronisation TV	Oui Boucle DJ	OuiNavigateur de dossiers	Oui Lien de fête sans fil	Oui ENTRÉES AUDIO USB	Oui 2)Portable dans	Oui Entrée micro	Oui 2) HAUT-PARLEURS DE PUISSANCE DE SORTIE Haut-parleurs G/D	1 175 watts x 2 ANTENNE RADIO AM/FM	OuiMémorisateur de station	Oui FONCTION KARAOKÉ Micro de contrôle du volume. (Dans l'unité principale)	OuiEffets vocaux	Oui Voice Eliminator pour les chansons (Voice Canceller)	OuiChangeur de clé	Oui Mode écologique	Oui",
    reviews: [
      {
        rating: 4.6,
        text: "Amusez-vous en utilisant une variété d'effets sonores DJ directement depuis votre smartphone.",
      },
      {
        rating: 4.9,
        text: "Les lumières colorées clignotent en synchronisation avec la musique pour ajouter de l'excitation et de l'atmosphère aux fêtes.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "07",
    productName: "MINI CHAINE XBOOM CJ44",
    imgUrl: productImg07,
    category: "LG audio",
    price: 300,
    slider: "https://www.lg.com/africa/images/LG.com_HeroBanner_XBOOM%20Go1600x800px_%20Desktop%20.jpg",
    shortDesc:
      "480 W, CD, Bluetooth, FM 2 ports USB Fonctionnalités karaoké",
    description:
      "AMPLIFICATEUR Puissance de sortie - Avant	240 W x 2Sélecteur de fonctions - CD/DVD	Oui Non Sélecteur de fonction - Tuner	OuiSélecteur de fonction - AUX1	Oui Sélecteur de fonction - USB1	OuiSélecteur de fonction - USB2	Oui Sélecteur de fonction - Entrée portable	OuiSélecteur de fonction - Bluetooth	Oui INTERFACE Entrée audio - USB 1	Oui (avant)Entrée audio - USB 2	Oui (avant) Entrée audio - Entrée portable	Oui (avant)Entrée audio - AUX IN 1/2 (L/R)	1 (arrière) Clé de verrouillage de porte (disque)	OuiSortie haut-parleur - Avant G/D	1/1 Antenne radio - FM	OuiFonction Karaoké - Volume du micro (sur le poste principal)	Oui Fonction Karaoké - Prise Micro	1EA (Φ6.3)Fonction Karaoké - Echo (sur RCU)	Oui AFFICHER Type d'affichage	CM4740Affichage - Démo	Oui Affichage - Gradateur	Oui SON Égaliseur - Standard	OuiÉgaliseur - Pop	Oui EQ - Classique	OuiEQ - Rock	Oui EQ - Jazz	OuiEQ - Explosion de basses	Oui QE - Football	OuiEQ local (latin/brésil/etc.) - Dangdut	Oui EQ local (latin/brésil/etc.)-arabe	OuiEQ local (latin/brésil/etc.) - Afro Hip-hop	Oui EQ local (latin/brésil/etc.) - Inde	OuiEQ local (latin/brésil/etc.) - Regueton	Oui EQ local (latin/brésil/etc.) - Merengue	OuiEQ local (latin/brésil/etc.) - Salsa	Oui EQ local (latin/brésil/etc.) - Samba	OuiEQ local (latin/brésil/etc.) - Axe	Oui EQ local (latin/brésil/etc.) - Forro	OuiEQ local (latin/brésil/etc.) - Funk	Oui EQ local (latin/brésil/etc.) - Sertanejo	OuiMulti-Jukebox	Oui DJ automatique	Oui",
    reviews: [
      {
        rating: 4.6,
        text: "Chantez sur n'importe quel morceau ! Voice Canceller réduit les voix de la chanson existante, afin que votre voix puisse être entendue plus clairement. Key Changer ajuste la hauteur de la chanson en fonction de votre voix !",
      },
      {
        rating: 4.9,
        text: "Avec deux ports USB, vous pouvez avoir plus de musique à portée de main.",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "08",
    productName: "XBOOM 720W HI-FI ENTERTAINEMENT SYSTEM CJ45",
    imgUrl: productImg08,
    category: "LG audio",
    price: 350,
    slider: SliderAudioLg,
    shortDesc:
      "Sortie puissante 720 W Lien de fête sans fil DJ automatique Étoile du karaoké Application Bluetooth LG Multi-Jukebox",
    description:
      "AMPLIFICATEUR Puissance de sortie totale	720WPuissance de sortie du haut-parleur avant	240 W x 2 Puissance de sortie du caisson de basses	240W COMMODITÉ Prise en charge des applications à distance Bluetooth (iOS/Android)	OuiEnregistrement direct USB	Oui Créateur de karaoké	OuiMultipoint	Oui SÉLECTEUR DE FONCTION CD	OuiTuner	Oui AUX1	OuiUSB1	Oui USB2	OuiEntrée portative	Oui Bluetooth	Oui",
    reviews: [
      {
        rating: 4.6,
        text: "Commencez la fête sans attendre. Même lorsque la musique est envoyée via Bluetooth au système de couchage, l'unité se réveille pour commencer la fête.",
      },
      {
        rating: 4.9,
        text: "Un système intelligent qui peut détecter votre appareil mobile. Envoyez facilement de l'audio directement à votre système.",
      },
    ],
    avgRating: 4.7,
  },
  //ajout-------------------
  {
    id: "10008",
    productName: "XBOOM ENTERTAINEMENT SYSTEM W/KAROKE & DJ EFFECTS CL98",
    imgUrl: productImg10008,
    category: "LG audio",
    price: 750,
    slider: "https://www.lg.com/africa/images/LG.com_HeroBanner_XBOOM%20Go1600x800px_%20Desktop%20.jpg",
    shortDesc:
      "Sortie puissante 720 W Lien de fête sans fil DJ automatique Étoile du karaoké Application Bluetooth LG Multi-Jukebox",
    description:
      "AMPLIFICATEUR Puissance de sortie totale	720WPuissance de sortie du haut-parleur avant	240 W x 2 Puissance de sortie du caisson de basses	240W COMMODITÉ Prise en charge des applications à distance Bluetooth (iOS/Android)	OuiEnregistrement direct USB	Oui Créateur de karaoké	OuiMultipoint	Oui SÉLECTEUR DE FONCTION CD	OuiTuner	Oui AUX1	OuiUSB1	Oui USB2	OuiEntrée portative	Oui Bluetooth	Oui",
    reviews: [
      {
        rating: 4.6,
        text: "Commencez la fête sans attendre. Même lorsque la musique est envoyée via Bluetooth au système de couchage, l'unité se réveille pour commencer la fête.",
      },
      {
        rating: 4.9,
        text: "Un système intelligent qui peut détecter votre appareil mobile. Envoyez facilement de l'audio directement à votre système.",
      },
    ],
    
    avgRating: 4.7,
  },
  //------------------------

  {
    id: "09",
    productName: "BLUETOOTH SPEAKER XL5S PORTABLE SPEACKER",
    imgUrl: productImg09,
    category: "LG audio",
    price: 425,
    slider: "https://www.lg.com/africa/images/LG.com_HeroBanner_XBOOM%20Go1600x800px_%20Desktop%20.jpg",
    shortDesc:
      "Sortie 200 W et woofer de 6,5 pouces Éclairage annulaire multicolore et éclairage stroboscopique double IPX4 12 heures d'autonomie",
    description:
      "AMPLIFICATEUR Puissance de sortie	200 W / 2,1 canaux (WF : 100 W, TV 50 W x 2 canaux)Sélecteur de fonction - USB1	Oui Sélecteur de fonction - Bluetooth	Oui INTERFACE Entrée audio - USB 1	OuiEntrée audio - Entrée micro (6,3 mm)	Oui Entrée audio - Entrée guitare (6,3 mm)	OuiFonction Karaoké - Volume du micro	Oui Fonction Karaoké - Prise Micro	OuiFonction Karaoké - MIC ECHO	Oui SON ÉGALISEUR - EQ utilisateur	OuiÉGALISEUR - Standard	Oui ÉGALISEUR - Boost sonore",
    reviews: [
      {
        rating: 4.6,
        text: "12 heures d'autonomie LG XBOOM XL5S a le pouvoir de faire durer votre fête. Profitez de votre fête sans avoir à vous arrêter et à charger.",
      },
      {
        rating: 4.9,
        text: "LG XBOOM XL5S répond à un indice de résistance à l'eau IPX4 ; il peut résister aux éclaboussures d'eau.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "10",
    productName: "BLUETOOTH SPEAKER XL7S PORTABLE SPEACKER",
    imgUrl: productImg10,
    category: "LG audio",
    price: 625,
    slider: "https://www.lg.com/africa/images/LG.com_HeroBanner_XBOOM%20Go1600x800px_%20Desktop%20.jpg",
    shortDesc:
      "Sortie 250 W et woofer de 8 pouces – Remplissez la salle avec un son énorme Éclairage dynamique à pixels et éclairage annulaire multicolore - Illuminez la fête Poignée télescopique et roues – Emportez-le partout 18 heures d'autonomie - Profitez de la musique à tout moment Entrée micro et guitare – Chantez au rythme de la musique IPX4 – Gérez quelques éclaboussures",
    description:
      "AMPLIFICATEUR Puissance de sortie	250 W / 2,1 canaux (WF : 130 W, TV 60 W x 2 canaux)Sélecteur de fonction - USB1	Oui Sélecteur de fonction - Bluetooth	Oui INTERFACE Entrée audio - USB 1 OuiEntrée audio - Entrée micro (6,3 mm)	Oui Entrée audio - Entrée guitare (6,3 mm)	OuiFonction Karaoké - Volume du micro	Oui Fonction Karaoké - Prise Micro	OuiFonction Karaoké - MIC ECHO	Oui SON ÉGALISEUR - EQ utilisateur	Oui (application uniquement)ÉGALISEUR - Standard	Oui ÉGALISEUR - Boost sonore",
    reviews: [
      {
        rating: 4.6,
        text: "20 heures d'autonomie Le LG XBOOM XL7S a une longue durée de vie de la batterie, vous pouvez donc mettre de la musique à tout moment sans vous inquiéter.",
      },
      {
        rating: 4.9,
        text: "LG XBOOM XL7S répond à un indice de résistance à l'eau IPX4 ; il peut résister aux éclaboussures d'eau.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "11",
    productName: "XBOOM ON5 SYSTEM AUDIO HIGH POWER",
    imgUrl: productImg11,
    category: "LG audio",
    price: '',
    slider: "https://www.lg.com/africa/images/LG.com_HeroBanner_XBOOM%20Go1600x800px_%20Desktop%20.jpg",
    shortDesc:
      "Super amplification des basses Éclairage multicolore Stroboscope de fête Application DJ et DJ Pad Karaoké Star et contrôle du son vocal Économiseur de fête",
    description:
      "AMPLIFICATEUR Sélecteur de fonctions - CD/DVD	Oui/-Sélecteur de fonction - FM	Oui Sélecteur de fonction - USB1	OuiSélecteur de fonction - USB2	Oui Sélecteur de fonction - Bluetooth	Oui INTERFACE Entrée audio - USB 1	OuiEntrée audio - USB 2	Oui Entrée audio - Entrée guitare	1EA (Φ6.3)Clé de verrouillage de porte (disque)	Oui Fonction Karaoké - Volume du micro (sur le poste principal)	OuiFonction Karaoké - Prise Micro	1EA (Φ6.3) Fonction Karaoké - MIC ECHO (sur RCU)	Oui AFFICHER Type d'affichage	14 segments / 8 chiffresAffichage - Démo	Oui Affichage - Gradateur	Oui SON ÉGALISEUR - EQ utilisateur	OuiÉGALISEUR - EQ Cluster2	Oui ÉGALISEUR - Standard	OuiÉGALISEUR - Pop	Oui ÉGALISEUR - Classique	OuiÉGALISEUR - Rock	Oui ÉGALISEUR - Jazz	OuiÉGALISEUR - Bass Blast	Oui ÉGALISEUR - Football	OuiSPECIAL EQ (Latin/Brésil/etc.) - Dangdut	Oui SPECIAL EQ (latin/brésil/etc.) – arabe	OuiSPECIAL EQ (Latin/Brésil/etc.) - Afro Hip-hop	Oui SPECIAL EQ (latin/brésil/etc.) – Inde	OuiSPECIAL EQ (Latin/ Brésil/ etc.) - Regueton	Oui SPECIAL EQ (Latin/ Brésil/ etc.) - Merengue	OuiSPECIAL EQ (Latin/Brésil/etc.) - Salsa	Oui SPECIAL EQ (Latin/Brésil/etc.) - Samba	OuiSPECIAL EQ (Latin/Brésil/etc.) - Axe	Oui SPECIAL EQ (Latin/Brésil/etc.) - Forro	OuiSPECIAL EQ (Latin/Brésil/etc.) - Funk	Oui SPECIAL EQ (Latin/Brésil/etc.) - Sertanejo",
    reviews: [
      {
        rating: 4.6,
        text: "Connectez l'ON5 à votre téléviseur LG via Bluetooth® pour profiter d'un son plus impressionnant et plus impressionnant.",
      },
      {
        rating: 4.9,
        text: "Associez trois appareils en même temps via l'application XBOOM. Utilisez n'importe lequel des appareils connectés pour contrôler de manière transparente une liste de lecture sans interruption de la musique.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "12",
    productName: "SOUNDBAR SNH5 ATMOS",
    imgUrl: productImg12,
    category: "LG audio",
    price: 400,
    slider: SliderAudioLg,
    shortDesc:
      "Haute puissance avec haut-parleur frontal DTS Virtuel:X 4.1 ch AI Sound Pro Synchronisation du son du téléviseur",
    description:
      "INFORMATIONS SUR LE MODÈLE Informations sur la marque	Barre de sonNom du modèle	SNH5 Nom du modèle de caisson de basses	S67T2-WSystème (puce principale)	MT8502 Couleur du poste principal/caisson de basses	Noir TAILLE (L X H X P, MM) Principal (avec pied)	990 x 97 x 125Caisson de basses	191 x 386 x 318 MATÉRIEL Principal - Avant / Haut / Bas	Grille métallique/Moule/MouleCaisson de basses - Avant / Corps	Jersey/Bois POIDS (KG) Principal	6,6 kgCaisson de basses	5,3 kg Poids brut	14,6 kg BOÎTE EN CARTON Taille (L x H x P) mm	1089 x 217 x 416Taper	Astuce Couleur	Blanc(1)+Jaune CONTENEUR Q 20 pieds	38840 pieds	480 40 pieds (HC)	640 NOMBRE DE CANAUX Nombre de canaux	4.1 canaux PUISSANCE DE SORTIE (THD 10%) Total	600WDevant	100 W x 2 Entourer	100 W x 2Caisson de basses	200 W (filaire) UNITÉ DE HAUT-PARLEUR_AVANT (L/R) SPL	82dBSystème	Fermé Unité de woofer	3 pouces (Papier, Argent)Impédance	4 ohms HAUT-PARLEUR_SURROUND (CÔTÉ) (L/R) SPL	82dBSystème	Fermé Unité de woofer	3 pouces (Papier, Argent)Impédance	4 ohms HAUT-PARLEUR_SUBWOOFER SPL	85dBSystème	Bass-réflexe Unité de woofer	7 poucesImpédance	3 ohms",
    reviews: [
      {
        rating: 4.8,
        text: "le son au meilleur rendez-vous",
      },
      {
        rating: 4.9,
        text: "le son au meilleur rendez-vous",
      },
    ],
    avgRating: 4.8,
  },
  {
    id: "13",
    productName: "SOUNDBAR SH7Q",
    imgUrl: productImg13,
    category: "LG audio",
    price: 600,
    slider: SliderAudioLg,
    shortDesc:
      "[Son puissant immersif 5.1 canaux] Audio 5.1 canaux avec une puissance de 800 W[Solutions sonores premium] Expérience avec les solutions DTS Virtual:X.[Meilleure correspondance pour les téléviseurs LG] Les barres de son LG sont conçues pour améliorer de manière transparente les performances des téléviseurs LG et conçues pour correspondre aux téléviseurs LG. Contrôlez la barre de son avec la télécommande LG TV et générez un son de haute qualité grâce au moteur sonore de LG TV.[HDMI, Bluetooth] Connectivité puissante avec sortie Bluetooth et HDMI[Eco Friendly] Produit écologique dans tout le processus de fabrication, d'utilisation et d'élimination. Utiliser de la résine recyclée provenant de bouteilles en plastique et éliminer le polystyrène et le sac en vinyle dans l'emballage",
    description:
      "INFORMATIONS SUR LE MODÈLE Informations sur la marque	Barre de son LGNom du modèle	SH7Q Nom du modèle de caisson de basses	SPQ5H-WSystème (puce principale)	MT8502 Couleur du poste principal/caisson de basses	Noir TAILLE (L X H X P, MM) Principal (avec pied)	1200 x 97 x 145Caisson de basses	171 x 390 x 261 MATÉRIEL Principal - Avant / Haut / Bas	Grille métallique/Moule/MouleCaisson de basses - Avant / Corps	Jersey/Bois POIDS (KG) Principal	8,52 kgCaisson de basses	5,3 kg Poids brut	19,8 kg BOÎTE EN CARTON Taille (L x H x P) mm	1326 x 482 x 251Taper	Flexo Couleur	Jaune CONTENEUR Q 20 pieds	15240 pieds	324 40 pieds (HC)	405 NOMBRE DE CANAUX Nombre de canaux	5.1 canaux",
    reviews: [
      {
        rating: 4.6,
        text: "Parfaitement adaptée à votre téléviseur LG, la barre de son LG dispose du partage du mode son TV, qui utilise le processeur sonore de votre téléviseur LG pour analyser le contenu que vous aimez et offrir un son plus clair et de meilleure qualité. ",
      },
      {
        rating: 4.9,
        text: "Les barres de son LG sont conçues pour améliorer de manière transparente les performances du téléviseur LG et conçues pour correspondre au téléviseur LG",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "14",
    productName: "SOUNDBAR SQC1",
    imgUrl: productImg14,
    category: "LG audio",
    price: 200,
    slider: SliderAudioLg,
    shortDesc:
      "Conception de style compact Caisson de basses Connectivité Bluetooth Sortie de 160 watts Veille Bluetooth Contrôlez avec la télécommande de votre téléviseur",
    description:
      "DIMENSIONS ET POIDS Taille (L x H x P) - Barre de son	660x56x99mmTaille (L x H x P) - Caisson de basses	185,5 x 303 x 205 mm Poids net - Barre de son	1,31 kgPoids net - Caisson de basses	2,98 kg Taille de l'emballage (L x H x P)	839x346x220mmPoids brut	6,04 kg CANAL/PUISSANCE DE SORTIE Canal	2.1ChPuissance de sortie (THD 10%) - Totale	160W Puissance de sortie (THD 10%) - Avant	30 W x 2Puissance de sortie (THD 10%) - Caisson de basses	",
    reviews: [
      {
        rating: 4.8,
        text: "N'hésitez pas à vous connecter à l'appareil de votre choix grâce à la connectivité USB, optique, portable et Bluetooth.",
      },
      {
        rating: 4.9,
        text: " Bluetooth, diffusez n'importe quoi Diffusez de la musique sans fil directement depuis votre smartphone ou tout autre appareil compatible pour une expérience d'écoute fluide.",
      },
    ],
    avgRating: 4.8,
  },

  {
    id: "1401",
    productName: "SOUNDBAR SQC2",
    imgUrl: productImg1401,
    category: "LG audio",
    price: 300,
    slider: SliderAudioLg,
    shortDesc:
      "Conception de style compact Caisson de basses Connectivité Bluetooth Sortie de 160 watts Veille Bluetooth Contrôlez avec la télécommande de votre téléviseur",
    description:
      "DIMENSIONS ET POIDS Taille (L x H x P) - Barre de son	660x56x99mmTaille (L x H x P) - Caisson de basses	185,5 x 303 x 205 mm Poids net - Barre de son	1,31 kgPoids net - Caisson de basses	2,98 kg Taille de l'emballage (L x H x P)	839x346x220mmPoids brut	6,04 kg CANAL/PUISSANCE DE SORTIE Canal	2.1ChPuissance de sortie (THD 10%) - Totale	160W Puissance de sortie (THD 10%) - Avant	30 W x 2Puissance de sortie (THD 10%) - Caisson de basses	",
    reviews: [
      {
        rating: 4.8,
        text: "N'hésitez pas à vous connecter à l'appareil de votre choix grâce à la connectivité USB, optique, portable et Bluetooth.",
      },
      {
        rating: 4.9,
        text: " Bluetooth, diffusez n'importe quoi Diffusez de la musique sans fil directement depuis votre smartphone ou tout autre appareil compatible pour une expérience d'écoute fluide.",
      },
    ],
    avgRating: 4.8,
  },

  {
    id: "15",
    productName: "SOUNDBAR SN4",
    imgUrl: productImg15,
    category: "LG audio",
    price: 400,
    slider: SliderAudioLg,
    shortDesc:
      "Woofer en carbone AI Sound Pro Caisson de basse sans fil Connectivité Diffusion Bluetooth Synchronisation du son du téléviseur",
    description:
      "INFORMATIONS SUR LE MODÈLE Informations sur la marque	Barre de sonNom du modèle	SN4 Nom du modèle de caisson de basses	SPN4B-WSystème (puce principale)	MT8502 Couleur du poste principal/caisson de basses	Noir TAILLE (L X H X P, MM) Principal (avec pied)	890 x 57 x 85Taille de téléviseur correspondante (`20 nouveau)	40 pouces ↑ Caisson de basses	171 x 390 x 261 MATÉRIEL Principal - Avant / Haut / Bas	Grille de moule/Moule/MouleCaisson de basses - Avant / Corps	Jersey/Bois POIDS (KG) Principal	2,1 kgCaisson de basses	5,3 kg Poids brut	9,38 kg BOÎTE EN CARTON Taille (L x H x P) mm	984 x 217 x 416Taper	Astuce Couleur	Blanc(1)+Jaune CONTENEUR Q 20 pieds	40040 pieds	960 40 pieds (HC)	1056 NOMBRE DE CANAUX Nombre de canaux	2.1 canaux",
    reviews: [
      {
        rating: 4.8,
        text: "LG Sound Bar SL4 peut être simplement connecté à votre téléviseur LG via Bluetooth® ou optique. Votre expérience audio TV complète est prête en un rien de temps.",
      },
      {
        rating: 4.9,
        text: "Diffusez de la musique directement depuis votre smartphone vers la barre de son LG SL4Y via Bluetooth®.",
      },
    ],
    avgRating: 4.8,
  },

  {
    id: "1501",
    productName: "SOUNDBAR SN5",
    imgUrl: productImg1501,
    category: "LG audio",
    price: 450,
    slider: SliderAudioLg,
    shortDesc:
      "Woofer en carbone AI Sound Pro Caisson de basse sans fil Connectivité Diffusion Bluetooth Synchronisation du son du téléviseur",
    description:
      "INFORMATIONS SUR LE MODÈLE Informations sur la marque	Barre de sonNom du modèle	SN4 Nom du modèle de caisson de basses	SPN4B-WSystème (puce principale)	MT8502 Couleur du poste principal/caisson de basses	Noir TAILLE (L X H X P, MM) Principal (avec pied)	890 x 57 x 85Taille de téléviseur correspondante (`20 nouveau)	40 pouces ↑ Caisson de basses	171 x 390 x 261 MATÉRIEL Principal - Avant / Haut / Bas	Grille de moule/Moule/MouleCaisson de basses - Avant / Corps	Jersey/Bois POIDS (KG) Principal	2,1 kgCaisson de basses	5,3 kg Poids brut	9,38 kg BOÎTE EN CARTON Taille (L x H x P) mm	984 x 217 x 416Taper	Astuce Couleur	Blanc(1)+Jaune CONTENEUR Q 20 pieds	40040 pieds	960 40 pieds (HC)	1056 NOMBRE DE CANAUX Nombre de canaux	2.1 canaux",
    reviews: [
      {
        rating: 4.8,
        text: "LG Sound Bar SL4 peut être simplement connecté à votre téléviseur LG via Bluetooth® ou optique. Votre expérience audio TV complète est prête en un rien de temps.",
      },
      {
        rating: 4.9,
        text: "Diffusez de la musique directement depuis votre smartphone vers la barre de son LG SL4Y via Bluetooth®.",
      },
    ],
    avgRating: 4.8,
  },

  
  {
    id: "150111",
    productName: "BLUETOOTH SPEAKER RP4B",
    imgUrl: "https://www.lg.com/africa/images/audio/md07538680/gallery/RP4_Burgundy_D01.jpg",
    category: "LG audio",
    price: 500,
    slider: "https://www.lg.com/africa/images/LG.com_HeroBanner_XBOOM%20Go1600x800px_%20Desktop%20.jpg",
    shortDesc:
      "【AUTHENTIC 360 SOUND】Equivalent Omnidirectional Sound Quality and Power Regardless of Location【MORE POWERFUL AND CLEAR SOUND】New and Premium Material Speaker Units to Improve Sound Power【EMOTIVE 360 MOOD LIGHTING】Ambient/Nature/Party Light Modes Creating Optimal Mood【AESTHETIC 360 DESIGN】Fits and Blends into Any Interior【10-HOUR PLAYTIME】Enjoy Music Anywhere for Longer【BETTER FOR THE PLANET】100% Recyclable Paper Material Packaging",
    description:
      "【AUTHENTIC 360 SOUND】Equivalent Omnidirectional Sound Quality and Power Regardless of Location【MORE POWERFUL AND CLEAR SOUND】New and Premium Material Speaker Units to Improve Sound Power【EMOTIVE 360 MOOD LIGHTING】Ambient/Nature/Party Light Modes Creating Optimal Mood【AESTHETIC 360 DESIGN】Fits and Blends into Any Interior【10-HOUR PLAYTIME】Enjoy Music Anywhere for Longer【BETTER FOR THE PLANET】100% Recyclable Paper Material Packaging",
    reviews: [
      {
        rating: 4.8,
        text: "LG Sound Bar SL4 peut être simplement connecté à votre téléviseur LG via Bluetooth® ou optique. Votre expérience audio TV complète est prête en un rien de temps.",
      },
      {
        rating: 4.9,
        text: "Diffusez de la musique directement depuis votre smartphone vers la barre de son LG SL4Y via Bluetooth®.",
      },
    ],
    avgRating: 4.8,
  },
  {
    id: "16",
    productName: "RNC7 BLUETOOTH SPEAKER",
    imgUrl: productImg16,
    category: "LG audio",
    price: 550,
    slider: "https://www.lg.com/africa/images/LG.com_HeroBanner_XBOOM%20Go1600x800px_%20Desktop%20.jpg",
    shortDesc:
      " Éclairage des haut-parleurs multicolores (RVB) Fonction DJ Fonction Karaoké Juke-Box Application Bluetooth Remote (Android/iOS)",
    description:
      "CARACTÉRISTIQUES DU PRODUIT Son	 EQ utilisateur Dolby Audio / EQ Cluster 3 (Standard, Pop, Classique, Rock, Jazz, Bass Blast+, Football)Égaliseur spécial	(Dangdut, Arabe, Afro Hip-hop, Inde, Rugueton, Merengue, Salsa, Samba, Axe, Forro, Funk, Sertanejo) Commodité	Répéter 1/Tous les Juke Box Suffle Sauter/Balayage - Av/Rev Copie USB/enregistrement direct Application Bluetooth Remote (Android/iOS) Changement de fonction automatique Bluetooth Mise sous tension (veille) Multi Bluetooth (Android/iOS) Multi couleur (RVB) Éclairage des haut-parleurs Party Strobe (App 용) Recherche de fichiers/dossiers avec lecture de musique Fota Auto Music Play (Move&Play) (Android/iOS) Wireless Party Link (Twin Mode)",
    reviews: [
      {
        rating: 4.8,
        text: "Connectez le RN7 à votre téléviseur LG via un câble optique ou Bluetooth® pour profiter d'un son plus impressionnant et plus impressionnant.",
      },
      {
        rating: 4.9,
        text: "Connectivité Plus de façons de profiter de la fête Branchez-le à l'entrée guitare et faites vibrer la pièce. Ou utilisez une clé USB et la radio pour écouter de la musique.",
      },
    ],
    avgRating: 4.8,
  },

  //ajout----------------------
  {
    id: "10016",
    productName: "RNC5 BLUETOOTH SPEAKER",
    imgUrl: productImg10016,
    category: "LG audio",
    price: '',
    slider: "https://www.lg.com/africa/images/LG.com_HeroBanner_XBOOM%20Go1600x800px_%20Desktop%20.jpg",
    shortDesc:
      " Éclairage des haut-parleurs multicolores (RVB) Fonction DJ Fonction Karaoké Juke-Box Application Bluetooth Remote (Android/iOS)",
    description:
      "CARACTÉRISTIQUES DU PRODUIT Son	 EQ utilisateur Dolby Audio / EQ Cluster 3 (Standard, Pop, Classique, Rock, Jazz, Bass Blast+, Football)Égaliseur spécial	(Dangdut, Arabe, Afro Hip-hop, Inde, Rugueton, Merengue, Salsa, Samba, Axe, Forro, Funk, Sertanejo) Commodité	Répéter 1/Tous les Juke Box Suffle Sauter/Balayage - Av/Rev Copie USB/enregistrement direct Application Bluetooth Remote (Android/iOS) Changement de fonction automatique Bluetooth Mise sous tension (veille) Multi Bluetooth (Android/iOS) Multi couleur (RVB) Éclairage des haut-parleurs Party Strobe (App 용) Recherche de fichiers/dossiers avec lecture de musique Fota Auto Music Play (Move&Play) (Android/iOS) Wireless Party Link (Twin Mode)",
    reviews: [
      {
        rating: 4.8,
        text: "Connectez le RN7 à votre téléviseur LG via un câble optique ou Bluetooth® pour profiter d'un son plus impressionnant et plus impressionnant.",
      },
      {
        rating: 4.9,
        text: "Connectivité Plus de façons de profiter de la fête Branchez-le à l'entrée guitare et faites vibrer la pièce. Ou utilisez une clé USB et la radio pour écouter de la musique.",
      },
    ],
    avgRating: 4.8,
  },
  //---------------------------

  {
    id: "17",
    productName: "RNC9 BLUETOOTH SPEAKER",
    imgUrl: productImg17,
    category: "LG audio",
    price: 600,
    slider: "https://www.lg.com/africa/images/LG.com_HeroBanner_XBOOM%20Go1600x800px_%20Desktop%20.jpg",
    shortDesc:
      "Explosion de basses+ Éclairage des haut-parleurs multicolores (RVB) Fonction DJ Fonction Karaoké Juke-Box Application Bluetooth Remote (Android/iOS)",
    description:
      "CARACTÉRISTIQUES DU PRODUIT Son	 EQ utilisateur Dolby Audio / EQ Cluster 3 (Standard, Pop, Classique, Rock, Jazz, Bass Blast+, Football)Égaliseur spécial	(Dangdut, Arabe, Afro Hip-hop, Inde, Rugueton, Merengue, Salsa, Samba, Axe, Forro, Funk, Sertanejo) Commodité	Répéter 1/Tous les Juke Box Suffle Sauter/Balayage - Av/Rev Copie USB/enregistrement direct Application Bluetooth Remote (Android/iOS) Changement de fonction automatique Bluetooth Mise sous tension (veille) Multi Bluetooth (Android/iOS) Multi couleur (RVB) Éclairage des haut-parleurs Party Strobe (App) Recherche de fichiers/dossiers avec lecture de musique Fota Auto Music Play (Move&Play) (Android/iOS) Wireless Party Link (Twin Mode)",
    reviews: [
      {
        rating: 4.8,
        text: "Connectez le RNC9 à votre téléviseur LG via un câble optique ou Bluetooth® pour profiter d'un son plus impressionnant et plus impressionnant.",
      },
      {
        rating: 4.9,
        text: "Revivez le plaisir avec des amis Enregistrez vos playlists et mix DJ sur USB pour pouvoir les réécouter à tout moment. Copiez-les sur une autre clé USB ou envoyez-les même à des amis via Bluetooth®.",
      },
    ],
    avgRating: 4.8,
  },

  {
    id: "18",
    productName: "BLUETOOTH SPEAKER XG7QBK",
    imgUrl: productImg18,
    category: "LG audio",
    price: '',
    slider: "https://www.lg.com/africa/images/LG.com_HeroBanner_XBOOM%20Go1600x800px_%20Desktop%20.jpg",
    shortDesc:
      "Woofer et tweeter de type piste 30 W pour un son fort Boost sonore Light Studio pour une expérience sonore immersive IP67 Autonomie de la batterie de 24 heures",
    description:
      "TAILLE (L X H X P, MM) Taille (L x H x P) mm	261x98x95 POIDS (KG) Poids net / kg)	1,1kg BOÎTE EN CARTON Taille du carton (L x H x P) mm	320x143x145Taper	Type normal CONTENEUR Q 20 pieds	357040 pieds	7350 40 pieds (HC)	7350 CANAUX Nombre de canaux	1 canal (2 voies)",
    reviews: [
      {
        rating: 4.8,
        text: "le festival peut commencer",
      },
      {
        rating: 4.9,
        text: "Waouh!!!",
      },
    ],
    avgRating: 4.8,
  },

  {
    id: "19",
    productName: "SOUND BAR SN7Y",
    imgUrl: productImg19,
    category: "LG audio",
    price: 550,
    slider: SliderAudioLg,
    shortDesc:
      "avec MÉRIDIEN Dolby Atmos DTS:X 3.1.2 ch Audio haute résolution AI Sound Pro",
    description:
      "INFORMATIONS SUR LE MODÈLE ormations sur la marque	Barre de sonNom du modèle	SN7Y  du modèle de caisson de basses	SPN5B-WSystème (puce principale)	MT8531 leur du poste principal/caisson de basses	Noir LLE (L X H X P, MM) ncipal (avec pied)	890 x 65 x 119Taille de téléviseur correspondante (20 nouveaux)	40 pouces ↑ sson de basses	180 x 394 x 290 ÉRIEL ncipal - Avant / Haut / Bas	Grille métallique/Moule/MouleCaisson de basses - Avant / Corps	Jersey/Bois DS (KG) ncipal	3,7 kgCaisson de basses	5,8 kg ds brut	11,77 kg TE EN CARTON lle (L x H x P) mm	988 x 225 x 441Taper	Astuce (L) leur	Blanc(1)+Jaune TENEUR Q pieds	38040 pieds	912 pieds (HC)	1056 bre de canaux	3.1.2 canaux",
    reviews: [
      {
        rating: 4.8,
        text: "Votre barre de son peut désormais faire office de gestionnaire de votre foyer.",
      },
      {
        rating: 4.9,
        text: "L'audio haute résolution offre des taux d'échantillonnage de 96 kHz et une profondeur de 24 bits, pour un son plus précis et une expérience d'écoute plus agréable.",
      },
    ],
    avgRating: 4.8,
  },

 {
    id: "20",
    productName: "xboom ck99 X-Shiny Woofer 5000w",
    imgUrl: productImg20,
    category: "LG audio",
    price: 1500,
    slider: SliderAudioLg,
    shortDesc:
      "5000 watts Corne de souffle Corne de compression DJ professionnel Crossfader Spectacle de feux arrière",
    description:
      "AMPLIFICATEUR Puissance de sortie - Avant	1250 W x 2Puissance de sortie - Caisson de basses	1250 W x 2 Sélecteur de fonctions - CD/DVD	OuiSélecteur de fonction - Tuner	Oui Sélecteur de fonction - AUX1	OuiSélecteur de fonction - AUX2	Oui Sélecteur de fonction - USB1	OuiSélecteur de fonction - USB2	Oui Sélecteur de fonction - Entrée portable	OuiSélecteur de fonction - Bluetooth	Oui INTERFACE Sortie audio - Prise casque (Φ3,5/Φ6,3)	OuiSortie audio - AUX OUT (Party Link)	Oui (arrière) Entrée audio - USB1	Oui (avant)Entrée audio - USB2	Oui (avant) Entrée audio - Entrée portable	Oui (avant)Entrée audio - AUX IN 1/2 (L/R)	Oui (arrière) Clé de verrouillage de porte (disque)	OuiConnexion du système avec haut-parleur pour l'éclairage (avant/caisson de basses)	Oui Sortie haut-parleur - Avant G/D	1/1Sortie haut-parleur - Caisson de basses (passif/actif)	2 Antenne radio - FM	OuiFonction Karaoké - Volume du micro (sur le poste principal)	Oui Fonction Karaoké - Prise Micro	2EA (Φ6.3)Fonction Karaoké - Echo (sur RCU)",
    reviews: [
      {
        rating: 4.8,
        text: "Prenez le contrôle de votre voix avec 18 effets vocaux différents, même lorsque vous chantez, rendant chaque fête plus amusante.",
      },
      {
        rating: 4.9,
        text: "Jouez et mixez à partir de deux clés USB différentes. Avec la fonction DJ Sharing, vous pouvez facilement envoyer des pistes mixées sur votre téléphone.",
      },
    ],
    avgRating: 4.8,
  },

  {
    id: "21",
    productName: "loud speaker mini RM2 speakER",
    imgUrl: productImg21,
    category: "LG audio",
    price: '',
    slider: SliderAudioLg,
    shortDesc:
      "CARACTÉRISTIQUES HAUT-PARLEUR LG RM2 (HAUT-PARLEUR LG RM2) QU'Y A-T-IL DANS LA BOITE HAUT-PARLEUR LG RM2 (HAUT-PARLEUR LG RM2)",
    description:
      "Haut-parleur vertical portable LG XBOOM RM2 80 W avec trou pour trépied Un haut-parleur doté d'un puissant woofer de 15 pouces vous offre une qualité sonore améliorée pour des rythmes qui vous donnent envie de danser.",
    reviews: [
      {
        rating: 4.8,
        text: "Oui, je recommande ce produit.",
      },
      {
        rating: 4.9,
        text: " La vie est belle avec LG et sa gamme de produits.",
      },
    ],
    avgRating: 4.8,
  },

  {
    id: "21001",
    productName: "950W MINI AUDIO MULTI COLOR LIGHTING KARAOKE cl65",
    imgUrl: productImg21001,
    category: "LG audio",
    price: 375,
    slider: SliderAudioLg,
    shortDesc:
      "- 950 Watts -Multi Color Lighting - Karaoke Star - Vocal Effects - ulti Bluetooth - Wireless Party Link",
    description:
      "- 950 Watts -Multi Color Lighting - Karaoke Star - Vocal Effects - ulti Bluetooth - Wireless Party Link.",
    reviews: [
      {
        rating: 4.8,
        text: "Oui, je recommande ce produit.",
      },
      {
        rating: 4.9,
        text: " La vie est belle avec LG et sa gamme de produits.",
      },
    ],
    avgRating: 4.8,
  },

  {
    id: "22",
    productName: "MINI SOUND BAR SK1",
    imgUrl: productImg22,
    category: "LG audio",
    price: 175,
    slider: SliderAudioLg,
    shortDesc:
      "Design compact Connectivité Bluetooth Synchronisation du son du téléviseur LG",
    description:
      "PHYSIQUE Taille (L x H x P) mm - Principal (avec pied) mm	650 X 77 X 89Taille (L x H x P) mm - Principal (avec pied) mm - Pied intégré (H) mm	1 Matériau - Ensemble principal - Avant	Grille en métalMatériau - Ensemble principal - Haut/Bas	Moule Poids net (Kg) - Principal	1,99KgTaille du carton (L x H x P) mm	724 x 138 x 202 Type de carton	Compenser CANAL/PUISSANCE DE SORTIE Canal	2.0 canauxPuissance de sortie (THD 10%) - Totale	40W Puissance de sortie (THD 10%) - Avant	20 W x 2 CONFÉRENCIER Principal - SPL	81dBPrincipal - Système	Fermé Principal - Unité de tweeter - Avant uniquement	2,2'' Principal - Impédance	4 ohms CONNECTIVITÉ Général - Entrée audio - Entrée portable (3,5Ø)	OuiGénéral - Optique	Oui (1) Général - USB	Oui (service uniquement)Sans fil - Bluetooth 4.0",
    reviews: [
      {
        rating: 4.8,
        text: "Le son démarre au moment où vous transférez l'audio vers la barre de son.",
      },
      {
        rating: 4.9,
        text: "Diffusez de la musique sans fil directement depuis votre smartphone ou tout autre appareil compatible pour une expérience d'écoute fluide.",
      },
    ],
    avgRating: 4.8,
  },

  {
    id: "23",
    productName: "Eclair QP5 3.1.2ch Dolby Atmos Compact Sounf Bar with Subwoofer, Black",
    imgUrl: productImg23,
    category: "LG audio",
    price: 700,
    slider: SliderAudioLg,
    shortDesc:
      "3.1.2 canaux 320W Design compact Dolby Atmos® et DTS:X avec haut-parleurs en hauteur Extenseur de champ sonore Caisson de basse amortisseur de vibrations Passage 4K et HDR",
    description:
      "Caractéristiques et spécifications Développer tout AAC / AAC+ Oui AAC C4A / USB Oui Dolby Atmos® Oui Dolby Digital Oui Dolby Digital Plus Oui Dolby TrueHD Oui Surround numérique DTS Oui DTS:X Oui DTS-HD haute résolution Oui Audio maître DTS-HD Oui FLAC (jusqu'à 192 kHz) C4A / USB Oui LPCM Oui MP3 C4A/USB Oui OGG (jusqu'à 48 kHz) C4A / USB Oui WAV-C4A / USB Oui WMA-C4A/USB Oui Bluetooth Oui Synchronisation du son du téléviseur LG (Bluetooth) Oui Synchronisation du son du téléviseur LG (optique) Oui Entrée optique Oui Emplacement USB Oui Caisson de basses actif sans fil Oui",
    reviews: [
      {
        rating: 4.8,
        text: " les produits audio de LG s'adaptent au rythme de votre vie.",
      },
      {
        rating: 4.9,
        text: "rehausse l'expérience et fait durer votre plaisir plus longtemps.",
      },
    ],
    avgRating: 4.8,
  },

  {
    id: "24",
    productName: "SP9A, 520W, 5.1.2 CH with Meridian & Dolby Atmos Soundbar",
    imgUrl: productImg24,
    category: "LG audio",
    price: 850,
    slider: SliderAudioLg,
    shortDesc:
      "Méridien Partage du mode son du téléviseur et contrôle du mode barre de son Audio haute résolution Dolby Atmos et DTS:X Nouveaux modes sonores AI Sound Pro",
    description:
      "POINTS FORTS DU PRODUIT Dolby Atmos	OuiDTS:X	Oui Audio haute résolution	Jusqu'à 24 bits/192 kHzSuréchantillonnage 24 bits	Oui DAC Hi-Fi	OuiPassage 4K (HDCP 2.3)	Oui Mode de jeu en groupe	Oui (Chromecast)Chromecast intégré	Oui Prêt pour le son surround sans fil	OuiDiffusion Bluetooth	Oui (5,0) Technologie audio méridienne	OuiFonctionne avec l'Assistant Google	Oui Spotify Connect pris en charge	OuiApple AirPlay 2 pris en charge	Oui Lecture USB	OuiPassage Dolby Vision et HDR10	Oui HDMI-eARC	OuiRéglage personnalisé de la pièce	Oui (application) Contrôle du mode barre de son	OuiPartage du mode son du téléviseur	Oui GÉNÉRAL Canaux	5.1.2 canauxPouvoir total	520W",
    reviews: [
      {
        rating: 4.8,
        text: "Connexion audio sans compromis HDMI eARC (Enhanced Audio Return Channel) permet la transmission sans perte de l'audio non compressé 5,1 canaux et 7,1 canaux, et même de l'audio HBR (High Bit Rate) tel que Dolby Atmos® et DTS : X® sur les téléviseurs compatibles.",
      },
      {
        rating: 4.9,
        text: "Les barres de son LG sont désormais compatibles avec davantage de services d'IA. Vous pouvez facilement contrôler la barre de son LG avec la plate-forme de votre choix.",
      },
    ],
    avgRating: 4.8,
  },
    // lg congelateur
  {
    id: "25",
    productName: "GCS415",
    imgUrl: productImg25,
    category: "LG congelateur",
    price: 700,
    slider: SliderCongelateurLg,
    shortDesc:
      "Ventilateur de circulation Panier commodité Éclairage LED Poignée et serrure de porte Démarrage basse tension (187 V ~ 254 V) Contrôle externe et congélation rapide",
    description:
      "RÉSUMÉ DES SPÉCIFICATIONS TYPE DE PRODUIT 1porte DIMENSION DU PRODUIT (LXHXP, MM) 1275 x 825 x 740 CONTRÔLE MANUEL Oui LUMIÈRE DU CONGÉLATEUR Ampoule",
    reviews: [
      {
        rating: 4.7,
        text: "La charnière au toucher doux",
      },
    ],
    avgRating: 4.5,
  },

   {
    id: "2511",
    productName: "GCS215",
    imgUrl: productImg25,
    category: "LG congelateur",
    price: 450,
    slider: SliderCongelateurLg,
    shortDesc:
      "Ventilateur de circulation Panier commodité Éclairage LED Poignée et serrure de porte Démarrage basse tension (187 V ~ 254 V) Contrôle externe et congélation rapide",
    description:
      "RÉSUMÉ DES SPÉCIFICATIONS TYPE DE PRODUIT 1porte DIMENSION DU PRODUIT (LXHXP, MM) 1275 x 825 x 740 CONTRÔLE MANUEL Oui LUMIÈRE DU CONGÉLATEUR Ampoule",
    reviews: [
      {
        rating: 4.7,
        text: "La charnière au toucher doux",
      },
    ],
    avgRating: 4.5,
  },
   {
    id: "2512",
    productName: "GCS315",
    imgUrl: productImg25,
    category: "LG congelateur",
    price: 550,
    slider: SliderCongelateurLg,
    shortDesc:
      "Ventilateur de circulation Panier commodité Éclairage LED Poignée et serrure de porte Démarrage basse tension (187 V ~ 254 V) Contrôle externe et congélation rapide",
    description:
      "RÉSUMÉ DES SPÉCIFICATIONS TYPE DE PRODUIT 1porte DIMENSION DU PRODUIT (LXHXP, MM) 1275 x 825 x 740 CONTRÔLE MANUEL Oui LUMIÈRE DU CONGÉLATEUR Ampoule",
    reviews: [
      {
        rating: 4.7,
        text: "La charnière au toucher doux",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "26",
    productName: "Congélateur Debout GN-304SLBT 171L",
    imgUrl: productImg26,
    category: "LG congelateur",
    price: 425,
    slider: SliderCongelateurLg,
    shortDesc:
      "Compresseur à inverseur intelligent™ Très grand congélateur Serrure à clé",
    description:
      "Compresseur à inverseur intelligent™ Très grand congélateur Serrure à clé",
    reviews: [
      {
        rating: 4.8,
        text: "La fonction EverCool retient l'air frais pendant 9 heures dans la section réfrigérateur en cas de coupure de courant.",
      },
      {
        rating: 4.8,
        text: "Le nouveau design du réfrigérateur est luxueux et ergonomique ! Ainsi, les clients pourraient facilement saisir la poignée de la poche.",
      },
    ],
    avgRating: 4.7,
  },
  // lg cuisiniere
  {
    id: "27",
    productName: "LG COOKER LRE 6321S",
    imgUrl: productImg27,
    category: "LG cuisiniere",
    price: 1750,
    slider: SliderCuisiniereLg,
    shortDesc:
      "Rotisserie Grilling Full Safety Removable Door Glass Closed Door Grilling",
    description:
      "- Oven Capacity : 90.1L - Burner Power : 1.0kW - 3.6kW (5 Burners) - High Power of Dual Heating (Convection & Grill) - Rotisserie Grilling - Easy Removal Door Glass & Catalytic Easy Clean in Oven - Cast Iron Trivet - Full Safety with FFD(Flame Failure Device) - Convenience with Auto Ignition",
    reviews: [
      {
        rating: 4.7,
        text: "Rotisserie Grilling Full Safety Removable Door Glass Closed Door Grilling",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "28",
    productName: "AS COOKER FA211RMA 60CM ",
    imgUrl: productImg28,
    category: "LG cuisiniere",
    price: '',
    slider: SliderCuisiniereLg,
    shortDesc:
      "Rotisserie Grilling Full Safety Removable Door Glass Closed Door Grilling!",
    description:
      "- Oven Capacity : 56L - Burner Power : 1.0kW - 3.6kW (4 Burners) - High Power of Dual Heating (Convection & Grill) - Rotisserie Grilling - Easy Removal Door Glass & Catalytic Easy Clean in Oven - Shiny Enamel Trivet - Full Safety with FFD(Flame Failure Device) - Convenience with Auto Ignition",
    reviews: [
      {
        rating: 4.8,
        text: "Closed Door Grilling Every LG cooker provides closed door grilling function",
      },
      {
        rating: 4.8,
        text: "Closed Door Grilling Every LG cooker provides closed door grilling function",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "29",
    productName: "Lave-vaisselle LG QuadWash™| EasyRack™ | SmartThinQ™",
    imgUrl: productImg29,
    category: "LG lave vaisselle",
    price: 1250,
    slider: SliderLaveVaisselleLg,
    shortDesc:
      "QuadWash™ (Multi-Motion Wash Arms) Inverter Direct Drive with 10 Years Warranty EasyRack™ Plus",
    description:
      "- Moteur Inverter Direct Drive™ QuadWash™ - Bras de lavage multidirectionnel",
    reviews: [
      {
        rating: 4.7,
        text: "LG dishwasher bring a sleek, modern design to your kitchen. Built with innovative LG technology, your dishwasher will work as beautifully as it looks.",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "30",
    productName: "DFB512FP",
    imgUrl: productImg30,
    category: "LG lave vaisselle",
    price: 1250,
    slider: SliderLaveVaisselleLg,
    shortDesc:
      "QuadWash™ (Multi-Motion Wash Arms) Inverter Direct Drive with 10 Years Warranty EasyRack™ Plus SmartThinQ™",
    description:
      "SUMMARY CAPACITY 14 Place Settings DIMENSIONS (W X H X D, MM) (Width)600mm x (Height)850mm x (Depth)600mm MAIN TECHNOLOGY Smart Rack+™ ADDITIONAL BENEFIT Inverter Direct Drive",
    reviews: [
      {
        rating: 4.8,
        text: "Easy to adjust on-the-fly, this racking system can shift to handle any challenge your dishes serve up.",
      },
      {
        rating: 4.8,
        text: " Clean from Every Angle Thanks to Multi-Motion spray arms and high-pressure jets, QuadWash™ provides maximum coverage to get things clean the first time.",
      },
    ],
    avgRating: 4.7,
  },
  // lg micro_onde
  {
    id: "31",
    productName: "NEOCHEF SMART INVERTER OVEN MH6535GIS",
    imgUrl: productImg31,
    category: "LG micro-onde",
    price: 250,
    slider: SliderMicroLg,
    shortDesc:
      "EasyClean™ Conception mince Lampe à LED",
    description:
      "RÉSUMÉ DES SPÉCIFICATIONS CAPACITÉ DU FOUR (L) 30 DIMENSION DE LA CAVITÉ (L X H X P) (MM) 359,0 x 218,0 x 378,5 TAPER Solo TYPE DE CONTRÔLE Panneau Tactile",
    reviews: [
      {
        rating: 4.7,
        text: "Design minimaliste, utilisation pratique de l'espace Avec son design élégant et sa taille compacte, il s'intègre parfaitement dans n'importe quel décor de cuisine, tout en offrant suffisamment d'espace pour les plats hauts ou larges.",
      },
    ],
    avgRating: 4.5,
  },
// ajout 
{
    id: "3111",
    productName: "25L SMART Inverter Solo MW Oven 2595CIS",
    imgUrl: productImg31,
    category: "LG micro-onde",
    price: 275,
    slider: SliderMicroLg,
    shortDesc:
      "EasyClean™ Conception mince Lampe à LED",
    description:
      "EasyClean™ Conception mince Lampe à LED",
    reviews: [
      {
        rating: 4.7,
        text: "Design minimaliste, utilisation pratique de l'espace Avec son design élégant et sa taille compacte, il s'intègre parfaitement dans n'importe quel décor de cuisine, tout en offrant suffisamment d'espace pour les plats hauts ou larges.",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "3112",
    productName: "20L Solo Mw Oven 2042DB",
    imgUrl: productImg31,
    category: "LG micro-onde",
    price: '',
    slider: SliderMicroLg,
    shortDesc:
      "EasyClean™ Conception mince Lampe à LED",
    description:
      "RÉSUMÉ DES SPÉCIFICATIONS CAPACITÉ DU FOUR (L) 30 DIMENSION DE LA CAVITÉ (L X H X P) (MM) 359,0 x 218,0 x 378,5 TAPER Solo TYPE DE CONTRÔLE Panneau Tactile",
    reviews: [
      {
        rating: 4.7,
        text: "Design minimaliste, utilisation pratique de l'espace Avec son design élégant et sa taille compacte, il s'intègre parfaitement dans n'importe quel décor de cuisine, tout en offrant suffisamment d'espace pour les plats hauts ou larges.",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "3113",
    productName: "42L Neo Chef Inverter 2535CIS",
    imgUrl: productImg31,
    category: "LG micro-onde",
    price: 250,
    slider: SliderMicroLg,
    shortDesc:
      "EasyClean™ Conception mince Lampe à LED",
    description:
      "RÉSUMÉ DES SPÉCIFICATIONS CAPACITÉ DU FOUR (L) 30 DIMENSION DE LA CAVITÉ (L X H X P) (MM) 359,0 x 218,0 x 378,5 TAPER Solo TYPE DE CONTRÔLE Panneau Tactile",
    reviews: [
      {
        rating: 4.7,
        text: "Design minimaliste, utilisation pratique de l'espace Avec son design élégant et sa taille compacte, il s'intègre parfaitement dans n'importe quel décor de cuisine, tout en offrant suffisamment d'espace pour les plats hauts ou larges.",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "32",
    productName: "42L NEO SOLO MS4295CIS",
    imgUrl: "https://www.lg.com/content/dam/channel/wcms/sa_en/images/microwaves/ms2535gisw_bwh9nag_emgf_sa_en_c/gallery/MS2535GISW_Zm01_060717.jpg",
    category: "LG micro-onde",
    price: 300,
    slider: SliderMicroLg,
    shortDesc:
      "EasyClean™ Plateau tournant stable Lampe LED 3 fois plus lumineuse Plus petite taille, plus grande capacité",
    description:
      "RÉSUMÉ DES SPÉCIFICATIONS CAPACITÉ (L) 42litres TECHNOLOGIE PRINCIPALE Onduleur intelligent AVANTAGE SUPPLÉMENTAIRE Cuisson rapide",
    reviews: [
      {
        rating: 4.8,
        text: "Vous pouvez également cuisiner facilement des plats cuits à la vapeur en utilisant des accessoires vapeur.",
      },
      {
        rating: 4.8,
        text: "Une cuisson à température précisément plus basse vous aide à préparer facilement un yaourt fait maison sain.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "33",
    productName: "42L NEOCHEF Grill-MH8265DIS",
    imgUrl: "https://www.lg.com/content/dam/channel/wcms/sa_en/images/microwaves/ms2535gisw_bwh9nag_emgf_sa_en_c/gallery/MS2535GISW_Zm01_060717.jpg",
    category: "LG micro-onde",
    price: 350,
    slider: SliderMicroLg,
    shortDesc:
      "Onduleur intelligent Conception à un seul corps Toucher entièrement en verre Antibactérien Easy Clean™ DIRIGÉ Anneau stable à 6 points",
    description:
      "CAPACITÉ ℓ	42 POUVOIR Sortir	1200WFour micro onde	1100W Gril	950 WCombiné (MO+Gril)	1500W SPÉCIFICATION DE BASE Puissance électrique	230 V/50 HzType de chauffage	Quartz Revêtement facile à nettoyer (antibactérien)	OuiTaille du plateau tournant (mm)	Φ360 Hors cas	Noir COULEUR Couleur du boîtier	NoirGarniture de porte/clavier	Noir MANETTE Taper	Toucher et composerAfficher	LED blanche PORTE Type ouvert	Balançoire latérale FONCTION Horloge	OuiRéglage de l'heure	Oui Sécurité enfants	OuiDémarrage rapide	Oui Plus/Moins	OuiSaveur de goût	Oui",
    reviews: [
      {
        rating: 4.6,
        text: "Cuisinez sainement avec Charcoal Lighting Heater™",
      },
      {
        rating: 4.9,
        text: "Travail stable Le NeoChef TM peut s'adapter aux niveaux de puissance entrante, ce qui signifie que vous pouvez toujours compter sur votre micro-ondes pour fonctionner, même dans les zones à basse tension, à électricité limitée ou à faible alimentation entrante (minimum 300 W).",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "34",
    productName: "Mini Bar One Door Refrigerator GC-051 SA 50L ",
    imgUrl: productImg34,
    category: "LG refrigerateur",
    price: 290,
    slider: SliderRefLg,
    shortDesc:
      "Le meilleur prix du réfrigérateur de chevet mini-bar LG à une porte GC-051SA par Jumia au Nigeria est de 42 900 NGN Les méthodes de paiement disponibles sontPaiement à la livraison Les frais de livraison sont de 800 à 28 500 NGN , avec une livraison prévue sous 3 à 8 jour(s) La première apparition de ce produit a eu lieu le 17 février 2019.",
    description:
      "Le réfrigérateur LG GC-051SA est un réfrigérateur de type bar d'une capacité de 46 litres. Il est livré avec une option clé et serrure, un bac à glaçons et un système de contrôle mécanique. Il est disponible dans des dimensions de 443x501x450. Le LG 051 SA est parfait pour une utilisation dans un petit bureau ou comme réfrigérateur de chevet. Caractéristiques: Réfrigérateur de type bar Capacité : 46L Poids : 15 kg net Dégivrage automatique numérique Poignée horizontale Bac à glaçons Option clé et serrure",
    reviews: [
      {
        rating: 4.7,
        text: "Le prix le moins cher du réfrigérateur de chevet mini-bar LG à une porte GC-051SA",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "35",
    productName: "ONE DOOR REFRIGERATOR GL-131SLQ SFSQQ 119",
    imgUrl: productImg35,
    category: "LG refrigerateur",
    price: 350,
    slider: SliderRefLg,
    shortDesc:
      "Refroidissement direct Porte transparente du congélateur Étagère métallique Déboiser Poignée facile horizontale au niveau du comptoir",
    description:
      "SPÉCIFICATIONS DE BASE type de produit	Une porte REF DIMENSIONS ET POIDS Dimension d'emballage (LxHxP, mm)	500 x 890 x 545Dimension du produit (LxHxP, mm)	460 x 830 x 500 MATÉRIEL ET FINITION Finition (Porte)	OptionType de poignée	Poche horizontale COMPARTIMENT RÉFRIGÉRATEUR Plateau à œufs/Panier à œufs	",
    reviews: [
      {
        rating: 4.8,
        text: "Qualité du produit",
      },
      {
        rating: 4.8,
        text: "Valeur du produit",
      },
    ],
    avgRating: 4.7,
  },

  //ajout----------------------
  {
    id: "10035",
    productName: "SINGLE DOOR REFRIGERATOR REF331 199L",
    imgUrl: productImg10035,
    category: "LG refrigerateur",
    price: 550,
    slider: "https://www.lg.com/africa/images/banners/RHQ-GMC_Hero-Banner_EN_NG_PC_Main-KV_1600x80011.jpg",
    shortDesc:
      "Smart Inverter Compressor™ Extra Large Freezer Key Lock",
    description:
      "New design of refrigerator is luxurious and ergonomic! Therefore, customers would be able to conveniently grab the pocket handle.	",
    reviews: [
      {
        rating: 4.8,
        text: "Qualité du produit",
      },
      {
        rating: 4.8,
        text: "Valeur du produit",
      },
    ],
    avgRating: 4.7,
  },
  //---------------------------
  {
    id: "36",
    productName: "GN-B 312 PLGB REF",
    imgUrl: productImg36,
    category: "LG refrigerateur",
    price: '',
    slider: SliderRefLg,
    shortDesc:
      "Dimensi Produk (LxTxD, mm) 600 x 1640 x 710Tipe KompresorSmart Inverter CompressorTampilan Luar (Pintu)Platinum Silver3",
    description:
      "-2 portes ystème de refroidissement: NoFrost- Capacité: 340 Litres- Capacité Refrigérateur: 246 L- Capacité Congélateur: 79 L - Éclairage LED- Smart Inverter- Diagnostic intelligent- Alarme de porte- R600a- Door Cooling+- Flux d'air multiple- Désodorisant- Flux d'air multiple- Températures optimales et homogènes- 2 Clayette- 3 Panier de porte- Bac à légume- Casier à œufs- Dimensions: H x L x P: 164 x 60 x 71 cm- Couleur: Inox",
    reviews: [
      {
        rating: 4.6,
        text: "Capacité Refrigérateur: 246 L",
      },
      {
        rating: 4.9,
        text: "Capacité Congélateur: 79 L",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "37",
    productName: "LG TOP MOUNT REFRIGERATOR WITH SMART INVERTER COMPRESSOR REF322 260L",
    imgUrl: productImg37,
    category: "LG refrigerateur",
    price: 850,
    slider: SliderRefLg,
    shortDesc:
      "LINEAR Cooling™ DoorCooling+™ Compresseur linéaire à inverseur Moist Balance Crisper™",
    description:
      "Caractéristiques Capacité (L)	260L Compositeur Linéaire	Oui / Avec 10 Ans De Garantie Sur Le Compositeur Onduleur	Oui Type De Gaz	600 Rands Dimensions	585x1670x703 Capacité (L)	260L Compositeur Linéaire	Oui / Avec 10 Ans De Garantie Sur Le Compositeur Onduleur	Oui Type De Gaz	600 Rands Dimensions",
    reviews: [
      {
        rating: 4.8,
        text: "Réfrigérateur à congélateur supérieur avec refroidissement par porte, LINEAR Cooling™ et HygieneFresh+™",
      },
      {
        rating: 4.8,
        text: "Réfrigérateur LG supérieur",
      },
    ],
    avgRating: 4.7,
  },
  
  {
    id: "38",
    productName: "GC-B414ELFM BOTTOM FREEZER",
    imgUrl: productImg38,
    category: "LG refrigerateur",
    price: 1500,
    slider: "https://www.lg.com/africa/images/banners/RHQ-GMC_Hero-Banner_EN_NG_PC_Main-KV_1600x80011.jpg",
    shortDesc:
      "Compresseur à onduleur intelligent Refroidissement linéaire Débit d'air multiple Grande capacité",
    description:
      "CAPACITÉ(L) Volume net (volume de stockage ISO)	324 litres DIMENSIONS Dimensions Largeur x Hauteur x Profondeur (mm)	595 x 1 860 x 707 CARACTÉRISTIQUES GÉNÉRALES Type de porte	Congélateur 1 porteCouleur	Platine Argent Type de poignée	Poignée de barre à ouverture facileMachine à glaçons mobile et torsadée	Oui Rabattre	OuiCompresseur	Onduleur intelligent - Garantie 10 ans Refroidissement linéaire	Oui",
    reviews: [
      {
        rating: 4.7,
        text: "Le nouveau design s’intègre parfaitement aux armoires de cuisine standard pour un look épuré et minimaliste.",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "39",
    productName: "GC-F411ELDM BOTTOM FREEZER",
    imgUrl: productImg39,
    category: "LG refrigerateur",
    price: 1500,
    slider: "https://www.lg.com/africa/images/banners/RHQ-GMC_Hero-Banner_EN_NG_PC_Main-KV_1600x80011.jpg",
    shortDesc:
      "Refroidissement linéaire Refroidissement des portes+ Débit d'air multiple",
    description:
      "CAPACITÉ(L) Volume net (volume de stockage ISO)	380 L DIMENSIONS Dimensions Largeur x Hauteur x Profondeur (mm)	595 x 1 860 x 707 CARACTÉRISTIQUES GÉNÉRALES Type de porte	Réfrigérateur 1 porteConception	Ajustement sans couture Finition	Platine ArgentFontaine à eau	Oui Type de poignée	Poignée de barre à ouverture facileCompresseur	Onduleur intelligent - Garantie 10 ans Refroidissement linéaire	OuiRefroidissement des portes+	Oui Débit d'air multiple	OuiBac à légumes équilibré et humide	Oui Éclairage de panneau LED	Oui",
    reviews: [
      {
        rating: 4.8,
        text: "La tour lumineuse et les LED supérieures éclairent chaque pièce à l'intérieu",
      },
      {
        rating: 4.8,
        text: "L'eau froide est toujours disponible grâce au distributeur intégré de type fin.",
      },
    ],
    avgRating: 4.7,
  },
   // Ajout 
   {
    id: "3911",
    productName: "REF 399(306L)",
    imgUrl: productImg39,
    category: "LG refrigerateur",
    price: '',
    slider: SliderRefLg,
    shortDesc:
      "Refroidissement linéaire Refroidissement des portes+ Débit d'air multiple",
    description:
      "Refroidissement linéaire Refroidissement des portes+ Débit d'air multiple",
    reviews: [
      {
        rating: 4.8,
        text: "La tour lumineuse et les LED supérieures éclairent chaque pièce à l'intérieu",
      },
      {
        rating: 4.8,
        text: "L'eau froide est toujours disponible grâce au distributeur intégré de type fin.",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "3912",
    productName: "Ref 459 (341L)",
    imgUrl: productImg39,
    category: "LG refrigerateur",
    price: '',
    slider: SliderRefLg,
    shortDesc:
      "Refroidissement linéaire Refroidissement des portes+ Débit d'air multiple",
    description:
      "Refroidissement linéaire Refroidissement des portes+ Débit d'air multiple",
    reviews: [
      {
        rating: 4.8,
        text: "La tour lumineuse et les LED supérieures éclairent chaque pièce à l'intérieu",
      },
      {
        rating: 4.8,
        text: "L'eau froide est toujours disponible grâce au distributeur intégré de type fin.",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "40",
    productName: "GN-B392PXBG REF 392",
    imgUrl: productImg40,
    category: "LG refrigerateur",
    price: 1450,
    slider: SliderRefLg,
    shortDesc:
      "Capacity : 195L - Capacity : 195L - Top Freezer Refrigerator- Smart Inverter Compressor - LinearCooling™- Door Cooling+™- Multi Air Flow- Smart Inverter Compressor™",
    description:
      "Capacity : 195L - Capacity : 195L - Top Freezer Refrigerator- Smart Inverter Compressor - LinearCooling™- Door Cooling+™- Multi Air Flow- Smart Inverter Compressor™",
    reviews: [
      {
        rating: 4.6,
        text: "LG Ref 392 GN-B392PXBG ",
      },
      {
        rating: 4.9,
        text: "Colour : Silver. - Smart Inverter Compressor. - Door Cooling+",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "41",
    productName: "NO FROST TOP Freezer Refrigerator REF 492",
    imgUrl: productImg41,
    category: "LG refrigerateur",
    price: '',
    slider: SliderRefLg,
    shortDesc:
      "Capacity : 490L - Capacity : 490L - Top Freezer With Hygiene Fresh - Moist Balance Crisper - Hygiene Fresh - Temperature Control - Led Lighting",
    description:
      "Capacity : 490L - Capacity : 490L - Top Freezer With Hygiene Fresh - Moist Balance Crisper - Hygiene Fresh - Temperature Control - Led Lighting",
    reviews: [
      {
        rating: 4.8,
        text: "réduit les fluctuations de température, conservant la saveur du frais pendant *jusqu'à 7 jours.",
      },
      {
        rating: 4.8,
        text: "Conserve la fraîcheur de la ferme plus longtemps",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "42",
    productName: "DOUBLE DOOR Refrigerator-Congelateur GC-F689",
    imgUrl: productImg42,
    category: "LG refrigerateur",
    price: '',
    slider: SliderRefLg,
    shortDesc:
      "Capacity - 446 L, Colour : Platinium Silver - 24'' Counter Depth Bottom Freezer  - 11.9 cu.ft. Capacity - Premium LED Interior Lighting - Multi-Air Flow Cooling - Inverter Linear Compressor - DoorCooling+",
    description:
      "Le filtre à air intelligent peut non seulement éliminer les bactéries jusqu'à 99,999 %, mais également minimiser les mauvaises odeurs dans le réfrigérateur. De plus, vous pouvez conserver vos aliments plus frais dans un état plus propre.",
    reviews: [
      {
        rating: 4.7,
        text: "  Éclairage LED L'éclairage LED est plus économe en énergie et a une durée de vie plus longue que l'éclairage à ampoule classique. Il permet des aliments plus frais avec moins de dégagement de chaleur.",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "43",
    productName: "DOUBLE DOOR Refrigerator GC-F682 HLHU X LARGE 471L",
    imgUrl: productImg43,
    category: "LG refrigerateur",
    price: 1600,
    slider: SliderRefLg,
    shortDesc:
      "- Capacity : 471 L- Top Freezer - LINEAR Cooling™- Door Cooling+™- Hygiene FRESH+™ - Fresh 0 Zone- 32% Energy Saving - Water Dispensor X",
    description:
      "- Capacity : 471 L- Top Freezer - LINEAR Cooling™- Door Cooling+™- Hygiene FRESH+™ - Fresh 0 Zone- 32% Energy Saving - Water Dispensor X",
    reviews: [
      {
        rating: 4.8,
        text: "C'est Magnifique",
      },
      {
        rating: 4.8,
        text: "C'est Magnifique",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "44",
    productName: "DOUBLE DOOR REF GL T682HQCL",
    imgUrl: productImg44,
    category: "LG refrigerateur",
    price: 1700,
    slider: SliderRefLg,
    shortDesc:
      "LINEARCooling™ DoorCooling+™ HygièneFresh+™ Zone fraîche 0 Compresseur linéaire à inverseur SmartThinQ™",
    description:
      "SYSTÈME DE GLACE ET D'EAU Distributeur d'eau seulement	ExterneMachine à glaçons automatique	Non Machine à glaçons_Manuel (mobile)",
    reviews: [
      {
        rating: 4.6,
        text: "Contrôle rapide et pratique Avec Smart ThinQ™, contrôlez et diagnostiquez votre réfrigérateur par votre smartphone même lorsque vous n'êtes pas chez vous. Réglez facilement la température, contrôlez HygieneFresh+™ et diagnostiquez votre réfrigérateur uniquement en touchant votre smartphone",
      },
      {
        rating: 4.9,
        text: "Contrôle rapide et pratique Avec Smart ThinQ™, contrôlez et diagnostiquez votre réfrigérateur par votre smartphone même lorsque vous n'êtes pas chez vous. Réglez facilement la température, contrôlez HygieneFresh+™ et diagnostiquez votre réfrigérateur uniquement en touchant votre smartphone",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "45",
    productName: "DOUBLE DOOR REF GL C682HLCL",
    imgUrl: productImg45,
    category: "LG refrigerateur",
    price: 1600,
    slider: SliderRefLg,
    shortDesc:
      "Capacité : 471 Litres Réfrigérant : R600a Couleur: Argent",
    description:
      "  - Type de Produit :  Réfrigérateur Double Battant - Marque :  LG -Modèle :GL-C682HLCL - Capacité :  473 litres - Type :  Autoportant - Style :  Porte française - Profondeur de comptoir :  Oui - Distributeur d'eau :  Extérieur - Machine à glacer :  Oui - Type de dégivrage :  Automatique - Type d'étagères :  Verre - Mode Sabbat :  Oui -Energy Star  : Oui - Diagnostic intelligent - InstaView Porte-à-porte avec frappe - Smart ThinQ fonctionne - Sécurité enfants - Réfrigération intelligente Plus - Refroidissement de porte Plus",
    reviews: [
      {
        rating: 4.8,
        text: "Réfrigérateur LG GL-C682HLCL À Compartiment Congélateur Supérieur De 471 L Avec Refroidissement De Porte, LINEAR Cooling™ Et HygieneFresh+™",
      },
      {
        rating: 4.8,
        text: "Réfrigérateur LG GL-C682HLCL À Compartiment Congélateur Supérieur De 471 L Avec Refroidissement De Porte, LINEAR Cooling™ Et HygieneFresh+™",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "46",
    productName: "DIGITAL Refrigerator NOFROST GN-H722 HLHU",
    imgUrl: productImg46,
    category: "LG refrigerateur",
    price: 2000,
    slider: SliderRefLg,
    shortDesc:
      "Capacity : 516L - Capacity : 516L - 18 Cubic Feet, Digital  - LINEAR Cooling™ - DoorCooling+™  - Smart ThinQ™ - HygieneFresh+™",
    description:
      "Capacity : 516L - Capacity : 516L - 18 Cubic Feet, Digital  - LINEAR Cooling™ - DoorCooling+™  - Smart ThinQ™ - HygieneFresh+™",
    reviews: [
      {
        rating: 4.7,
        text: "Débit d'air multiple Des températures optimales partout",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "47",
    productName: "STAINLESS STEEL VCM TOP FREEZER GN-A 782 HLHU 549L",
    imgUrl: productImg47,
    category: "LG refrigerateur",
    price: '',
    slider: SliderRefLg,
    shortDesc:
      "Capacity : 546L - Capacity : 546L (Gross) - Colour : Shiny Steel - Greater energy efficiency  - LINEAR Cooling™ - DoorCooling+™ - Inverter Linear Compressor - Door-in-Door™ - HygieneFresh+™",
    description:
      "Capacity : 546L - Capacity : 546L (Gross) - Colour : Shiny Steel - Greater energy efficiency  - LINEAR Cooling™ - DoorCooling+™ - Inverter Linear Compressor - Door-in-Door™ - HygieneFresh+™",
    reviews: [
      {
        rating: 4.8,
        text: "Débit d'air multiple Des températures optimales partout",
      },
      {
        rating: 4.8,
        text: "Débit d'air multiple Des températures optimales partout",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "48",
    productName: "REF 882 ULTRA XLARGE REF",
    imgUrl: productImg48,
    category: "LG refrigerateur",
    price: 2200,
    slider: SliderRefLg,
    shortDesc:
      "- 257cses Capacity : 171L - Wide Top Freezer Refrigerator- Smart Inverter Compressor- Water Dispenser- Hygiene Fresh- Eco LED Interior Light- Total No Frost- 1840 x 840 x 730 (H:W:D mm)",
    description:
      "- 257cses Capacity : 171L - Wide Top Freezer Refrigerator- Smart Inverter Compressor- Water Dispenser- Hygiene Fresh- Eco LED Interior Light- Total No Frost- 1840 x 840 x 730 (H:W:D mm)",
    reviews: [
      {
        rating: 4.6,
        text: "la fonction de refroidissement de la porte sur le côté peut conserver les objets stockés au frais. Refroidissement des portes + ™ Offre une fraîcheur uniforme et plus rapide.",
      },
      {
        rating: 4.9,
        text: "la fonction de refroidissement de la porte sur le côté peut conserver les objets stockés au frais. Refroidissement des portes + ™ Offre une fraîcheur uniforme et plus rapide.",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "49",
    productName: "GCFB507 PQAM 507L",
    imgUrl: productImg49,
    category: "LG refrigerateur",
    price: 1800,
    slider: SliderRefLg,
    shortDesc:
      " 507L-Total No Frost Multi Air Flow Touch LED Display LED Lighting Tempered Glass",
    description:
      " 507L-Total No Frost Multi Air Flow Touch LED Display LED Lighting Tempered Glass",
    reviews: [
      {
        rating: 4.8,
        text: "Les aliments restent frais et les boissons sont glacées sur n'importe quelle étagère avec des performances de refroidissement uniformes et plus rapides.",
      },
      {
        rating: 4.8,
        text: "Les aliments restent frais et les boissons sont glacées sur n'importe quelle étagère avec des performances de refroidissement uniformes et plus rapides.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "50",
    productName: "SIDE BY SIDE REF X257CSES",
    imgUrl: productImg50,
    category: "LG refrigerateur",
    price: 3200,
    slider: "https://www.lg.com/africa/images/support/LG.com_HeroBanner_2%20Year%20Warranty%20Banner%201600x800px_%20Desktop%20.jpg",
    shortDesc:
      "Compresseur linéaire Inverter InstaView Door-in-Door® Distributeur d’eau UVnano® Hygiene Fresh+™ DoorCooling+ ThinQ",
    description:
      "Compresseur linéaire Inverter InstaView Door-in-Door® Distributeur d’eau UVnano® Hygiene Fresh+™ DoorCooling+ ThinQ",
    reviews: [
      {
        rating: 4.7,
        text: "Conserve la fraîcheur de la ferme plus longtemps LinearCooling™ réduit les fluctuations de température, conservant la saveur du frais pendant *jusqu'à 7 jours.",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "51",
    productName: "SIDE BY SIDE B257JLYL",
    imgUrl: productImg51,
    category: "LG refrigerateur",
    price: 2600,
    slider: "https://www.lg.com/africa/images/support/LG.com_HeroBanner_2%20Year%20Warranty%20Banner%201600x800px_%20Desktop%20.jpg",
    shortDesc:
      "LINEARCooling™ Multi Air Flow Porte UltraSleek Grande capacité ThinQ™ Compresseur Ã  onduleur Smart Inverter",
    description:
      "LINEARCooling™ Multi Air Flow Porte UltraSleek Grande capacité ThinQ™ Compresseur Ã  onduleur Smart Inverter",
    reviews: [
      {
        rating: 4.8,
        text: "Débit d'air multiple Des températures optimales partout L'air circule dans toutes les directions pour garder vos aliments frais, peu importe où vous les placez.",
      },
      {
        rating: 4.8,
        text: "Débit d'air multiple Des températures optimales partout L'air circule dans toutes les directions pour garder vos aliments frais, peu importe où vous les placez.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "52",
    productName: "SIDE BY SIDE REF L257SLRS",
    imgUrl: productImg52,
    category: "LG refrigerateur",
    price: 2500,
    slider: "https://www.lg.com/africa/images/support/LG.com_HeroBanner_2%20Year%20Warranty%20Banner%201600x800px_%20Desktop%20.jpg",
    shortDesc:
      " Porte UltraSleek LINEARCooling™ Grande capacité hinQ™ Compresseur Ã  onduleur Smart Inverter",
    description:
      "CAPACITÉ 674L DIMENSION (LXHXP, CM) 913x1790x743 CARACTÉRISTIQUE CLÉ #1 Compresseur à inverseur intelligent™ CARACTÉRISTIQUE CLÉ #2 Réfrigérateurs porte-dans-porte®",
    reviews: [
      {
        rating: 4.6,
        text: "Connectez-vous et contrôlez depuis n'importe où",
      },
      {
        rating: 4.9,
        text: "Connectez-vous et contrôlez depuis n'importe où",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "53",
    productName: "SIDE BY SIDE J257SLRS",
    imgUrl: productImg53,
    category: "LG refrigerateur",
    price: 2900,
    slider: "https://www.lg.com/africa/images/support/LG.com_HeroBanner_2%20Year%20Warranty%20Banner%201600x800px_%20Desktop%20.jpg",
    shortDesc:
      "Capacity : 674 L - Capacity : 674 L - Side by Side Refrigerator | Multi Air Flow  - Smart Diagnosis™ - Write a review - UltraSleek Door - LinearCooling™ - Large Capacity - ThinQ™ -Smart Inverter Compressor™",
    description:
      "Capacity : 674 L - Capacity : 674 L - Side by Side Refrigerator | Multi Air Flow  - Smart Diagnosis™ - Write a review - UltraSleek Door - LinearCooling™ - Large Capacity - ThinQ™ -Smart Inverter Compressor™",
    reviews: [
      {
        rating: 4.8,
        text: "Le résultat peut varier en fonction de l'utilisation réelle.",
      },
      {
        rating: 4.8,
        text: "Le résultat peut varier en fonction de l'utilisation réelle.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "54",
    productName: "REF 4 DOORS GC-X22FTQLL",
    imgUrl: productImg54,
    category: "LG refrigerateur",
    price: 3200,
    slider: "https://www.lg.com/africa/images/support/LG.com_HeroBanner_2%20Year%20Warranty%20Banner%201600x800px_%20Desktop%20.jpg",
    shortDesc:
      "Conception compacte, capacité maximale Toquez deux fois pour voir l’intérieur grâce à la fonction InstaView Door-in-Door™ Compresseur linéaire Inverter à longue durée et économie d’énergie Plus frais plus longtemps avec NatureFRESH SmartThinQ™ avec connexion Wi-Fi",
    description:
      "Conception compacte, capacité maximale Toquez deux fois pour voir l’intérieur grâce à la fonction InstaView Door-in-Door™ Compresseur linéaire Inverter à longue durée et économie d’énergie Plus frais plus longtemps avec NatureFRESH SmartThinQ™ avec connexion Wi-Fi",
    reviews: [
      {
        rating: 4.8,
        text: "Premium en Compact Capacité maximisée, taille sans compromis",
      },
      {
        rating: 4.8,
        text: "le réfrigérateur que vous choisirez doit s'adapter à l'alcôve de votre cuisine, alors assurez-vous de vérifier sa taille. Le nouveau réfrigérateur Slim French Door mesure 835 mm de largeur, soit moins que les réfrigérateurs classiques à portes françaises, et s'adapte facilement à la plupart des cuisines.",
      },
    ],
    avgRating: 4.7,
  },

 {
    id: "55",
    productName: "ON OFF AC 9000BTU CO9TZCAA",
    imgUrl: productImg57,
    category: "LG climatiseur",
    price: 375,
    slider: SliderSplitLg,
    shortDesc:
      "- CO9TZCAA ON OFF no Inverter Refroidissement rapide & Économie d’énergie Gen Mode+™ Silencieux DESIGN SIMPLE ET ÉLANCÉ AVEC ÉCRAN INVISIBLE",
    description:
      "Refroidissement rapide Économie d'énergie Moins de bruit Compresseur INVERSEUR DOUBLE™",
    reviews: [
      {
        rating: 4.7,
        text: "Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
    ],
    avgRating: 4.5,
  },

   {
    id: "5531",
    productName: "ON OFF AC 1200BTU CO9TZCAA",
    imgUrl: productImg57,
    category: "LG climatiseur",
    price: 400,
    slider: SliderSplitLg,
    shortDesc:
      "- CO9TZCAA ON OFF no Inverter Refroidissement rapide & Économie d’énergie Gen Mode+™ Silencieux DESIGN SIMPLE ET ÉLANCÉ AVEC ÉCRAN INVISIBLE",
    description:
      "Refroidissement rapide Économie d'énergie Moins de bruit Compresseur INVERSEUR DOUBLE™",
    reviews: [
      {
        rating: 4.7,
        text: "Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "56",
    productName: "ON OFF AC 18000BTU C12TZCAA",
    imgUrl: productImg57,
    category: "LG climatiseur",
    price: 600,
    slider: SliderSplitLg,
    shortDesc:
      "C12TZCAA ON OFF no Inverter - Inverter Compressor dual inverter- 70% Energy Saving- 40% Faster Cooling- Fast Heating- Low Noise",
    description:
      "Refroidissement rapide Économie d'énergie Moins de bruit Compresseur elevé",
    reviews: [
      {
        rating: 4.8,
        text: "Mettre la vidéo en pause Économisez sur vos factures d'énergie et pour la planète",
      },
      {
        rating: 4.8,
        text: "Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "57",
    productName: "ON OFF 24000 BTU C24TZCAA",
    imgUrl: productImg57,
    category: "LG climatiseur",
    price: 800,
    slider: SliderSplitLg,
    shortDesc:
      "- C24TZCAA ON OFF no Inverter - Inverter Compressor dual inverter- 70% Energy Saving- 40% Faster Cooling- Fast Heating- Low Noise",
    description:
      "- C24TZCAA ON OFF no Inverter - Inverter Compressor dual inverter- 70% Energy Saving- 40% Faster Cooling- Fast Heating- Low Noise",
    reviews: [
      {
        rating: 4.6,
        text: "Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
      {
        rating: 4.9,
        text: "Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "58",
    productName: "DUALCOOL Anti-Moustique 9 00BTU Q09AA28B",
    imgUrl: productImg58,
    category: "LG climatiseur",
    price: 450,
    slider: SliderSplitLg,
    shortDesc:
      "Anti-moustique Refroidissement rapide & Économie d’énergie Dual Inverter Compressor™ Gen Mode+™ Silencieux DESIGN SIMPLE ET ÉLANCÉ AVEC ÉCRAN INVISIBLE",
    description:
      "Nécessité de protéger l'Afrique des moustiques de manière non nocive Anti-moustique Refroidissement rapide & Économie d’énergie Dual Inverter Compressor™ Gen Mode+™ Silencieux DESIGN SIMPLE ET ÉLANCÉ AVEC ÉCRAN INVISIBLE",
    reviews: [
      {
        rating: 4.7,
        text: "Un bon travail passe inaperçu, elimine les moustiques",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "59",
    productName: "DUALCOOL Anti-Moustique 12 000BTU Q12JA28J",
    imgUrl: productImg59,
    category: "LG climatiseur",
    price: 500,
    slider: SliderSplitLg,
    shortDesc:
      "Anti-moustique Refroidissement rapide & Économie d’énergie Dual Inverter Compressor™ Gen Mode+™ Silencieux DESIGN SIMPLE ET ÉLANCÉ AVEC ÉCRAN INVISIBLE",
    description:
      "Nécessité de protéger l'Afrique des moustiques de manière non nociveAnti-moustique Refroidissement rapide & Économie d’énergie Dual Inverter Compressor™ Gen Mode+™ Silencieux DESIGN SIMPLE ET ÉLANCÉ AVEC ÉCRAN INVISIBLE",
    reviews: [
      {
        rating: 4.8,
        text: "Un bon travail passe inaperçu, elimine les moustiques",
      },
      {
        rating: 4.8,
        text: "Un bon travail passe inaperçu, elimine les moustiques",
      },
    ],
    avgRating: 4.7,
  },{
    id: "60",
    productName: "DUALCOOL inverteur Anti-Moustique 18 000BTU",
    imgUrl: productImg60,
    category: "LG climatiseur",
    price: 700,
    slider: SliderSplitLg,
    shortDesc:
      "Anti-moustique Refroidissement rapide & Économie d’énergie Dual Inverter Compressor™ Gen Mode+™ Silencieux DESIGN SIMPLE ET ÉLANCÉ AVEC ÉCRAN INVISIBLE",
    description:
      "Nécessité de protéger l'Afrique des moustiques de manière non nocive Anti-moustique Refroidissement rapide & Économie d’énergie Dual Inverter Compressor™ Gen Mode+™ Silencieux DESIGN SIMPLE ET ÉLANCÉ AVEC ÉCRAN INVISIBLE",
    reviews: [
      {
        rating: 4.6,
        text: "Un bon travail passe inaperçu, elimine les moustiques.",
      },
      {
        rating: 4.9,
        text: "Un bon travail passe inaperçu, elimine les moustiques.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "60",
    productName: "DUALCOOL inverteur Anti-Moustique 24 000BTU",
    imgUrl: productImg60,
    category: "LG climatiseur",
    price: 900,
    slider: SliderSplitLg,
    shortDesc:
      "Anti-moustique Refroidissement rapide & Économie d’énergie Dual Inverter Compressor™ Gen Mode+™ Silencieux DESIGN SIMPLE ET ÉLANCÉ AVEC ÉCRAN INVISIBLE",
    description:
      "Nécessité de protéger l'Afrique des moustiques de manière non nocive Anti-moustique Refroidissement rapide & Économie d’énergie Dual Inverter Compressor™ Gen Mode+™ Silencieux DESIGN SIMPLE ET ÉLANCÉ AVEC ÉCRAN INVISIBLE",
    reviews: [
      {
        rating: 4.6,
        text: "Un bon travail passe inaperçu, elimine les moustiques.",
      },
      {
        rating: 4.9,
        text: "Un bon travail passe inaperçu, elimine les moustiques.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "61",
    productName: "18000 BTU DUAL COOL",
    imgUrl: productImg61,
    category: "LG climatiseur",
    price: 750,
    slider: SliderSplitLg,
    shortDesc:
      "Refroidissement rapide Économie d'énergie Moins de bruit Compresseur INVERSEUR DOUBLE™",
    description:
      "Refroidissement rapide Économie d'énergie Moins de bruit Compresseur INVERSEUR DOUBLE™",
    reviews: [
      {
        rating: 4.7,
        text: "Économisez sur vos factures d'énergie et pour la planète Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
    ],
    avgRating: 4.5,
  },

  {/**{
    id: "62",
    productName: "24000 BTU DUAL COOL",
    imgUrl: productImg62,
    category: "LG climatiseur",
    price: 950,
    slider: SliderRefLg,
    shortDesc:
      "Refroidissement rapide Économie d'énergie Moins de bruit Compresseur INVERSEUR DOUBLE™",
    description:
      "Refroidissement rapide Économie d'énergie Moins de bruit Compresseur INVERSEUR DOUBLE™",
    reviews: [
      {
        rating: 4.8,
        text: "Économisez sur vos factures d'énergie et pour la planète Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
      {
        rating: 4.8,
        text: "Économisez sur vos factures d'énergie et pour la planète Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
    ],
    avgRating: 4.7,
  }, */},

  {
    id: "63",
    productName: "12000 BTU DUAL COOL BLACK MIRROR inventeur S4-Q12ARJB",
    imgUrl: productImg63,
    category: "LG climatiseur",
    price: 900,
    slider: SliderSplitLg,
    shortDesc:
      "Refroidissement rapide Économie d'énergie Moins de bruit Compresseur INVERSEUR DOUBLE™",
    description:
      "Refroidissement rapide Économie d'énergie Moins de bruit Compresseur INVERSEUR DOUBLE™",
    reviews: [
      {
        rating: 4.6,
        text: "Économisez sur vos factures d'énergie et pour la planète Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
      {
        rating: 4.9,
        text: "Économisez sur vos factures d'énergie et pour la planète Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "64",
    productName: "18000 BTU DUAL COOL BLACK MIRROR inventeur S4-Q18KLRJA",
    imgUrl: productImg64,
    category: "LG climatiseur",
    price: 1100,
    slider: SliderSplitLg,
    shortDesc:
      "Refroidissement rapide Économie d'énergie Moins de bruit Compresseur INVERSEUR DOUBLE™",
    description:
      "Refroidissement rapide Économie d'énergie Moins de bruit Compresseur INVERSEUR DOUBLE™",
    reviews: [
      {
        rating: 4.7,
        text: "Économisez sur vos factures d'énergie et pour la planète Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "65",
    productName: "24000 BTU S4-Q24K2RTD",
    imgUrl: productImg65,
    category: "LG climatiseur",
    price: 1400,
    slider: SliderSplitLg,
    shortDesc:
      "Refroidissement rapide Économie d'énergie Moins de bruit Compresseur INVERSEUR DOUBLE™",
    description:
      "Refroidissement rapide Économie d'énergie Moins de bruit Compresseur INVERSEUR DOUBLE™",
    reviews: [
      {
        rating: 4.8,
        text: "Économisez sur vos factures d'énergie et pour la planète Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
      {
        rating: 4.8,
        text: "Économisez sur vos factures d'énergie et pour la planète Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "66",
    productName: "CASSETTE UNIT 18 000 BTU INVERTEUR ATNW18GPLS1",
    imgUrl: productImg66,
    category: "LG climatiseur",
    price: 1500,
    slider: SliderSplitLg,
    shortDesc:
      "- Heating and Cooling - R410 - Low Power Inverter Compressor - Plasma Air Purifying System - 4 Way Swirl Swing - Auto Restart - Jet Cool - Sleep Mode - Healthy Dehumidification - High Ceiling Operation - Wide Air Flow",
    description:
      "Cassette de plafond Alliance, onduleur 18 000 Btu/h Modèle FOUCI 18 Gaz R410A écologique. Technologie d'onduleur super économe en énergie. Conception affleurante. 6 modèles de 12 000 Btu/h à 60 000 Btu/h. Refroidissement et chauffage. La tuyauterie entre l'extérieur et l'extérieur peut atteindre un maximum. de 65m. Pour des surfaces jusqu'à 36 m². Niveau de bruit : 46 dB(A) Cette unité utilise une alimentation monophasée. Renseignez-vous sur l'installation car il ne s'agit pas d'un produit standard. Reportez-vous à la brochure pour plus de spécifications, y compris les dimensions. Les images des unités extérieures peuvent différer des images réelles selon le modèle. Garantie de 12 mois. Livraison gratuite dans tout le pays – Afrique du Sud. Excellent rapport qualité/prix !",
    reviews: [
      {
        rating: 4.6,
        text: "Économisez sur vos factures d'énergie et pour la planète Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
      {
        rating: 4.9,
        text: "Économisez sur vos factures d'énergie et pour la planète Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "67",
    productName: "CASSETTE UNIT 24 000 BTU INVERTEUR ATNW24GPLS1",
    imgUrl: productImg67,
    category: "LG climatiseur",
    price: 1750,
    slider: SliderSplitLg,
    shortDesc:
      "- Heating and Cooling - R410 - Low Power Inverter Compressor - Plasma Air Purifying System - 4 Way Swirl Swing - Auto Restart - Jet Cool - Sleep Mode - Healthy Dehumidification - High Ceiling Operation - Wide Air Flow",
    description:
      "Cassette de plafond Alliance, 24000BTU  Modèle FOUCI 18 Gaz R410A écologique. Technologie d'onduleur super économe en énergie. Conception affleurante. 6 modèles de 12 000 Btu/h à 60 000 Btu/h. Refroidissement et chauffage. La tuyauterie entre l'extérieur et l'extérieur peut atteindre un maximum. de 65m. Pour des surfaces jusqu'à 36 m². Niveau de bruit : 46 dB(A) Cette unité utilise une alimentation monophasée. Renseignez-vous sur l'installation car il ne s'agit pas d'un produit standard. Reportez-vous à la brochure pour plus de spécifications, y compris les dimensions. Les images des unités extérieures peuvent différer des images réelles selon le modèle. Garantie de 12 mois. Livraison gratuite dans tout le pays – Afrique du Sud. Excellent rapport qualité/prix !",
    reviews: [
      {
        rating: 4.7,
        text: "Économisez sur vos factures d'énergie et pour la planète Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
    ],
    avgRating: 4.5,
  },

  
  {
    id: "6831",
    productName: "CASSETTE UNIT 18 000 BTU INVERTEUR ATNW18GQSK1",
    imgUrl: productImg68,
    category: "LG climatiseur",
    price: 2000,
    slider: SliderSplitLg,
    shortDesc:
      "- Heating and Cooling - R410 - Low Power Inverter Compressor - Plasma Air Purifying System - 4 Way Swirl Swing - Auto Restart - Jet Cool - Sleep Mode - Healthy Dehumidification - High Ceiling Operation - Wide Air Flow",
    description:
      "Cassette de plafond Alliance, onduleur 36 000 Btu/h Modèle FOUCI 18 Gaz R410A écologique. Technologie d'onduleur super économe en énergie. Conception affleurante. 6 modèles de 12 000 Btu/h à 60 000 Btu/h. Refroidissement et chauffage. La tuyauterie entre l'extérieur et l'extérieur peut atteindre un maximum. de 65m. Pour des surfaces jusqu'à 36 m². Niveau de bruit : 46 dB(A) Cette unité utilise une alimentation monophasée. Renseignez-vous sur l'installation car il ne s'agit pas d'un produit standard. Reportez-vous à la brochure pour plus de spécifications, y compris les dimensions. Les images des unités extérieures peuvent différer des images réelles selon le modèle. Garantie de 12 mois. Livraison gratuite dans tout le pays – Afrique du Sud. Excellent rapport qualité/prix !",
    reviews: [
      {
        rating: 4.8,
        text: "Économisez sur vos factures d'énergie et pour la planète Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
      {
        rating: 4.8,
        text: "Économisez sur vos factures d'énergie et pour la planète Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "68",
    productName: "CASSETTE UNIT 36 000 BTU INVERTEUR ATNW36GMLS1",
    imgUrl: productImg68,
    category: "LG climatiseur",
    price: 3800,
    slider: SliderSplitLg,
    shortDesc:
      "- Heating and Cooling - R410 - Low Power Inverter Compressor - Plasma Air Purifying System - 4 Way Swirl Swing - Auto Restart - Jet Cool - Sleep Mode - Healthy Dehumidification - High Ceiling Operation - Wide Air Flow",
    description:
      "Cassette de plafond Alliance, onduleur 36 000 Btu/h Modèle FOUCI 18 Gaz R410A écologique. Technologie d'onduleur super économe en énergie. Conception affleurante. 6 modèles de 12 000 Btu/h à 60 000 Btu/h. Refroidissement et chauffage. La tuyauterie entre l'extérieur et l'extérieur peut atteindre un maximum. de 65m. Pour des surfaces jusqu'à 36 m². Niveau de bruit : 46 dB(A) Cette unité utilise une alimentation monophasée. Renseignez-vous sur l'installation car il ne s'agit pas d'un produit standard. Reportez-vous à la brochure pour plus de spécifications, y compris les dimensions. Les images des unités extérieures peuvent différer des images réelles selon le modèle. Garantie de 12 mois. Livraison gratuite dans tout le pays – Afrique du Sud. Excellent rapport qualité/prix !",
    reviews: [
      {
        rating: 4.8,
        text: "Économisez sur vos factures d'énergie et pour la planète Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
      {
        rating: 4.8,
        text: "Économisez sur vos factures d'énergie et pour la planète Réduisez votre consommation d'énergie et votre facture d'électricité grâce à un refroidissement plus efficace.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "69",
    productName: "CASSETTE UNIT 48 000 BTU INVERTEUR ATNW48GMLS1",
    imgUrl: productImg69,
    category: "LG climatiseur",
    price: 4200,
    slider: SliderSplitLg,
    shortDesc:
      "- Heating and Cooling - R410 - Low Power Inverter Compressor - Plasma Air Purifying System - 4 Way Swirl Swing - Auto Restart - Jet Cool - Sleep Mode - Healthy Dehumidification - High Ceiling Operation - Wide Air Flow",
    description:
      "Cassette de plafond Alliance, onduleur 48 000 Btu/h Modèle FOUCI 18 Gaz R410A écologique. Technologie d'onduleur super économe en énergie. Conception affleurante. 6 modèles de 12 000 Btu/h à 60 000 Btu/h. Refroidissement et chauffage. La tuyauterie entre l'extérieur et l'extérieur peut atteindre un maximum. de 65m. Pour des surfaces jusqu'à 36 m². Niveau de bruit : 46 dB(A) Cette unité utilise une alimentation monophasée. Renseignez-vous sur l'installation car il ne s'agit pas d'un produit standard. Reportez-vous à la brochure pour plus de spécifications, y compris les dimensions. Les images des unités extérieures peuvent différer des images réelles selon le modèle. Garantie de 12 mois. Livraison gratuite dans tout le pays – Afrique du Sud. Excellent rapport qualité/prix !",
    reviews: [
      {
        rating: 4.6,
        text: "La cassette de plafond de LG améliore le confort de votre demeure tout en en préservant son esthétique, ce qui en fait l’atout idéal pour votre entreprise. LG révolutionnera la manière dont vous menez vos activités.",
      },
      {
        rating: 4.9,
        text: "La cassette de plafond de LG améliore le confort de votre demeure tout en en préservant son esthétique, ce qui en fait l’atout idéal pour votre entreprise. LG révolutionnera la manière dont vous menez vos activités.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "70",
    productName: "STANDING UNITS 30 000 AC APNQ-AQ-30GS1S1 INVERTEUR",
    imgUrl: productImg70,
    category: "LG climatiseur",
    price: 2200,
    slider: SliderSplitLg,
    shortDesc:
      "Flux d'air puissant Avec une vitesse et un volume d'air puissants, le flux d'air peut atteindre jusqu'à 20 mètres. Cela permet un refroidissement frais et confortable beaucoup plus tôt.",
    description:
      "Réponse rapide L'unité au sol peut atteindre une température définie dans un laps de temps plus court tout en fournissant un refroidissement puissant. Pendant ce temps, la fonction Power Heating offre l’angle de flux d’air optimal, garantissant une performance de chauffage plus rapide.",
    reviews: [
      {
        rating: 4.7,
        text: "Conception élégante Le climatiseur au sol est une solution idéale pour les intérieurs modernes de votre maison ou de votre bureau.",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "71",
    productName: "STANDING UNITS 48000 BTU (APNQ48LLT3S1) AC",
    imgUrl: productImg71,
    category: "LG climatiseur",
    price: 3750,
    slider: SliderSplitLg,
    shortDesc:
      "Flux d'air puissant Avec une vitesse et un volume d'air puissants, le flux d'air peut atteindre jusqu'à 20 mètres. Cela permet un refroidissement frais et confortable beaucoup plus tôt.",
    description:
      "Réponse rapide L'unité au sol peut atteindre une température définie dans un laps de temps plus court tout en fournissant un refroidissement puissant. Pendant ce temps, la fonction Power Heating offre l’angle de flux d’air optimal, garantissant une performance de chauffage plus rapide.",
    reviews: [
      {
        rating: 4.8,
        text: "Conception élégante Le climatiseur au sol est une solution idéale pour les intérieurs modernes de votre maison ou de votre bureau.",
      },
      {
        rating: 4.8,
        text: "Conception élégante Le climatiseur au sol est une solution idéale pour les intérieurs modernes de votre maison ou de votre bureau.",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "72",
    productName: "STANDING UNITS 100 000 BTU INVERTER",
    imgUrl: productImg72,
    category: "LG climatiseur",
    price: 10000,
    slider: SliderSplitLg,
    shortDesc:
      "Flux d'air puissant Avec une vitesse et un volume d'air puissants, le flux d'air peut atteindre jusqu'à 20 mètres. Cela permet un refroidissement frais et confortable beaucoup plus tôt.",
    description:
      "Réponse rapide L'unité au sol peut atteindre une température définie dans un laps de temps plus court tout en fournissant un refroidissement puissant. Pendant ce temps, la fonction Power Heating offre l’angle de flux d’air optimal, garantissant une performance de chauffage plus rapide.",
    reviews: [
      {
        rating: 4.6,
        text: "Conception élégante Le climatiseur au sol est une solution idéale pour les intérieurs modernes de votre maison ou de votre bureau.",
      },
      {
        rating: 4.9,
        text: "Conception élégante Le climatiseur au sol est une solution idéale pour les intérieurs modernes de votre maison ou de votre bureau.",
      },
    ],
    avgRating: 4.7,
  },
    // lg tv
  {
    id: "73",
    productName: "32'' 32LP500 HD TV",
    imgUrl: productImg73,
    category: "LG tv",
    price: 150,
    slider:"https://www.lg.com/africa/images/banners/hero-banner.jpg",
    shortDesc:
      " HD 1920X1080 (32” HD)- Color Master Engine, USB Movie- Slim, 2HDMI, 1USB- 2.0Ch 6W, Dolby Audio, Virtual Surround Plus- Music Player, Quick Access",
    description:
      "HD 1920X1080 (32” HD)- Color Master Engine, USB Movie- Slim, 2HDMI, 1USB- 2.0Ch 6W, Dolby Audio, Virtual Surround Plus- Music Player, Quick Access",
    reviews: [
      {
        rating: 4.7,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "74",
    productName: "32'' 32LM637 SMART TV",
    imgUrl: productImg74,
    category: "LG tv",
    price: 225,
    slider: "https://www.lg.com/africa/images/banners/hero-banner.jpg",
    shortDesc:
      " Full HD 1920X1080- USB 2.0,  Slim, HDR10- 2HDMI, 1USB- Browsing the web, Timeshift function- Updatable firmware",
    description:
      "Full HD 1920X1080- USB 2.0,  Slim, HDR10- 2HDMI, 1USB- Browsing the web, Timeshift function- Updatable firmware",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "75",
    productName: "43'' 43LP5000 FULL HD TV",
    imgUrl: productImg75,
    category: "LG tv",
    price: 290,
    slider: "https://www.lg.com/africa/images/banners/hero-banner.jpg",
    shortDesc:
      "Perfect Color, Pixel Dimming- Perfect Viewing Angle- Blade Slim- 2.0Ch, 20W, Smart Sound Mode, Dolby Atmos- 3HDMI, 3USB- webOS 2.0, Magic Remote",
    description:
      "Perfect Color, Pixel Dimming- Perfect Viewing Angle- Blade Slim- 2.0Ch, 20W, Smart Sound Mode, Dolby Atmos- 3HDMI, 3USB- webOS 2.0, Magic Remote",
    reviews: [
      {
        rating: 4.6,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.9,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "76",
    productName: "43'' 43LM6370 FHD SMART TV",
    imgUrl: productImg76,
    category: "LG tv",
    price: 350,
    slider: "https://www.lg.com/africa/images/banners/hero-banner.jpg",
    shortDesc:
      " Full HD 1920X1080- USB 2.0,  Slim, HDR10- 2HDMI, 1USB- Browsing the web, Timeshift function- Updatable firmware",
    description:
      "Full HD 1920X1080- USB 2.0,  Slim, HDR10- 2HDMI, 1USB- Browsing the web, Timeshift function- Updatable firmware",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "77",
    productName: "43'' 43UQ80006LD UD TV",
    imgUrl: productImg77,
    category: "LG tv",
    price: 475,
    slider: "https://www.lg.com/africa_fr/images/TV/features/QNED2023/tv-qned-22-ai-concierge-desktop.jpg",
    shortDesc:
      " Real 4K UHD picture, colour and depth- ThinQ AI webOS Smart TV with Magic Remote- True Cinema Experience supported with Active HDR-  Film Maker Mode , AI Sound- Unlimited Gameplay with HGiG , ALLM , HDMI 2.0 ",
    description:
      "Real 4K UHD picture, colour and depth- ThinQ AI webOS Smart TV with Magic Remote- True Cinema Experience supported with Active HDR-  Film Maker Mode , AI Sound- Unlimited Gameplay with HGiG , ALLM , HDMI 2.0",
    reviews: [
      {
        rating: 4.7,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "78",
    productName: "48'' OLED 48C26LA SMART TV",
    imgUrl: productImg78,
    category: "LG tv",
    price: 1800,
    slider: "https://www.lg.com/africa/images/banners/hero-banner.jpg",
    shortDesc:
      " Self-lighting OLED: Perfect Black, - Intense Color, Infinite Contrast α9 Gen 3 AI - Processor 4K with AI Picture Pro/Sound Pro- webOS Smart TV with Pointing and Wheel - Control Magic Remote",
    description:
      "Self-lighting OLED: Perfect Black, - Intense Color, Infinite Contrast α9 Gen 3 AI - Processor 4K with AI Picture Pro/Sound Pro- webOS Smart TV with Pointing and Wheel - Control Magic Remote",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "791",
    productName: "50'' 55UR73006 SMART UHD TV",
    imgUrl: productImg791,
    category: "LG tv",
    price: 550,
    slider: "https://www.lg.com/africa_fr/images/TV/features/QNED2023/tv-qned-22-ai-concierge-desktop.jpg",
    shortDesc:
      " Quad Core Processor 4K- FILMMAKER MODE™- Ultra Surround Sound- HDR 10 Pro & HLG Pro- Sport Alert- AI ThinQ™",
    description:
      "Quad Core Processor 4K- FILMMAKER MODE™- Ultra Surround Sound- HDR 10 Pro & HLG Pro- Sport Alert- AI ThinQ™",
    reviews: [
      {
        rating: 4.6,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.9,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "792",
    productName: "55'' 55UR73006 SMART UHD TV",
    imgUrl: productImg792,
    category: "LG tv",
    price: 650,
    slider: "https://www.lg.com/africa_fr/images/TV/features/QNED2023/tv-qned-22-ai-concierge-desktop.jpg",
    shortDesc:
      " Quad Core Processor 4K- FILMMAKER MODE™- Ultra Surround Sound- HDR 10 Pro & HLG Pro- Sport Alert- AI ThinQ™",
    description:
      "Quad Core Processor 4K- FILMMAKER MODE™- Ultra Surround Sound- HDR 10 Pro & HLG Pro- Sport Alert- AI ThinQ™",
    reviews: [
      {
        rating: 4.6,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.9,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "79",
    productName: "55'' 55UR91006LB",
    imgUrl: productImg79,
    category: "LG tv",
    price: 700,
    slider: "https://www.lg.com/africa_fr/images/TV/features/QNED2023/tv-qned-22-ai-concierge-desktop.jpg",
    shortDesc:
      " Quad Core Processor 4K- FILMMAKER MODE™- Ultra Surround Sound- HDR 10 Pro & HLG Pro- Sport Alert- AI ThinQ™",
    description:
      "Quad Core Processor 4K- FILMMAKER MODE™- Ultra Surround Sound- HDR 10 Pro & HLG Pro- Sport Alert- AI ThinQ™",
    reviews: [
      {
        rating: 4.6,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.9,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },
  
  {
    id: "80",
    productName: "55 INCH SMART HOTEL TV 55US660H",
    imgUrl: productImg80,
    category: "LG tv",
    price: 900,
    slider: "https://www.lg.com/africa_fr/images/TV/features/QNED2023/tv-qned-22-ai-concierge-desktop.jpg",
    shortDesc:
      " High-Resolution for a razor sharp image- Consistent images at any angle- The core of authentic image- 4K Active HDR for a lifelike scene- Multi-channel bringing encapsulating sound effect- Sophisticated inside and out ",
    description:
      "High-Resolution for a razor sharp image- Consistent images at any angle- The core of authentic image- 4K Active HDR for a lifelike scene- Multi-channel bringing encapsulating sound effect- Sophisticated inside and out",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "801",
    productName: "65'' 65UR73006 SMART UHD TV",
    imgUrl: productImg801,
    category: "LG tv",
    price: '',
    slider: "https://www.lg.com/africa_fr/images/TV/features/QNED2023/tv-qned-22-ai-concierge-desktop.jpg",
    shortDesc:
      " High-Resolution for a razor sharp image- Consistent images at any angle- The core of authentic image- 4K Active HDR for a lifelike scene- Multi-channel bringing encapsulating sound effect- Sophisticated inside and out ",
    description:
      "High-Resolution for a razor sharp image- Consistent images at any angle- The core of authentic image- 4K Active HDR for a lifelike scene- Multi-channel bringing encapsulating sound effect- Sophisticated inside and out",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "81",
    productName: "65'' 65QNED816RA QNED SMART TV",
    imgUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSA8vw1pt-Mndle6bufLTwPgrHf95pKmnp1Bw&s",
    category: "LG tv",
    price: 1750,
    slider: "https://www.lg.com/africa_fr/images/TV/features/QNED2023/tv-qned-02-core-technology-85-desktop.jpg",
    shortDesc:
      " High-Resolution for a razor sharp image- Consistent images at any angle- The core of authentic image- 4K Active HDR for a lifelike scene- Multi-channel bringing encapsulating sound effect- Sophisticated inside and out ",
    description:
      "High-Resolution for a razor sharp image- Consistent images at any angle- The core of authentic image- 4K Active HDR for a lifelike scene- Multi-channel bringing encapsulating sound effect- Sophisticated inside and out",
    reviews: [
      {
        rating: 4.7,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.5,
  },

  
    {
    id: "82",
    productName: "55'' NANO 80 TV",
    imgUrl: "https://www.lg.com/africa_fr/images/television/md07597850/gallery/N02_D-02.jpg",
    category: "LG tv",
    price: 900,
    slider: "https://www.lg.com/africa_fr/images/TV/features/NANO2024/nanocell-nano80-01-intro-visual-d.jpg",
    shortDesc:
      "Un monde de couleurs véritables grâce à la technologie NanoCell Unequalité d'image et de son extraordinaire grâce au processeur alpha 5 AI 4K de 7e génération 4 ns de mises à jour garantis sur 5 ans avec webOS Re:New Program. Desimages et un son dignes du cinéma avec FILMMAKER MODE et HDR10 Pro Amliorez l'expérience de jeu grâce à Game Dashboard et Optimizer",
    description:
      "Un monde de couleurs véritables grâce à la technologie NanoCell Unequalité d'image et de son extraordinaire grâce au processeur alpha 5 AI 4K de 7e génération 4 ns de mises à jour garantis sur 5 ans avec webOS Re:New Program. Desimages et un son dignes du cinéma avec FILMMAKER MODE et HDR10 Pro Amliorez l'expérience de jeu grâce à Game Dashboard et Optimizer",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "83",
    productName: "75'' Nano 80 TV",
    imgUrl: "https://www.lg.com/africa_fr/images/television/md07597850/gallery/N02_D-02.jpg",
    category: "LG tv",
    price: 2200,
    slider: "https://www.lg.com/africa_fr/images/TV/features/NANO2024/nanocell-nano80-01-intro-visual-d.jpg",
    shortDesc:
      "Un monde de couleurs véritables grâce à la technologie NanoCell Unequalité d'image et de son extraordinaire grâce au processeur alpha 5 AI 4K de 7e génération 4 ns de mises à jour garantis sur 5 ans avec webOS Re:New Program. Desimages et un son dignes du cinéma avec FILMMAKER MODE et HDR10 Pro Amliorez l'expérience de jeu grâce à Game Dashboard et Optimizer",
    description:
      "Un monde de couleurs véritables grâce à la technologie NanoCell Unequalité d'image et de son extraordinaire grâce au processeur alpha 5 AI 4K de 7e génération 4 ns de mises à jour garantis sur 5 ans avec webOS Re:New Program. Desimages et un son dignes du cinéma avec FILMMAKER MODE et HDR10 Pro Amliorez l'expérience de jeu grâce à Game Dashboard et Optimizer",
    reviews: [
      {
        rating: 4.6,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.9,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

   {
    id: "8311",
    productName: "86'' Nano 80 TV",
    imgUrl: "https://www.lg.com/africa_fr/images/television/md07597850/gallery/N02_D-02.jpg",
    category: "LG tv",
    price: 3250,
    slider: "https://www.lg.com/africa_fr/images/TV/features/NANO2024/nanocell-nano80-01-intro-visual-d.jpg",
    shortDesc:
      "Un monde de couleurs véritables grâce à la technologie NanoCell Unequalité d'image et de son extraordinaire grâce au processeur alpha 5 AI 4K de 7e génération 4 ns de mises à jour garantis sur 5 ans avec webOS Re:New Program. Desimages et un son dignes du cinéma avec FILMMAKER MODE et HDR10 Pro Amliorez l'expérience de jeu grâce à Game Dashboard et Optimizer",
    description:
      "Un monde de couleurs véritables grâce à la technologie NanoCell Unequalité d'image et de son extraordinaire grâce au processeur alpha 5 AI 4K de 7e génération 4 ns de mises à jour garantis sur 5 ans avec webOS Re:New Program. Desimages et un son dignes du cinéma avec FILMMAKER MODE et HDR10 Pro Amliorez l'expérience de jeu grâce à Game Dashboard et Optimizer",
    reviews: [
      {
        rating: 4.6,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.9,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "841",
    productName: "65'' 65QNED816RA QNED SMART TV",
    imgUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSA8vw1pt-Mndle6bufLTwPgrHf95pKmnp1Bw&s",
    category: "LG tv",
    price: 1750,
    slider: "https://www.lg.com/africa_fr/images/TV/features/QNED2023/tv-qned-02-core-technology-85-desktop.jpg",
    shortDesc:
      "Technologie Quantum Dot NanoCell pour des couleurs plus riches et plus précises en 4K Processeur α7 Gen5 AI 4K, image et son exceptionnels Améliore le rapport de contraste avec Precision Dimming Pro Cinéma et sport d'exception avec Dolby Vision IQ et Dolby Atmos LG AI ThinQ et webOS Smart TV avec Magic Remote",
    description:
      "Technologie Quantum Dot NanoCell pour des couleurs plus riches et plus précises en 4K Processeur α7 Gen5 AI 4K, image et son exceptionnels Améliore le rapport de contraste avec Precision Dimming Pro Cinéma et sport d'exception avec Dolby Vision IQ et Dolby Atmos LG AI ThinQ et webOS Smart TV avec Magic Remote",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  }, 
  
  {
    id: "84",
    productName: "65'' 65QNED916QA UD SMART TV",
    imgUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSA8vw1pt-Mndle6bufLTwPgrHf95pKmnp1Bw&s",
    category: "LG tv",
    price: 1750,
    slider: "https://www.lg.com/africa_fr/images/TV/features/QNED2023/tv-qned-02-core-technology-85-desktop.jpg",
    shortDesc:
      "Technologie Quantum Dot NanoCell pour des couleurs plus riches et plus précises en 4K Processeur α7 Gen5 AI 4K, image et son exceptionnels Améliore le rapport de contraste avec Precision Dimming Pro Cinéma et sport d'exception avec Dolby Vision IQ et Dolby Atmos LG AI ThinQ et webOS Smart TV avec Magic Remote",
    description:
      "Technologie Quantum Dot NanoCell pour des couleurs plus riches et plus précises en 4K Processeur α7 Gen5 AI 4K, image et son exceptionnels Améliore le rapport de contraste avec Precision Dimming Pro Cinéma et sport d'exception avec Dolby Vision IQ et Dolby Atmos LG AI ThinQ et webOS Smart TV avec Magic Remote",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "85",
    productName: "55'' OLED55C1 SMART TVS",
    imgUrl: productImg85,
    category: "LG tv",
    price: 2250,
    slider: "https://www.lg.com/africa/images/banners/hero-banner.jpg",
    shortDesc:
      "Image et fonctionnalités avancées avec le processeur α9 AI 4K Gen6 Visuels lumineux et audacieux de Brightness Booster Design ultra fin avec des cadres à peine visibles Fonctionnalités intelligentes, notamment ThinQ AI, WebOS et reconnaissance vocale mains libres Divertissement immersif avec Dolby Atmos & Vision, VRR, G-sync et Freesync",
    description:
      "Image et fonctionnalités avancées avec le processeur α9 AI 4K Gen6 Visuels lumineux et audacieux de Brightness Booster Design ultra fin avec des cadres à peine visibles Fonctionnalités intelligentes, notamment ThinQ AI, WebOS et reconnaissance vocale mains libres Divertissement immersif avec Dolby Atmos & Vision, VRR, G-sync et Freesync",
    reviews: [
      {
        rating: 4.7,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "86",
    productName: "OLED 55CXPVA SMART UD TV",
    imgUrl: productImg86,
    category: "LG tv",
    price: '',
    slider: "https://www.lg.com/africa/LG.com_HeroBanner_LG%20OLED_LineUp%201600x800px_%20Desktop%20.jpg",
    shortDesc:
      " Self-lighting OLED: Perfect Black, - Intense Color, Infinite Contrast α9 Gen 3 AI - Processor 4K with AI Picture Pro/Sound Pro- webOS Smart TV with Pointing and Wheel - Control Magic Remote",
    description:
      " Self-lighting OLED: Perfect Black, - Intense Color, Infinite Contrast α9 Gen 3 AI - Processor 4K with AI Picture Pro/Sound Pro- webOS Smart TV with Pointing and Wheel - Control Magic Remote",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "87",
    productName: "OLED55CS6LA OLED 4K SMART TV",
    imgUrl: productImg87,
    category: "LG tv",
    price: '',
    slider: "https://www.lg.com/africa_fr/images/TV/features/QNED2023/tv-qned-26-cinema-desktop.jpg",
    shortDesc:
      "LG 4K SELF-LIT OLED – une véritable brillance dans la clarté du contenu Couleur parfaite (fidélité des couleurs à 100 %) Écran de confort oculaire Processeur IA α9 Gen5 Téléviseur intelligent ThinQ AI webOS avec télécommande magique NFC Véritable expérience cinéma prise en charge avec Dolby Vision IQ | Dolby Atmos Gameplay illimité avec GSYNC, AMD FreeSync Premium, LG VRR, HGiG HDMI 2.1, Game Optimiser",
    description:
      "LG 4K SELF-LIT OLED – une véritable brillance dans la clarté du contenu Couleur parfaite (fidélité des couleurs à 100 %) Écran de confort oculaire Processeur IA α9 Gen5 Téléviseur intelligent ThinQ AI webOS avec télécommande magique NFC Véritable expérience cinéma prise en charge avec Dolby Vision IQ | Dolby Atmos Gameplay illimité avec GSYNC, AMD FreeSync Premium, LG VRR, HGiG HDMI 2.1, Game Optimiser",
    reviews: [
      {
        rating: 4.6,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.9,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "88",
    productName: "65'' 65UR91006LB",
    imgUrl: productImg88,
    category: "LG tv",
    price: '',
    slider: "https://www.lg.com/africa/images/banners/hero-banner.jpg",
    shortDesc:
      "Image, couleur et profondeur réelles 4K UHD Processeur α5 AI Gen6 avec mise à l'échelle AI 4K Téléviseur intelligent ThinQ AI webOS avec télécommande magique Véritable expérience cinématographique prise en charge avec Active HDR, mode Film Maker et AI Sound Gameplay illimité avec HGiG, ALLM, HDMI 2.0 eARC, Game Optimizer",
    description:
      "Image, couleur et profondeur réelles 4K UHD Processeur α5 AI Gen6 avec mise à l'échelle AI 4K Téléviseur intelligent ThinQ AI webOS avec télécommande magique Véritable expérience cinématographique prise en charge avec Active HDR, mode Film Maker et AI Sound Gameplay illimité avec HGiG, ALLM, HDMI 2.0 eARC, Game Optimizer",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "891",
    productName: "65'' OLED65C36 SMART TVS",
    imgUrl: productImg891,
    category: "LG tv",
    price: '',
    slider: "https://www.lg.com/africa/LG.com_HeroBanner_LG%20OLED_LineUp%201600x800px_%20Desktop%20.jpg",
    shortDesc:
      "LG 4K SELF-LIT OLED – une véritable brillance dans la clarté du contenu Couleur parfaite (fidélité des couleurs à 100 %) Écran de confort oculaire Processeur α8 AI 4K avec AI Super Upscaling 4K et assistant d'image personnalisé Conception ultra fine pour une immersion et des intérieurs élevés 4 ans de mises à jour garanties sur 5 ans avec webOSRe:NewProgram Des films et des jeux incroyables améliorés par Dolby Atmos et Dolby Vision, VRR, G-Sync et Freesync",
    description:
      "LG 4K SELF-LIT OLED – une véritable brillance dans la clarté du contenu Couleur parfaite (fidélité des couleurs à 100 %) Écran de confort oculaire Processeur α8 AI 4K avec AI Super Upscaling 4K et assistant d'image personnalisé Conception ultra fine pour une immersion et des intérieurs élevés 4 ans de mises à jour garanties sur 5 ans avec webOSRe:NewProgram Des films et des jeux incroyables améliorés par Dolby Atmos et Dolby Vision, VRR, G-Sync et Freesync",
    reviews: [
      {
        rating: 4.7,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "89",
    productName: "65'' OLED65G1 SMART TV PREMIUM OLED TV",
    imgUrl: productImg89,
    category: "LG tv",
    price: '',
    slider: "https://www.lg.com/africa/LG.com_HeroBanner_LG%20OLED_LineUp%201600x800px_%20Desktop%20.jpg",
    shortDesc:
      "LG 4K SELF-LIT OLED – une véritable brillance dans la clarté du contenu Couleur parfaite (fidélité des couleurs à 100 %) Écran de confort oculaire Processeur α8 AI 4K avec AI Super Upscaling 4K et assistant d'image personnalisé Conception ultra fine pour une immersion et des intérieurs élevés 4 ans de mises à jour garanties sur 5 ans avec webOSRe:NewProgram Des films et des jeux incroyables améliorés par Dolby Atmos et Dolby Vision, VRR, G-Sync et Freesync",
    description:
      "LG 4K SELF-LIT OLED – une véritable brillance dans la clarté du contenu Couleur parfaite (fidélité des couleurs à 100 %) Écran de confort oculaire Processeur α8 AI 4K avec AI Super Upscaling 4K et assistant d'image personnalisé Conception ultra fine pour une immersion et des intérieurs élevés 4 ans de mises à jour garanties sur 5 ans avec webOSRe:NewProgram Des films et des jeux incroyables améliorés par Dolby Atmos et Dolby Vision, VRR, G-Sync et Freesync",
    reviews: [
      {
        rating: 4.7,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "901",
    productName: "70'' 70UR8006 UD TV",
    imgUrl: productImg901,
    category: "LG tv",
    price: 1300,
    slider: "https://www.lg.com/africa/images/banners/hero-banner.jpg",
    shortDesc:
      "Visualisation 4K Ultra HD éblouissante avec une qualité d'image éclatante. Processeur Quad Core rapide 4K HDR pour un cinéma, un sport et des jeux impressionnants Smart TV webOS avec pointage et commande par molette Magic Remote",
    description:
      "Visualisation 4K Ultra HD éblouissante avec une qualité d'image éclatante. Processeur Quad Core rapide 4K HDR pour un cinéma, un sport et des jeux impressionnants Smart TV webOS avec pointage et commande par molette Magic Remote",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "90",
    productName: "70'' 70UP7550PVC 4K SMART TV",
    imgUrl: productImg90,
    category: "LG tv",
    price: '',
    slider: "https://www.lg.com/africa/images/banners/hero-banner.jpg",
    shortDesc:
      "Visualisation 4K Ultra HD éblouissante avec une qualité d'image éclatante. Processeur Quad Core rapide 4K HDR pour un cinéma, un sport et des jeux impressionnants Smart TV webOS avec pointage et commande par molette Magic Remote",
    description:
      "Visualisation 4K Ultra HD éblouissante avec une qualité d'image éclatante. Processeur Quad Core rapide 4K HDR pour un cinéma, un sport et des jeux impressionnants Smart TV webOS avec pointage et commande par molette Magic Remote",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "91",
    productName: "70'' 70UR80006 UHD SMART TV",
    imgUrl: productImg91,
    category: "LG tv",
    price: 1300,
    slider: SliderTvLg,
    shortDesc:
      "Processeur α5 Gen5 AI] pour une expérience visuelle améliorée Conception mince Du vrai 4K Véritable expérience cinématographique prise en charge avec Active HDR, mode Film Maker, AI Sound Gameplay illimité avec HGiG, ALLM, HDMI 2.0 eARC",
    description:
      "Processeur α5 Gen5 AI] pour une expérience visuelle améliorée Conception mince Du vrai 4K Véritable expérience cinématographique prise en charge avec Active HDR, mode Film Maker, AI Sound Gameplay illimité avec HGiG, ALLM, HDMI 2.0 eARC",
    reviews: [
      {
        rating: 4.6,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.9,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "921",
    productName: " OLED 77'' 77C36LA SMART 4K",
    imgUrl: productImg92,
    category: "LG tv",
    price: 8000,
    slider: "https://www.lg.com/africa/LG.com_HeroBanner_LG%20OLED_LineUp%201600x800px_%20Desktop%20.jpg",
    shortDesc:
      "Un son et une image plus clairs avec des améliorations grâce au processeur alpha 8 AI 4K. Contraste infini pour des visuels nets avec des noirs foncés et des blancs éclatants. 4 ans de mises à niveau garantis sur 5 ans avec webOS Re:New Program. Dolby Atmos pour un son immersif et des graphismes incroyablement réalistes de Dolby Vision. Jeu fluide et sans bégaiement avec VRR, G-sync et Freesync.",
    description:
      "Un son et une image plus clairs avec des améliorations grâce au processeur alpha 8 AI 4K. Contraste infini pour des visuels nets avec des noirs foncés et des blancs éclatants. 4 ans de mises à niveau garantis sur 5 ans avec webOS Re:New Program. Dolby Atmos pour un son immersif et des graphismes incroyablement réalistes de Dolby Vision. Jeu fluide et sans bégaiement avec VRR, G-sync et Freesync.",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "92",
    productName: "77'' OLED77G2 SMART PREMIUM OLED TV",
    imgUrl: productImg92,
    category: "LG tv",
    price: '',
    slider: "https://www.lg.com/africa/LG.com_HeroBanner_LG%20OLED_LineUp%201600x800px_%20Desktop%20.jpg",
    shortDesc:
      "Un son et une image plus clairs avec des améliorations grâce au processeur alpha 8 AI 4K. Contraste infini pour des visuels nets avec des noirs foncés et des blancs éclatants. 4 ans de mises à niveau garantis sur 5 ans avec webOS Re:New Program. Dolby Atmos pour un son immersif et des graphismes incroyablement réalistes de Dolby Vision. Jeu fluide et sans bégaiement avec VRR, G-sync et Freesync.",
    description:
      "Un son et une image plus clairs avec des améliorations grâce au processeur alpha 8 AI 4K. Contraste infini pour des visuels nets avec des noirs foncés et des blancs éclatants. 4 ans de mises à niveau garantis sur 5 ans avec webOS Re:New Program. Dolby Atmos pour un son immersif et des graphismes incroyablement réalistes de Dolby Vision. Jeu fluide et sans bégaiement avec VRR, G-sync et Freesync.",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  //ajout tv 5 fois

  {
    id: "209",
    productName: "75'' 75UQ90006LC UD TV",
    imgUrl: productImg209,
    category: "LG tv",
    price: '',
    slider: "https://www.lg.com/africa/images/banners/hero-banner.jpg",
    shortDesc:
      "Real 4K UHD picture, colour and depth- α5 Gen5 AI Processor 4k with AI 4K upscaling ,- AI Brightness Control - True Cinema Experience supported with Active HDR , Filmmaker Mode , AI Sound- Unlimited Gameplay with HGiG , ALLM , HDMI 2.0 eARC- Game Optimizer - Sport alert- ThinQ AI webOS Smart TV with Magic Remote",
    description:
      "Real 4K UHD picture, colour and depth- α5 Gen5 AI Processor 4k with AI 4K upscaling ,- AI Brightness Control - True Cinema Experience supported with Active HDR , Filmmaker Mode , AI Sound- Unlimited Gameplay with HGiG , ALLM , HDMI 2.0 eARC- Game Optimizer - Sport alert- ThinQ AI webOS Smart TV with Magic Remote",
    reviews: [
      {
        rating: 4.6,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.9,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "2101",
    productName: "75''75QNED816RA QNED SMART TV",
    imgUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSA8vw1pt-Mndle6bufLTwPgrHf95pKmnp1Bw&s",
    category: "LG tv",
    price: 2300,
    slider: "https://www.lg.com/africa_fr/images/TV/features/QNED2023/tv-qned-08-dimming-85-desktop.jpg",
    shortDesc:
      "[Technologie Quantum Dot et NanoCell] pour des couleurs plus vives et plus riches[Mini LED] pour un meilleur rapport de contraste[Disponible en 5 tailles] 86, 75, 65, 55, 50[Processeur IA α7 de cinquième génération] pour une meilleure expérience visuelle[ThinQ AI et WebOS] pour des paramètres personnalisés[Services de streaming OTT] plusieurs plateformes de streaming sont disponibles directement sur les téléviseurs QNED",
    description:
      "[Technologie Quantum Dot et NanoCell] pour des couleurs plus vives et plus riches[Mini LED] pour un meilleur rapport de contraste[Disponible en 5 tailles] 86, 75, 65, 55, 50[Processeur IA α7 de cinquième génération] pour une meilleure expérience visuelle[ThinQ AI et WebOS] pour des paramètres personnalisés[Services de streaming OTT] plusieurs plateformes de streaming sont disponibles directement sur les téléviseurs QNED",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "210",
    productName: "75'' 75UR8006 UD TV (PROMOTION)",
    imgUrl: productImg210,
    category: "LG tv",
    price: 1500,
    slider: "https://www.lg.com/africa/images/banners/hero-banner.jpg",
    shortDesc:
      "Couleurs vives et détails remarquables avec 4K HDR10 Pro Une expérience visuelle plus intelligente avec le processeur α5 AI 4K Gen6 Fonctionnalités intelligentes, notamment ThinQ AI et WebOS Streaming intégré, y compris les chaînes LG, Netflix, Apple TV+ et Disney+",
    description:
      "Couleurs vives et détails remarquables avec 4K HDR10 Pro Une expérience visuelle plus intelligente avec le processeur α5 AI 4K Gen6 Fonctionnalités intelligentes, notamment ThinQ AI et WebOS Streaming intégré, y compris les chaînes LG, Netflix, Apple TV+ et Disney+",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "2102",
    productName: "82'' 82UP8050PVB UHD TV",
    imgUrl: productImg2102,
    category: "LG tv",
    price: 1600,
    slider: "https://www.lg.com/africa_fr/images/TV/features/QNED2023/tv-qned-22-ai-concierge-desktop.jpg",
    shortDesc:
      "Couleurs vives et détails remarquables avec 4K HDR10 Pro Une expérience visuelle plus intelligente avec le processeur α5 AI 4K Gen6 Fonctionnalités intelligentes, notamment ThinQ AI et WebOS Streaming intégré, y compris les chaînes LG, Netflix, Apple TV+ et Disney+",
    description:
      "Couleurs vives et détails remarquables avec 4K HDR10 Pro Une expérience visuelle plus intelligente avec le processeur α5 AI 4K Gen6 Fonctionnalités intelligentes, notamment ThinQ AI et WebOS Streaming intégré, y compris les chaînes LG, Netflix, Apple TV+ et Disney+",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "2103",
    productName: "83'' OLED83C36LA SMART UD OLED TV",
    imgUrl: productImg2103,
    category: "LG tv",
    price: '',
    slider: "https://www.lg.com/africa/LG.com_HeroBanner_LG%20OLED_LineUp%201600x800px_%20Desktop%20.jpg",
    shortDesc:
      "Couleurs vives et détails remarquables avec 4K HDR10 Pro Une expérience visuelle plus intelligente avec le processeur α5 AI 4K Gen6 Fonctionnalités intelligentes, notamment ThinQ AI et WebOS Streaming intégré, y compris les chaînes LG, Netflix, Apple TV+ et Disney+",
    description:
      "Couleurs vives et détails remarquables avec 4K HDR10 Pro Une expérience visuelle plus intelligente avec le processeur α5 AI 4K Gen6 Fonctionnalités intelligentes, notamment ThinQ AI et WebOS Streaming intégré, y compris les chaînes LG, Netflix, Apple TV+ et Disney+",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "2104",
    productName: "86'' 86QNED816RA 4K SMART TV",
    imgUrl: "https://www.lg.com/africa_fr/images/television/md07560861/gallery/DZ01-1.jpg",
    category: "LG tv",
    price: '',
    slider: "https://www.lg.com/africa_fr/images/TV/features/QNED2023/tv-qned-08-dimming-85-desktop.jpg",
    shortDesc:
      "Couleurs vives et détails remarquables avec 4K HDR10 Pro Une expérience visuelle plus intelligente avec le processeur α5 AI 4K Gen6 Fonctionnalités intelligentes, notamment ThinQ AI et WebOS Streaming intégré, y compris les chaînes LG, Netflix, Apple TV+ et Disney+",
    description:
      "Couleurs vives et détails remarquables avec 4K HDR10 Pro Une expérience visuelle plus intelligente avec le processeur α5 AI 4K Gen6 Fonctionnalités intelligentes, notamment ThinQ AI et WebOS Streaming intégré, y compris les chaînes LG, Netflix, Apple TV+ et Disney+",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "211",
    productName: "86QNED916VPA 4K SMART TV",
    imgUrl: "https://www.lg.com/africa_fr/images/television/md07560861/gallery/DZ01-1.jpg",
    category: "LG tv",
    price: 5500,
    slider: "https://www.lg.com/africa_fr/images/TV/features/QNED2023/tv-qned-08-dimming-85-desktop.jpg",
    shortDesc:
      "[Technologie Quantum Dot et NanoCell] pour des couleurs plus vives et plus riches[Mini LED] pour un meilleur rapport de contraste[Processeur IA α7 de cinquième génération] pour une meilleure expérience visuelle[ThinQ AI et WebOS] pour des paramètres personnalisés[Services de streaming OTT] plusieurs plateformes de streaming sont disponibles directement sur les téléviseurs QNED",
    description:
      " [Technologie Quantum Dot et NanoCell] pour des couleurs plus vives et plus riches[Mini LED] pour un meilleur rapport de contraste[Processeur IA α7 de cinquième génération] pour une meilleure expérience visuelle[ThinQ AI et WebOS] pour des paramètres personnalisés[Services de streaming OTT] plusieurs plateformes de streaming sont disponibles directement sur les téléviseurs QNED",
    reviews: [
      {
        rating: 4.6,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.9,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "212",
    productName: "86'' 86UR78006LC UD TV",
    imgUrl: productImg212,
    category: "LG tv",
    price: 2750,
    slider: "https://www.lg.com/africa_fr/images/TV/features/QNED2023/tv-qned-22-ai-concierge-desktop.jpg",
    shortDesc:
      " Real 4K UHD picture, color and depth Details- α7 AI Processor Gen6 with AI 4K upscaling- ThinQ AI webOS Smart TV with Magic Remote- True Cinema Experience supported with Active 4K HDR10,HLG,Filmmaker Mode Advanced Dynamic Tone Mapping- Unlimited Gameplay with LG VRR 60Hz , HGiG , ALLM , HDMI 2.0 , Game Optimizer",
    description:
      " Real 4K UHD picture, color and depth Details- α7 AI Processor Gen6 with AI 4K upscaling- ThinQ AI webOS Smart TV with Magic Remote- True Cinema Experience supported with Active 4K HDR10,HLG,Filmmaker Mode Advanced Dynamic Tone Mapping- Unlimited Gameplay with LG VRR 60Hz , HGiG , ALLM , HDMI 2.0 , Game Optimizer",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "213",
    productName: "86'' 86UQ90006LC UD TV",
    imgUrl: productImg213,
    category: "LG tv",
    price: 2750,
    slider: "https://www.lg.com/africa_fr/images/TV/features/QNED2023/tv-qned-22-ai-concierge-desktop.jpg",
    shortDesc:
      "Real 4K UHD picture, color and depth Details- α7 AI Processor Gen6 with AI 4K upscaling- ThinQ AI webOS Smart TV with Magic Remote- True Cinema Experience supported with Active 4K HDR10,HLG,Filmmaker Mode Advanced Dynamic Tone Mapping- Unlimited Gameplay with LG VRR 60Hz , HGiG , ALLM , HDMI 2.0 , Game Optimizer",
    description:
      "Real 4K UHD picture, color and depth Details- α7 AI Processor Gen6 with AI 4K upscaling- ThinQ AI webOS Smart TV with Magic Remote- True Cinema Experience supported with Active 4K HDR10,HLG,Filmmaker Mode Advanced Dynamic Tone Mapping- Unlimited Gameplay with LG VRR 60Hz , HGiG , ALLM , HDMI 2.0 , Game Optimizer",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },
    // lg machine à laver
  {
    id: "931",
    productName: "WM 810 TWIN TOP 7KG",
    imgUrl: productImg931,
    category: "LG machine à laver",
    price: '',
    slider: SliderMachineLg,
    shortDesc:
      "  Wash Capacity	7 Kg Noise	65 / 72 DB (Wash / Spin) Spin Timer	5 Min Spin Speed	1250 Rpm Net Weight	19 Kg Spin Capacity	4.6 Kg Wash Timer	15 Min Water Level	 1 Liters Dimension (W X H X D.MM)	761 X 895 X 448 Mm",
    description:
      " Wash Capacity	7 Kg Noise	65 / 72 DB (Wash / Spin) Spin Timer	5 Min Spin Speed	1250 Rpm Net Weight	19 Kg Spin Capacity	4.6 Kg Wash Timer	15 Min Water Level	 1 Liters Dimension (W X H X D.MM)	761 X 895 X 448 Mm",
    reviews: [
      {
        rating: 4.7,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "93",
    productName: "8kg 2T2TYM1S WASH",
    imgUrl: productImg93,
    category: "LG machine à laver",
    price: 700,
    slider: SliderMachineLg,
    shortDesc:
      " 8Kg Washer | AI DD | Steam™ (Allergy Care) 5.0 (2)- Write a review - AI DD™- Steam™ - Bigger Capacity- Durability - Design - ThinQ™",
    description:
      " 8Kg Washer | AI DD | Steam™ (Allergy Care) 5.0 (2)- Write a review - AI DD™- Steam™ - Bigger Capacity- Durability - Design - ThinQ™",
    reviews: [
      {
        rating: 4.7,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "94",
    productName: "WM9585 TOP LOADER T9585NDHVH",
    imgUrl: productImg94,
    category: "LG machine à laver",
    price: 450,
    slider: SliderMachineLg,
    shortDesc:
      "Contrôle intelligent de l'onduleur Mouvement intelligent TurboDrum™ Système silencieux LoDecibel™ Diagnostic intelligent™",
    description:
      "Contrôle intelligent de l'onduleur Mouvement intelligent TurboDrum™ Système silencieux LoDecibel™ Diagnostic intelligent™",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "95",
    productName: "fh2j3wDNP Front Loader with Inverter Direct Driver 6.5Kg",
    imgUrl: productImg95,
    category: "LG machine à laver",
    price: 500,
    slider: SliderMachineLg,
    shortDesc:
      "- 6,5Kg Washer | AI DD | Steam™ (Allergy Care) 5.0 (2) - Write a review - AI DD™ - Steam™ - Bigger Capacity - Durability - Design",
    description:
      "- 6,5Kg Washer | AI DD | Steam™ (Allergy Care) 5.0 (2) - Write a review - AI DD™ - Steam™ - Bigger Capacity - Durability - Design",
    reviews: [
      {
        rating: 4.6,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.9,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },
   {/**
     {
    id: "96",
    productName: "9Kg WM4RVYG6P",
    imgUrl: productImg96,
    category: "LG machine à laver",
    price: 750,
    :590,
    shortDesc:
      "- 9kg | Front Load Washer | AI DD™ - Steam Washer | ThinQ™ - 1.0 (2) - Write a review - AI DD Motor - Steam - ThinQ - Stainless Steel Lifter",
    description:
      "- 9kg | Front Load Washer | AI DD™ - Steam Washer | ThinQ™ - 1.0 (2) - Write a review - AI DD Motor - Steam - ThinQ - Stainless Steel Lifter",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  }, */},
  {
    id: "97",
    productName: "10.5Kg F4V5RYP2T",
    imgUrl: productImg97,
    category: "LG machine à laver",
    price: 1100,
    slider: SliderMachineLg,
    shortDesc:
      "- 10.5kg Silver Vivace - AI DD Front Loader Washing Machine 5.0 (2) - Write a review - AI DD™ - Steam™ - Bigger Capacity - ThinQ™",
    description:
      "- 10.5kg Silver Vivace - AI DD Front Loader Washing Machine 5.0 (2) - Write a review - AI DD™ - Steam™ - Bigger Capacity - ThinQ™",
    reviews: [
      {
        rating: 4.6,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.9,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "98",
    productName: "WASHING AND DRYER 2V5PGP2T",
    imgUrl: productImg98,
    category: "LG machine à laver",
    price: 950,
    slider: SliderMachineLg,
    shortDesc:
      "8/5kg - 7/4kg - 8kg Wash / 5kg Dry Eco Hybrid Washer Dryer Combo 3.3 (3) - Write a review - Washer and Dryer in One - 6 Motion DD - Smart Diagnosis - Add Forgotten items",
    description:
      "8/5kg - 7/4kg - 8kg Wash / 5kg Dry Eco Hybrid Washer Dryer Combo 3.3 (3) - Write a review - Washer and Dryer in One - 6 Motion DD - Smart Diagnosis - Add Forgotten items",
    reviews: [
      {
        rating: 4.7,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "981",
    productName: "WASHING MACHINE WM2Y1TYP6J 8KG",
    imgUrl: productImg981,
    category: "LG machine à laver",
    price: 550,
    slider: SliderMachineLg,
    shortDesc:
      "Allergy Care Stain Care Express 12 Rapide 60",
    description:
      "Express 12 / Rapide 60 Nettoyage rapide pour une petite machine Lorsque vous n’avez pas beaucoup de linge à laver, faites-le avec les cycles de lavage rapide LG comme Express 12 et Rapide 60.",
    reviews: [
      {
        rating: 4.7,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.5,
  },
  
  
  {
    id: "991",
    productName: "WASHING MACHINE 9KG WM4R3VYG6P",
    imgUrl: productImg991,
    category: "LG machine à laver",
    price: 650,
    slider: SliderMachineLg,
    shortDesc:
      "AI DD™ Steam™ Bigger Capacity",
    description:
      "Intelligent Care with 18% More Fabric Protection Based on big data of accumulated washing experience, AI DD™ offers the most Optimized washing motion in order to care your laundry.",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "99",
    productName: "WM4V5VGP2T WASHING MAWHINE 9KG/6KG DRYER 9/6",
    imgUrl: productImg99,
    category: "LG machine à laver",
    price: 1000,
    slider: SliderMachineLg,
    shortDesc:
      "Washing machine 9Kg/6Kg dryer AI DD™ Steam+™ Plus grande capacité ThinQ™",
    description:
      "Washing machine 9Kg/6Kg dryer AI DD™ Steam+™ Plus grande capacité ThinQ™",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {/**
    {
    id: "100",
    productName: "10Kg/7Kg DRYER 10/7",
    imgUrl: productImg100,
    category: "LG machine à laver",
    price: 1200,
    slider: SliderMachineLg,
    shortDesc:
      "- fresh & soft sensor dry - Smart Diagnosis™ - Sensor Dry - Lint Filter - Ventless Drying - Stainless Steel Drum - Smart Diagnosis",
    description:
      "- fresh & soft sensor dry - Smart Diagnosis™ - Sensor Dry - Lint Filter - Ventless Drying - Stainless Steel Drum - Smart Diagnosis",
    reviews: [
      {
        rating: 4.6,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.9,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "101",
    productName: "WMF0L9DYP2S 15Kg",
    imgUrl: productImg101,
    category: "LG machine à laver",
    price: 1800,
    slider: SliderMachineLg,
    shortDesc:
      "washing machine 15Kg Entretien intelligent des vêtements avec la technologie d'IA Réduisez les allergènes avec les cycles à vapeur Lavage exceptionnel avec la technologie 6 Motion Wash Démarrez et surveillez à distance la progression du lavage avec ThinQ® Suspendre et ajouter des articles",
    description:
      "washing machine 15Kg Entretien intelligent des vêtements avec la technologie d'IA Réduisez les allergènes avec les cycles à vapeur Lavage exceptionnel avec la technologie 6 Motion Wash Démarrez et surveillez à distance la progression du lavage avec ThinQ® Suspendre et ajouter des articles",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "102",
    productName: "DRYER 80T2SP7RM 8Kg DRY",
    imgUrl: productImg102,
    category: "LG machine à laver",
    price: 1000,
    slider: SliderMachineLg,
    shortDesc:
      "- 9kg | Front Load Washer | AI DD™ - Steam Washer | ThinQ™ - 1.0 (2) - Write a review - AI DD Motor - Steam - ThinQ - Stainless Steel Lifter",
    description:
      "- 9kg | Front Load Washer | AI DD™ - Steam Washer | ThinQ™ - 1.0 (2) - Write a review - AI DD Motor - Steam - ThinQ - Stainless Steel Lifter",
    reviews: [
      {
        rating: 4.6,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.9,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  }, */},
  
  

  {
    id: "103",
    productName: "DRYER 9KG RH90V9PV8N",
    imgUrl: productImg103,
    category: "LG machine à laver",
    price: 1200,
    slider: SliderMachineLg,
    shortDesc:
      "DRYER 9Kg Pompe Ã chaleur Sensor Dry Design élégant",
    description:
      "DRYER 9Kg Pompe Ã chaleur Sensor Dry Design élégant",
    reviews: [
      {
        rating: 4.7,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "104",
    productName: "WMF0L2CRV2T2 20/12Kg",
    imgUrl: productImg104,
    category: "LG machine à laver",
    price: 2500,
    slider: SliderMachineLg,
    shortDesc:
      "Washing machine 20Kg/12Kg TurboWash™ Steam™ 6 Motion Direct Drive Ajouter des articles",
    description:
      "Washing machine 20Kg/12Kg TurboWash™ Steam™ 6 Motion Direct Drive Ajouter des articles",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "105",
    productName: "WMF0L2CRV2T2 17Kg/10Kg",
    imgUrl: productImg105,
    category: "LG machine à laver",
    price: 2300,
    slider: SliderMachineLg,
    shortDesc:
      "Washing machine 17Kg/10kg Moteur Inverter Direct Drive True Steam TurboWash™ TurboDry 6 mouvements",
    description:
      "Washing machine 17Kg/10kg Moteur Inverter Direct Drive True Steam TurboWash™ TurboDry 6 mouvements",
    reviews: [
      {
        rating: 4.6,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.9,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "106",
    productName: "COMMERCIAL 15KG FRONT LOADER WMFHOC7D4MS",
    imgUrl: productImg106,
    category: "LG machine à laver",
    price: 3800,
    slider: "https://www.lg.com/africa/images/banners/LG.com_HeroBanner_LG%20WashTower%201600x800px_Desktop.jpg",
    shortDesc:
      "Laveuse grande capacité de 5,2 pi³ / 147ℓEntraînement direct de l'onduleurPanneau d'affichage à LEDHaute efficacité de l'eauTambour de grande capacitéCe modèle est conçu pour être utilisé avec une sécheuse empilable et ne doit pas être acheté comme laveuse autonome à moins qu'il n'existe une sécheuse compatible. Pour plus d'information, contactez nous.",
    description:
      "Laveuse grande capacité de 5,2 pi³ / 147ℓEntraînement direct de l'onduleurPanneau d'affichage à LEDHaute efficacité de l'eauTambour de grande capacitéCe modèle est conçu pour être utilisé avec une sécheuse empilable et ne doit pas être acheté comme laveuse autonome à moins qu'il n'existe une sécheuse compatible. Pour plus d'information, contactez nous.",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "107",
    productName: "COMMERCIAL 13KG DRYER DRYCDT29MSQPS",
    imgUrl: productImg107,
    category: "LG machine à laver",
    price: 3800,
    slider: "https://www.lg.com/africa/images/banners/LG.com_HeroBanner_LG%20WashTower%201600x800px_Desktop.jpg",
    shortDesc:
      "Le Giant-C+ est la meilleure solution pour votre entreprise. Économisez des coûts et de l'espace grâce à une efficacité élevée et une installation facile et gagnez de l'argent grâce à la fiabilité LG. Plus de clients visiteront votre buanderie !",
    description:
      "Le Giant-C+ est la meilleure solution pour votre entreprise. Économisez des coûts et de l'espace grâce à une efficacité élevée et une installation facile et gagnez de l'argent grâce à la fiabilité LG. Plus de clients visiteront votre buanderie !",
    reviews: [
      {
        rating: 4.6,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "1071",
    productName: "13/10kg LG WashTower™ with Centre Control™, Black Steel color",
    imgUrl: productImg1071,
    category: "LG machine à laver",
    price: 3500,
    slider: "https://www.lg.com/africa/images/banners/LG.com_HeroBanner_LG%20WashTower%201600x800px_Desktop.jpg",
    shortDesc:
      "- Smart Pairing™ - AI DD™ TurboWash™ 360 - Allergy Care - Dual Inverter HeatPump™ - Auto Cleaning Condenser",
    description:
      " Smart Pairing™ - AI DD™ TurboWash™ 360 - Allergy Care - Dual Inverter HeatPump™ - Auto Cleaning Condenser",
    reviews: [
      {
        rating: 4.6,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "108",
    productName: "clothes styler S3 MFC",
    imgUrl: productImg108,
    category: "LG machine à laver",
    price: 2750,
    slider: SliderMachineLg,
    shortDesc:
      "dryer 9kg - LG Styler® Smart wi-fi Enabled Steam Closet with TrueSteam® - Technology and Exclusive Moving Hangers - Sanitize, Deodorize & Freshen with Chemical-free TrueSteam® - Damage-Free Drying with Built-in Heat Pump",
    description:
      "dryer 9kg - LG Styler® Smart wi-fi Enabled Steam Closet with TrueSteam® - Technology and Exclusive Moving Hangers - Sanitize, Deodorize & Freshen with Chemical-free TrueSteam® - Damage-Free Drying with Built-in Heat Pump",
    reviews: [
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
      {
        rating: 4.8,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.7,
  },



  
  {
    id: "109",
    productName: "AIR PURIFIER 260M3/H",
    imgUrl: productImg109,
    category: "Hisense climatiseur",
    price: 275,
    slider: SliderAirHisense,
    shortDesc:
      "-260 m3/h - 57 dB- Anti polvere, filtro HEPA H11, ionizzatore- Indicatore di qualità dell'aria- 3 velocità + Sleep mode- Timer 1/4/8 ore",
    description:
      "-260 m3/h - 57 dB- Anti polvere, filtro HEPA H11, ionizzatore- Indicatore di qualità dell'aria- 3 velocità + Sleep mode- Timer 1/4/8 ore",
    reviews: [
      {
        rating: 4.7,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.5,
  },
  
  {
    id: "110",
    productName: "Audi AX3100G Dolby Atmos/DTS-x",
    imgUrl: productImg110,
    category: "Hisense audio",
    price: 250,
    slider: SliderAudioHisense,
    shortDesc:
      "USB/MP5/WMA- Bluetooth- Dolby Digital",
    description:
      "USB/MP5/WMA- Bluetooth- Dolby Digital",
    reviews: [
      {
        rating: 4.7,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.5,
  },
    {
    id: "113",
    productName: "Audi AX3120G",
    imgUrl: productImg110,
    category: "Hisense audio",
    price: 350,
    slider: SliderAudioHisense,
    shortDesc:
      "USB/MP5/WMA- Bluetooth- Dolby Digital",
    description:
      "USB/MP5/WMA- Bluetooth- Dolby Digital",
    reviews: [
      {
        rating: 4.7,
        text: "le produit haut de gamme",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "111",
    productName: "HS219 SOUNDBAR 320W ",
    imgUrl: productImg111,
    category: "Hisense audio",
    price: 275,
    slider: SliderAudioHisense,
    shortDesc:
      "Hisense HS219 Barre de son 2.1 canaux avec caisson de basses sans fil, 320 W, alimentée par Dolby Audio, une télécommande, Roku TV Ready, Bluetooth, HDMI ARC/Optique/AUX/USB, 3 modes EQ",
    description:
      "Profitez d'un meilleur son TV : facile à placer et à connecter à votre téléviseur, la barre de son Hisense HS219 améliore considérablement votre expérience de divertissement télévisé et est parfaite pour les téléviseurs de 32 pouces ou plus. Au lieu de simplement entendre la scène, vous y êtes transporté. Connectivité facile : avec HDMI (ARC), le Hisense HS219 peut se connecter à votre téléviseur via un seul câble HDMI (inclus), se connecter sans fil via Bluetooth et contrôler les deux avec une seule télécommande. Vous bénéficierez d'un meilleur son TV en quelques minutes.",
    reviews: [
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

 {
    id: "112",
    productName: "HS218 SOUND BAR",
    imgUrl: productImg112,
    category: "Hisense audio",
    price: 300,
    slider: SliderAudioHisense,
    shortDesc:
      "- Bluetooth - USB - HDMI ARC - Dolby Digital",
    description:
      "Barre de son Hisense HS218 2.1 canaux avec caisson de basses sans fil, 200 W, alimentée par Dolby Audio, compatible Roku TV, Bluetooth, HDMI ARC/Optique/AUX/USB, 3 modes d'égalisation, Profitez d'un meilleur son TV : la barre de son Hisense HS218 est livrée avec 4 spearkers et un caisson de basses sans fil, qui vous offre un total de 200 watts de son supérieur, ainsi que la technologie Dolby Audio et un réglage professionnel des effets sonores de niveau maître. Il améliorera instantanément le son de votre téléviseur.",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },
  //ajout-------------
  {
    id: "1112",
    productName: "HS2100 SOUND BAR",
    imgUrl: productImg111,
    category: "Hisense audio",
    price: 225,
    slider: SliderAudioHisense,
    shortDesc:
      "- Bluetooth - USB - HDMI ARC - Dolby Digital",
    description:
      "Barre de son Hisense HS218 2.1 canaux avec caisson de basses sans fil, 200 W, alimentée par Dolby Audio, compatible Roku TV, Bluetooth, HDMI ARC/Optique/AUX/USB, 3 modes d'égalisation, Profitez d'un meilleur son TV : la barre de son Hisense HS218 est livrée avec 4 spearkers et un caisson de basses sans fil, qui vous offre un total de 200 watts de son supérieur, ainsi que la technologie Dolby Audio et un réglage professionnel des effets sonores de niveau maître. Il améliorera instantanément le son de votre téléviseur.",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "1113",
    productName: "HS205 SOUND BAR",
    imgUrl: productImg1113,
    category: "Hisense audio",
    price: 150,
    slider: SliderAudioHisense,
    shortDesc:
      "- Bluetooth - USB - HDMI ARC - Dolby Digital",
    description:
      "Barre de son Hisense HS218 2.1 canaux avec caisson de basses sans fil, 200 W, alimentée par Dolby Audio, compatible Roku TV, Bluetooth, HDMI ARC/Optique/AUX/USB, 3 modes d'égalisation, Profitez d'un meilleur son TV : la barre de son Hisense HS218 est livrée avec 4 spearkers et un caisson de basses sans fil, qui vous offre un total de 200 watts de son supérieur, ainsi que la technologie Dolby Audio et un réglage professionnel des effets sonores de niveau maître. Il améliorera instantanément le son de votre téléviseur.",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },
  //------------
  {
    id: "1123",
    productName: "PARTY ROCKET ONE PLUS 300W",
    imgUrl: productImg1123,
    category: "Hisense audio",
    price: 350,
    slider: SliderAudioHisense,
    shortDesc:
      "Hisense Party Rocker One Haut-parleur Bluetooth avec puissance 300 W, woofer intégré, mode karaoké, chargeur sans fil intégré, entrée et sortie AUX, USB",
    description:
      "À propos de cet article haut-parleur Bluetooth 300w Woofer intégré Mode Karaoké Tapis de chargement sans fil intégré Entrée et sortie AUX, USB",
    reviews: [
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

 /* {
    id: "114",
    productName: "120SH 96l",
    imgUrl: productImg114,
    category: "Hisense congelateur",
    price: 225,
    shortDesc:
      "- Fast Freeze Yes - Power Indicator Function Yes - Color Silver - Lock And Key - R600 Gas",
    description:
      "- Fast Freeze Yes - Power Indicator Function Yes - Color Silver - Lock And Key - R600 Gas",
    reviews: [
      {
        rating: 4.7,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.5,
  },*/

  {
    id: "115",
    productName: "FRZ 180SH 144L ",
    imgUrl: productImg115,
    category: "Hisense congelateur",
    price: 250,
    slider: SliderCongelateurHisense,
    shortDesc:
      "144 L Fast Freezer Power Indicator Function Color: Silver Cooling Technology: Defrost Energy Rating: A Temperature Control: Mechanical Refrigerant Gas: R600A Easy Cleaning Convenient Defrost Drain",
    description:
      "144 L Fast Freezer Power Indicator Function Color: Silver Cooling Technology: Defrost Energy Rating: A Temperature Control: Mechanical Refrigerant Gas: R600A Easy Cleaning Convenient Defrost Drain",
    reviews: [
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "116",
    productName: "FZ250 FREEZER FZ189L UPRIGHT FREEZER",
    imgUrl: productImg116,
    category: "Hisense congelateur",
    price: '',
    slider: SliderCongelateurHisense,
    shortDesc:
      "Panier de porte à tiroir à porte simple 189 litres à89 étagères",
    description:
      "Panier de porte à tiroir à porte simple 189 litres à89 étagères",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "117",
    productName: "FZ260 Congelateur R600A external Condenser Silver Color ",
    imgUrl: productImg117,
    category: "Hisense congelateur",
    price: 400,
    slider: SliderCongelateurHisense,
    shortDesc:
      "Capacité du congélateur : 260 Litres Classe énergétique : A Réfrigérant : R600a Tension/Fréquence : 220/50 Hz Dimensions :  99 x 72 x 100,3 cm 06 mois de garantie",
    description:
      "Capacité du congélateur : 260 Litres Classe énergétique : A Réfrigérant : R600a Tension/Fréquence : 220/50 Hz Dimensions :  99 x 72 x 100,3 cm 06 mois de garantie",
    reviews: [
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "118",
    productName: "FREEZER 24OL 320SH",
    imgUrl: productImg118,
    category: "Hisense congelateur",
    price: 500,
    slider: SliderCongelateurHisense,
    shortDesc:
      "Une cote énergétique Fonction de super congélation Température réglable Verrouillage de contrôle avec clé Panier de rangement amovible Dégivrer Vidange Indicateur de puissance Transition de refroidissement et de congélation Porte en verre",
    description:
      "Une cote énergétique Fonction de super congélation Température réglable Verrouillage de contrôle avec clé Panier de rangement amovible Dégivrer Vidange Indicateur de puissance Transition de refroidissement et de congélation Porte en verre",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },
  
  {
    id: "1181",
    productName: "340 FREEZER R600A, SILVER EXTERNAL CONDENSER",
    imgUrl: productImg1181,
    category: "Hisense congelateur",
    price: '',
    slider: SliderCongelateurHisense,
    shortDesc:
      "Une cote énergétique Fonction de super congélation Température réglable Verrouillage de contrôle avec clé Panier de rangement amovible Dégivrer Vidange Indicateur de puissance Transition de refroidissement et de congélation Porte en verre",
    description:
      "Finish：White Ne Capacity：297L A nergy Saving Derost Drain System Mutimode Function Frdge Chller Frezer Suer Freezer Meallic Spring-load Hinges 2 CS Wire Basket Alminum Interior Fat Freeze Function Exernal Handle with Lock Adustable Feet Diension (W*D*H mm)：1114*630*842 Cotainer Load (40’HQ): 108",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },
  //ajoute--------------------
  {
    id: "11181",
    productName: "FREEZER 301L 390SH",
    imgUrl: productImg11181,
    category: "Hisense congelateur",
    price: '',
    slider: SliderCongelateurHisense,
    shortDesc:
      "Une cote énergétique Fonction de super congélation Température réglable Verrouillage de contrôle avec clé Panier de rangement amovible Dégivrer Vidange Indicateur de puissance Transition de refroidissement et de congélation Porte en verre",
    description:
      "Finish：White Ne Capacity：297L A nergy Saving Derost Drain System Mutimode Function Frdge Chller Frezer Suer Freezer Meallic Spring-load Hinges 2 CS Wire Basket Alminum Interior Fat Freeze Function Exernal Handle with Lock Adustable Feet Diension (W*D*H mm)：1114*630*842 Cotainer Load (40’HQ): 108",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },
  //--------------------------
 {
    id: "119",
    productName: "FREEZER FC-55DD4SA",
    imgUrl: productImg119,
    category: "Hisense congelateur",
    price: '',
    slider: SliderCongelateurHisense,
    shortDesc:
      "Capacité brute – 550 litres Économie d'énergie A++ Fonction de coupure de courant Congélation rapide Fonction d'alarme de surchauffe Contrôle mécanique de la température avec thermostat réglable Fonction d'indicateur d'alimentation Dispositif d'évacuation de l'eau",
    description:
      "Capacité brute – 550 litres Économie d'énergie A++ Fonction de coupure de courant Congélation rapide Fonction d'alarme de surchauffe Contrôle mécanique de la température avec thermostat réglable Fonction d'indicateur d'alimentation Dispositif d'évacuation de l'eau",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "120",
    productName: "FREEZER FC-66DD4SA",
    imgUrl: productImg120,
    category: "Hisense congelateur",
    price: 1200,
    slider: SliderCongelateurHisense,
    shortDesc:
      "Congélateur coffre Hisense 660 litres double porte blanc modèle Fc66Dd4Sa -1 an de garantie complète et 5 compresseurs.",
    description:
      "Congélateur coffre Hisense 660 litres double porte blanc modèle FC66DD4SA -1 an de garantie complète et 5 ans sur le compresseur. Fabriqué à partir d'un matériau moussant LBA avancé pour garantir son effet d'isolation thermique supérieur, ce nouveau congélateur coffre a une plus grande capacité par rapport aux congélateurs normaux.",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },
 
  {
    id: "1161",
    productName: "FREEZER 189L RS 189",
    imgUrl: productImg1161,
    category: "Hisense congelateur",
    price: 390,
    slider: SliderCongelateurHisense,
    shortDesc:
      "Panier de porte à tiroir à porte simple 189 litres à89 étagères",
    description:
      "Panier de porte à tiroir à porte simple 189 litres à89 étagères",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "121",
    productName: "FREEZER 91DD 702L",
    imgUrl: productImg121,
    category: "Hisense congelateur",
    price: '',
    slider: SliderCongelateurHisense,
    shortDesc:
      "Couleur argentée de fonction d'indicateur de puissance de congélateur rapide du GAZ R600 de congélateur de coffre à double porte de 702 litres",
    description:
      "Couleur argentée de fonction d'indicateur de puissance de congélateur rapide du GAZ R600 de congélateur de coffre à double porte de 702 litres",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "122",
    productName: "SHOWCASE REF-FL-30FC",
    imgUrl: productImg122,
    category: "Hisense congelateur",
    price: 700,
    slider: SliderCongelateurHisense,
    shortDesc:
      "Capacité : 222L Affichage du refroidisseur de boissons : Oui Réfrigérant : Gaz R600 Type : Refroidisseur de boissons vitrine Contrôle mécanique : Oui Système de dégivrage automatique Porte à arc intégrée Lumière intérieure pour un effet lumineux Basse tension Porte en verre double couche Bac d'élimination de l'eau",
    description:
      "Capacité : 222L Affichage du refroidisseur de boissons : Oui Réfrigérant : Gaz R600 Type : Refroidisseur de boissons vitrine Contrôle mécanique : Oui Système de dégivrage automatique Porte à arc intégrée Lumière intérieure pour un effet lumineux Basse tension Porte en verre double couche Bac d'élimination de l'eau",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "123",
    productName: "SHOWCASE REF-FL37FC4SA BLACK COLOR",
    imgUrl: productImg123,
    category: "Hisense congelateur",
    price: 900,
    slider: SliderCongelateurHisense,
    shortDesc:
      "Capacité : 282L Réfrigérant : Gaz R600 Type de porte : Porte vitrée simple Type d'affichage : Vitrine Éclairage : éclairage LED Plage de température : contrôle de température réglable Étagères : étagères réglables pour un rangement flexible Efficacité énergétique : conception économe en énergie Niveau de bruit : fonctionnement à faible bruit Sécurité : mécanisme de verrouillage et de clé pour plus de sécurité",
    description:
      "Capacité : 282L Réfrigérant : Gaz R600 Type de porte : Porte vitrée simple Type d'affichage : Vitrine Éclairage : éclairage LED Plage de température : contrôle de température réglable Étagères : étagères réglables pour un rangement flexible Efficacité énergétique : conception économe en énergie Niveau de bruit : fonctionnement à faible bruit Sécurité : mécanisme de verrouillage et de clé pour plus de sécurité",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },
 

  {
    id: "124",
    productName: "SHOWCASE 320L WHITE COLOR FL-42FC",
    imgUrl: productImg124,
    category: "Hisense congelateur",
    price: 1000,
    slider: SliderCongelateurHisense,
    shortDesc:
      "Refroidisseur d'affichage de boissons d'une capacité de 306 L",
    description:
      "Refroidisseur d'affichage de boissons d'une capacité de 306 L",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "125",
    productName: "SHOWCASE REF-FL50FC WHITE COLOR",
    imgUrl: productImg125,
    category: "Hisense congelateur",
    price: 1250,
    slider: SliderCongelateurHisense,
    shortDesc:
      "capacité : 382L Réfrigérant : Gaz R600 Refroidisseur d'affichage de boissons : Oui Couleur blanche Capacité de rétention : 48 heures Surfusion Porte vitrée transparente",
    description:
      "capacité : 382L Réfrigérant : Gaz R600 Refroidisseur d'affichage de boissons : Oui Couleur blanche Capacité de rétention : 48 heures Surfusion Porte vitrée transparente",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "126",
    productName: "SHOWCASE REF-FL99WC BLACK COLOR",
    imgUrl: productImg126,
    category: "Hisense congelateur",
    price: 3000,
    slider: SliderCongelateurHisense,
    shortDesc:
      "Contrôle mécanique de la température avec thermostat réglable Système de dégivrage automatique Porte à arc intégrée (deux pièces), apparence à la mode Lumière intérieure pour un effet lumineux de la lumière verticale pour avoir un meilleur éclairage Conception de verre de porte à double couche Technologie respectueuse de l'environnement, conception pratique pour un déplacement facile avec bac d'élimination de l'eau auto-évaporée. Démarrage basse tension Avec ventilateurs internes et inférieurs pour offrir une efficacité de refroidissement plus élevée Avec flux d'air chaud pour éviter la condensation d'eau sur la porte",
    description:
      "Contrôle mécanique de la température avec thermostat réglable Système de dégivrage automatique Porte à arc intégrée (deux pièces), apparence à la mode Lumière intérieure pour un effet lumineux de la lumière verticale pour avoir un meilleur éclairage Conception de verre de porte à double couche Technologie respectueuse de l'environnement, conception pratique pour un déplacement facile avec bac d'élimination de l'eau auto-évaporée. Démarrage basse tension Avec ventilateurs internes et inférieurs pour offrir une efficacité de refroidissement plus élevée Avec flux d'air chaud pour éviter la condensation d'eau sur la porte",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  //Hisense COOKER

  {
    id: "127",
    productName: "HISENSE 60CM WALL MOUNTED COOKER HOOD HH60TASS",
    imgUrl: productImg127,
    category: "Hisense cuisiniere",
    price: 350,
    slider: SliderCuisiniereHisense,
    shortDesc:
      "Éclairage LED Filtres lavables Bon flux d'air Acier inoxydable",
    description:
      "Éclairage LED Filtres lavables Bon flux d'air Acier inoxydable",
    reviews: [
      {
        rating: 4.7,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "1271",
    productName: "HISENSE GAS COOKER 60*57 HFG60121X4 GAS BURNERS",
    imgUrl: productImg1271,
    category: "Hisense cuisiniere",
    price: 375,
    slider: SliderCuisiniereHisense,
    shortDesc:
      "Éclairage LED Filtres lavables Bon flux d'air Acier inoxydable",
    description:
      "Éclairage LED Filtres lavables Bon flux d'air Acier inoxydable",
    reviews: [
      {
        rating: 4.7,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.5,
  },
  //ajout--------------------
  
 
  
  {
    id: "1129",
    productName: "HISENSE COOKER 60CM BUILD OVEN HBO602023",
    imgUrl:  productImg127,
    category: "Hisense cuisiniere",
    price: 500,
    slider: SliderCuisiniereHisense,
    shortDesc:
      "Finition en acier inoxydable Une cote énergétique Porte en verre double couche Intérieur facile à nettoyer Indicateur de puissance et de température Lumière interne",
    description:
      "Finition en acier inoxydable Une cote énergétique Porte en verre double couche Intérieur facile à nettoyer Indicateur de puissance et de température Lumière interne",
    reviews: [
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },
  //----------------

 {/** {
    id: "128",
    productName: "HISENSE COOKER 60CM BUILTIN OVEN HBO60203",
    imgUrl: productImg128,
    category: "Hisense cuisiniere",
    price: 500,
    slider: SliderCuisiniereHisense,
    shortDesc:
      "Finition en acier inoxydable Une cote énergétique Porte en verre double couche Intérieur facile à nettoyer Indicateur de puissance et de température Lumière interne",
    description:
      "Finition en acier inoxydable Une cote énergétique Porte en verre double couche Intérieur facile à nettoyer Indicateur de puissance et de température Lumière interne",
    reviews: [
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  } */},

  {
    id: "129",
    productName: "HISENSE COOKER CERAMIC HOB HHU60CEEC 59CM BUILT IN CERAMIC HOB BLACK VITRO",
    imgUrl: productImg130,
    category: "Hisense cuisiniere",
    price: 400,
    slider: SliderCuisiniereHisense,
    shortDesc:
      "Assiette en verre céramique élégante et robuste Panneau de commande tactile facile à utiliser L'indicateur de chaleur résiduelle vous avertit lorsque la plaque est encore chaude même après avoir été éteinte Verrouillez facilement le panneau de commande de la table de cuisson pour empêcher les enfants de l'allumer et de se brûler Quatre plaques chauffantes garantissent qu'il y a suffisamment d'espace pour cuisiner facilement un repas entier Choisissez entre 9 options de niveaux de puissance pour répondre à vos besoins de cuisson",
    description:
      "Assiette en verre céramique élégante et robuste Panneau de commande tactile facile à utiliser L'indicateur de chaleur résiduelle vous avertit lorsque la plaque est encore chaude même après avoir été éteinte Verrouillez facilement le panneau de commande de la table de cuisson pour empêcher les enfants de l'allumer et de se brûler Quatre plaques chauffantes garantissent qu'il y a suffisamment d'espace pour cuisiner facilement un repas entier Choisissez entre 9 options de niveaux de puissance pour répondre à vos besoins de cuisson",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "130",
    productName: "HISENSE 90CM FREE STANDING COOKER CERAMIC COOKTOP 5 BURNERS HFEC90545X",
    imgUrl: productImg129,
    category: "Hisense cuisiniere",
    price: 1100,
    slider: SliderCuisiniereHisense,
    shortDesc:
      "Finition mat Allumage automatique 2 ventilateurs à convection Porte en verre double couche Compartiment de stockage Intérieur facile à nettoyer 6 fonctions Brûleur Wok Dispositif de panne de flamme",
    description:
      "Finition mat Allumage automatique 2 ventilateurs à convection Porte en verre double couche Compartiment de stockage Intérieur facile à nettoyer 6 fonctions Brûleur Wok Dispositif de panne de flamme",
    reviews: [
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  {/**{
    id: "131",
    productName: "623 DISHWASHER 3 BASKETS 8 PROGRAMS INOX COLOR",
    imgUrl: productImg131,
    category: "Hisense machine à laver",
    price: 430,
    slider: SliderMachineHisense,
    shortDesc:
      "Lave-vaisselle Hisense en acier inoxydable, 8 programmes avec 15 couverts, trois paniers, couleur argent modèle - H623.",
    description:
      "Lave-vaisselle Hisense en acier inoxydable, 8 programmes avec 15 couverts, trois paniers, couleur argent modèle - H623. Une minuterie vous permet de régler votre lave-vaisselle pour qu'il démarre 1 à 24 heures plus tard. Un design élégant et raffiné s'intègre parfaitement à n'importe quelle armoire Le panier est équipé de dispositifs spéciaux en haut. En appuyant dessus, la hauteur du panier supérieur peut être facilement réglée en trois étapes. Cela simplifie le chargement et le déchargement, en particulier lorsqu'il s'agit de grandes casseroles ou assiettes.",
    reviews: [
      {
        rating: 4.7,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.5,
  }, */},

  {
    id: "132",
    productName: "washing machine 6kg 6010s front loading 6kg",
    imgUrl: productImg132,
    category: "Hisense machine à laver",
    price: '',
    slider: SliderMachineHisense,
    shortDesc:
      "Rendement A+++ -20% Lavage rapide Tambour de flocon de neige Nettoyage du tambour",
    description:
      "Rendement A+++ -20% Lavage rapide Tambour de flocon de neige Nettoyage du tambour",
    reviews: [
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "133",
    productName: "7Kg 7012s front loanding",
    imgUrl: productImg133,
    category: "Hisense machine à laver",
    price: 400,
    slider: SliderMachineHisense,
    shortDesc:
      "LAVAGE AU JET PUR Débit d'eau puissant à grande vitesse pour un nettoyage efficace",
    description:
      "Moins de repassage, nettoyage en profondeur, rafraîchissement des vêtements",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "134",
    productName: "FULL AUTOMATIC 8Kg WF8014T",
    imgUrl: productImg134,
    category: "Hisense machine à laver",
    price: '',
    slider: SliderMachineHisense,
    shortDesc:
      "Note* : 5 étoiles Couleur du produit :   Gris Vitesse d'essorage maximale (rpm) :   1400 Moteur inverseur ProSmartTM Capacité de lavage (KG) :   8 StainExpert MC GentleWaveTM​ Lavage à la vapeurTM Accueil WhizTM Garantie :   3 ans tous risques + 09 ans supplémentaires sur le moteur LxPxH (cm) :   60x59x84",
    description:
      "Note* : 5 étoiles Couleur du produit :   Gris Vitesse d'essorage maximale (rpm) :   1400 Moteur inverseur ProSmartTM Capacité de lavage (KG) :   8 StainExpert MC GentleWaveTM​ Lavage à la vapeurTM Accueil WhizTM Garantie :   3 ans tous risques + 09 ans supplémentaires sur le moteur LxPxH (cm) :   60x59x84",
    reviews: [
      {
        rating: 4.7,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "135",
    productName: "8043WM 8Kg WASHING AND 5Kg DRYER",
    imgUrl: productImg135,
    category: "Hisense machine à laver",
    price: '',
    slider: SliderMachineHisense,
    shortDesc:
      "Vitesse d'essorage : 1400 tr/min 8 kg de lavage et 5 kg de sèche-linge Dimensions : H84,5 x L59,5 x P54 cm Type d'affichage : LED Nombre de programmes : 13 laveuse, 2 sécheuse Classe énergétique : A Angle d'ouverture de la porte : 135 degrés Classe d'efficacité d'essorage : B Volume du tambour : 61 Litres",
    description:
      "Vitesse d'essorage : 1400 tr/min 8 kg de lavage et 5 kg de sèche-linge Dimensions : H84,5 x L59,5 x P54 cm Type d'affichage : LED Nombre de programmes : 13 laveuse, 2 sécheuse Classe énergétique : A Angle d'ouverture de la porte : 135 degrés Classe d'efficacité d'essorage : B Volume du tambour : 61 Litres",
    reviews: [
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.8,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "136",
    productName: "Front Load Washing Machine and Dryer 10Kg Wf3Q1043BT",
    imgUrl: productImg136,
    category: "Hisense machine à laver",
    price: 650,
    slider: SliderMachineHisense,
    shortDesc:
      "Type d'affichage LED Couleur d'affichage Blanc Serrure de porte Serrure électromagnétique Moteur BLDC Couleur du moteur Gris titane Onduleur",
    description:
      "Type d'affichage LED Couleur d'affichage Blanc Serrure de porte Serrure électromagnétique Moteur BLDC Couleur du moteur Gris titane Onduleur",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },
  
  {
    id: "137",
    productName: "Washing machine 12kg WF5S123BT",
    imgUrl: productImg137,
    category: "Hisense machine à laver",
    price: '',
    slider: SliderMachineHisense,
    shortDesc:
      "Type d'affichage LED Couleur d'affichage Blanc Serrure de porte Serrure électromagnétique Moteur BLDC Couleur du moteur Gris titane Onduleur",
    description:
      "Type d'affichage LED Couleur d'affichage Blanc Serrure de porte Serrure électromagnétique Moteur BLDC Couleur du moteur Gris titane Onduleur",
    reviews: [
      {
        rating: 4.7,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "138",
    productName: "Washing machine 12Kg WF5S 1243bt",
    imgUrl: productImg138,
    category: "Hisense machine à laver",
    price: 750,
    slider: SliderMachineHisense,
    shortDesc:
      "Classe d'efficacité énergétique : A Capacité de lavage : 12 kgType de construction : AutonomeLargeur du produit : 595 mmHauteur du produit : 845 mm",
    description:
      "Classe d'efficacité énergétique : A Capacité de lavage : 12 kgType de construction : AutonomeLargeur du produit : 595 mmHauteur du produit : 845 mm",
    reviews: [
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  //Ajout

  {
    id: "13911",
    productName: "TOP LOAD WASHING MACHINE WTCT802 8Kg",
    imgUrl: productImg139,
    category: "Hisense machine à laver",
    price: 420,
    slider: SliderMachineHisense,
    shortDesc:
      "Induction d'humidité Éclairage LED Filtre facile à nettoyer Fonction anti-froissage automatique Affichage LED intelligent Séchage différé de 24 heures",
    description:
      "Induction d'humidité Éclairage LED Filtre facile à nettoyer Fonction anti-froissage automatique Affichage LED intelligent Séchage différé de 24 heures",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "139",
    productName: "DRYER 8KG DV1W801UT",
    imgUrl: productImg139,
    category: "Hisense machine à laver",
    price: 400,
    slider: SliderMachineHisense,
    shortDesc:
      "Induction d'humidité Éclairage LED Filtre facile à nettoyer Fonction anti-froissage automatique Affichage LED intelligent Séchage différé de 24 heures",
    description:
      "Induction d'humidité Éclairage LED Filtre facile à nettoyer Fonction anti-froissage automatique Affichage LED intelligent Séchage différé de 24 heures",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "13912",
    productName: "WM 5KG TWIN TUB WSPA503",
    imgUrl: productImg13911,
    category: "Hisense machine à laver",
    price: '',
    slider: SliderMachineHisense,
    shortDesc:
      "Induction d'humidité Éclairage LED Filtre facile à nettoyer Fonction anti-froissage automatique Affichage LED intelligent Séchage différé de 24 heures",
    description:
      "Induction d'humidité Éclairage LED Filtre facile à nettoyer Fonction anti-froissage automatique Affichage LED intelligent Séchage différé de 24 heures",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "13913",
    productName: "WM 7.5KG TWIN TUB WSQB753",
    imgUrl: productImg139,
    category: "Hisense machine à laver",
    price: '',
    slider: SliderMachineHisense,
    shortDesc:
      "Induction d'humidité Éclairage LED Filtre facile à nettoyer Fonction anti-froissage automatique Affichage LED intelligent Séchage différé de 24 heures",
    description:
      "Induction d'humidité Éclairage LED Filtre facile à nettoyer Fonction anti-froissage automatique Affichage LED intelligent Séchage différé de 24 heures",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "1381",
    productName: "Washing machine 1245WM 12/8 Kg 12Kg Wash 8Kg Dryer Front Loader",
    imgUrl: productImg1381,
    category: "Hisense machine à laver",
    price: 900,
    slider: SliderMachineHisense,
    shortDesc:
      "Classe d'efficacité énergétique : A Capacité de lavage : 12 kgType de construction : AutonomeLargeur du produit : 595 mmHauteur du produit : 845 mm",
    description:
      "Classe d'efficacité énergétique : A Capacité de lavage : 12 kgType de construction : AutonomeLargeur du produit : 595 mmHauteur du produit : 845 mm",
    reviews: [
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "139",
    productName: "DRYER DVDL80S 8KGT",
    imgUrl: productImg139,
    category: "Hisense machine à laver",
    price: '',
    slider: SliderMachineHisense,
    shortDesc:
      "Induction d'humidité Éclairage LED Filtre facile à nettoyer Fonction anti-froissage automatique Affichage LED intelligent Séchage différé de 24 heures",
    description:
      "Induction d'humidité Éclairage LED Filtre facile à nettoyer Fonction anti-froissage automatique Affichage LED intelligent Séchage différé de 24 heures",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  // Microwave
  
  {
    id: "140",
    productName: "20L MICROwave h20mommi silver",
    imgUrl: productImg140,
    category: "Hisense micro-onde",
    price: 130,
    slider: SliderMicroHisense,
    shortDesc:
      "Verre miroir Réglage de 6 niveaux de puissance Fonction de dégivrage Conception de porte durable Chaleur de transfert de proportion d'or de 700 W Plaque chauffante rotative à 360° 30 minutes de cuisson",
    description:
      "Verre miroir Réglage de 6 niveaux de puissance Fonction de dégivrage Conception de porte durable Chaleur de transfert de proportion d'or de 700 W Plaque chauffante rotative à 360° 30 minutes de cuisson",
    reviews: [
      {
        rating: 4.7,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "14011",
    productName: "microwave h30mobs9hg 30l",
    imgUrl: productImg141,
    category: "Hisense micro-onde",
    price: 275,
    slider: SliderMicroHisense,
    shortDesc:
      "Verre miroir Réglage de 6 niveaux de puissance Fonction de dégivrage Conception de porte durable Chaleur de transfert de proportion d'or de 700 W Plaque chauffante rotative à 360° 30 minutes de cuisson",
    description:
      "Verre miroir Réglage de 6 niveaux de puissance Fonction de dégivrage Conception de porte durable Chaleur de transfert de proportion d'or de 700 W Plaque chauffante rotative à 360° 30 minutes de cuisson",
    reviews: [
      {
        rating: 4.7,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "14012",
    productName: "MWHIH26MOS5H WITH HANDLE",
    imgUrl: productImg14012,
    category: "Hisense micro-onde",
    price: 160,
    slider: SliderMicroHisense,
    shortDesc:
      "Verre miroir Réglage de 6 niveaux de puissance Fonction de dégivrage Conception de porte durable Chaleur de transfert de proportion d'or de 700 W Plaque chauffante rotative à 360° 30 minutes de cuisson",
    description:
      "Verre miroir Réglage de 6 niveaux de puissance Fonction de dégivrage Conception de porte durable Chaleur de transfert de proportion d'or de 700 W Plaque chauffante rotative à 360° 30 minutes de cuisson",
    reviews: [
      {
        rating: 4.7,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "14013",
    productName: "MWHIH34MOBS10HG BLACK GRILL",
    imgUrl: productImg14013,
    category: "Hisense micro-onde",
    price: 225,
    slider: SliderMicroHisense,
    shortDesc:
      "Verre miroir Réglage de 6 niveaux de puissance Fonction de dégivrage Conception de porte durable Chaleur de transfert de proportion d'or de 700 W Plaque chauffante rotative à 360° 30 minutes de cuisson",
    description:
      "Verre miroir Réglage de 6 niveaux de puissance Fonction de dégivrage Conception de porte durable Chaleur de transfert de proportion d'or de 700 W Plaque chauffante rotative à 360° 30 minutes de cuisson",
    reviews: [
      {
        rating: 4.7,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "14014",
    productName: "AIR FRYER AFHIH06AFGY1S1 5.2L",
    imgUrl: "https://hisensekenyaregion.co.ke/wp-content/uploads/2023/09/H06AFBS1S3.png",
    category: "Hisense micro-onde",
    price: 75,
    slider: SliderMicroHisense,
    shortDesc:
      "Verre miroir Réglage de 6 niveaux de puissance Fonction de dégivrage Conception de porte durable Chaleur de transfert de proportion d'or de 700 W Plaque chauffante rotative à 360° 30 minutes de cuisson",
    description:
      "Verre miroir Réglage de 6 niveaux de puissance Fonction de dégivrage Conception de porte durable Chaleur de transfert de proportion d'or de 700 W Plaque chauffante rotative à 360° 30 minutes de cuisson",
    reviews: [
      {
        rating: 4.7,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.5,
  },

  //ajout 

  {
    id: "141",
    productName: "28L BLACK MINI OVEN H28TOBKPKL16",
    imgUrl: productImg14111,
    category: "Hisense micro-onde",
    price: 150,
    slider: SliderMicroHisense,
    shortDesc:
      "Capacité 30L Fonction préréglée Sécurité enfant Signal de fin de cuisson Set de cuisson à mémoire réglage de l'horloge à 12h minuterie de cuisson jusqu'à 99'99'' Démarrage express de la cuisson Affichage LED bleu Contrôle numérique à membrane 11 niveaux de puissance 6 menus de cuisson automatique Dégivrage poids/vitesse Cuisson en plusieurs étapes",
    description:
      "Capacité 30L Fonction préréglée Sécurité enfant Signal de fin de cuisson Set de cuisson à mémoire réglage de l'horloge à 12h minuterie de cuisson jusqu'à 99'99'' Démarrage express de la cuisson Affichage LED bleu Contrôle numérique à membrane 11 niveaux de puissance 6 menus de cuisson automatique Dégivrage poids/vitesse Cuisson en plusieurs étapes",
    reviews: [
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "142",
    productName: "046DR",
    imgUrl: productImg142,
    category: "Hisense refrigerateur",
    price: '',
    slider: SliderRefHisense,
    shortDesc:
      "45 litres Pas de givre Faible bruit Technologie respectueuse de l'environnement Argent Porte simple Petit congélateur",
    description:
      "45 litres Pas de givre Faible bruit Technologie respectueuse de l'environnement Argent Porte simple Petit congélateur",
    reviews: [
      {
        rating: 4.7,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "143",
    productName: "93DR",
    imgUrl: productImg143,
    category: "Hisense refrigerateur",
    price: '',
    slider: SliderRefHisense,
    shortDesc:
      " Gel	Oui Faible Bruit	Oui Couleur	Argent Technologie Respectueuse De L'environnement	Oui Taille	90L Dimensions",
    description:
      "Gel	Oui Faible Bruit	Oui Couleur	Argent Technologie Respectueuse De L'environnement	Oui Taille	90L Dimensions",
    reviews: [
      {
        rating: 4.8,
        text: "la fraicheur",
      },
      {
        rating: 4.8,
        text: "Fouani Congo toujours meilleur",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "144",
    productName: "REF 121DR SILVER",
    imgUrl: productImg144,
    category: "Hisense refrigerateur",
    price: 320,
    slider: SliderRefHisense,
    shortDesc:
      "121L Frost Yes Low Noise Yes Color Silver Glass Shelf Tray Yes Ice Tray & Manual Yes Veg Drawer Yes Dimensions (W X D X H)(Mm) 475 X 556 X 840",
    description:
      "121L Frost Yes Low Noise Yes Color Silver Glass Shelf Tray Yes Ice Tray & Manual Yes Veg Drawer Yes Dimensions (W X D X H)(Mm) 475 X 556 X 840",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "145",
    productName: "REF20S 150L SILVER COLOR",
    imgUrl: productImg145,
    category: "Hisense refrigerateur",
    price: '',
    slider: SliderRefHisense,
    shortDesc:
      "DESCRIPTION Single Door Energy Efficient- 3 Stars Silver Colour Lock and Key R600a Gas Transparent Shelves Five years warranty",
    description:
      "DESCRIPTION Single Door Energy Efficient- 3 Stars Silver Colour Lock and Key R600a Gas Transparent Shelves Five years warranty",
    reviews: [
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "146",
    productName: "REF 172DR",
    imgUrl: productImg146,
    category: "Hisense refrigerateur",
    price: 410,
    slider: SliderRefHisense,
    shortDesc:
      " Capacity 124Ltrs Color Silver Environment -Friendly Tech Yes Low Noise Yes Power Rating (V) 220 – 240 / 50 Gas Type R600 Frost Free / No frost Yes Top freezer Yes Egg & Ice Tray Yes Bottom Fridge Yes Compressor Yes Dimensions (W x H x D)(MM) 475 x 1169.5 x 467",
    description:
      "Capacity 124Ltrs Color Silver Environment -Friendly Tech Yes Low Noise Yes Power Rating (V) 220 – 240 / 50 Gas Type R600 Frost Free / No frost Yes Top freezer Yes Egg & Ice Tray Yes Bottom Fridge Yes Compressor Yes Dimensions (W x H x D)(MM) 475 x 1169.5 x 467",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "147",
    productName: "REF230 WITH WATER DISPENSER 178L",
    imgUrl: productImg147,
    category: "Hisense refrigerateur",
    price: 450,
    slider: SliderRefHisense,
    shortDesc:
      "Capacité Nette (Litre)	176 Litres Dégivrer	Manuel Faible Bruit	Oui Technologie Respectueuse De L'environnement	Oui Fontaine À Eau	Oui Dimensions (LXDXH)",
    description:
      "Capacité Nette (Litre)	176 Litres Dégivrer	Manuel Faible Bruit	Oui Technologie Respectueuse De L'environnement	Oui Fontaine À Eau	Oui Dimensions (LXDXH)",
    reviews: [
      {
        rating: 4.7,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "148",
    productName: "REF205 205L",
    imgUrl: productImg148,
    category: "Hisense refrigerateur",
    price: 525,
    slider: SliderRefHisense,
    shortDesc:
      "Modèle : REF205DR Capacité : 205L Type : Réfrigérateur monté en bas Fluide frigorigène : Gaz R600A Couleur : Argent Faible bruit : Oui Gel : Oui Poids : 42kg",
    description:
      "Modèle : REF205DR Capacité : 205L Type : Réfrigérateur monté en bas Fluide frigorigène : Gaz R600A Couleur : Argent Faible bruit : Oui Gel : Oui Poids : 42kg",
    reviews: [
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.8,
        text: "La société Fouani Congo dispose les meilleurs",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "149",
    productName: "REF 205RED 199L WITH WATER DISPENSER",
    imgUrl: productImg149,
    category: "Hisense refrigerateur",
    price: 525,
    slider: SliderRefHisense,
    shortDesc:
      " Capacité	205 L Pas De Gel	Oui Fontaine À Eau	Non Couleur	Argent Foncé Type De Gaz	600 Rands Faible Bruit	Oui Dimensions (LXDXH)	550×542×1434 Capacité	205 L Pas De Gel	Oui Fontaine À Eau	Non Couleur	Argent Foncé Type De Gaz	600 Rands Faible Bruit	Oui Dimensions (LXDXH)	550×542×1434",
    description:
      "Capacité	205 L Pas De Gel	Oui Fontaine À Eau	Non Couleur	Argent Foncé Type De Gaz	600 Rands Faible Bruit	Oui Dimensions (LXDXH)	550×542×1434 Capacité	205 L Pas De Gel	Oui Fontaine À Eau	Non Couleur	Argent Foncé Type De Gaz	600 Rands Faible Bruit	Oui Dimensions (LXDXH)	550×542×1434",
    reviews: [
      {
        rating: 4.6,
        text: "La société Fouani Congo dispose les meilleurs",
      },
      {
        rating: 4.9,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "150",
    productName: "REF60 461L SILVER 60W R",
    imgUrl: productImg150,
    category: "Hisense refrigerateur",
    price: 950,
    slider: SliderRefHisense,
    shortDesc:
      "Capacité	460 LitresCouleur	ArgentSans Givre	OuiSystème À Flux D'air Multiples	OuiFaible Bruit	OuiGaz	R600ADimensions (LXDXH)	700×685×1850",
    description:
      "Capacité	460 LitresCouleur	ArgentSans Givre	OuiSystème À Flux D'air Multiples	OuiFaible Bruit	OuiGaz	R600ADimensions (LXDXH)	700×685×1850",
    reviews: [
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "151",
    productName: "REF 240DR 240L SILVER",
    imgUrl: productImg151,
    category: "Hisense refrigerateur",
    price: 600,
    slider: SliderRefHisense,
    shortDesc:
      " Capacité	240 Litres Pas De Gel	Oui Couleur	Argent Type De Gaz	600 Rands Faible Bruit	Oui Tiroir À Légumes	Oui Plateau À Œufs Et À Glace	Oui Panier De Porte R	Oui Dimensions (L X H X P)(MM)	550x1610x547 ",
    description:
      "Capacité	240 Litres Pas De Gel	Oui Couleur	Argent Type De Gaz	600 Rands Faible Bruit	Oui Tiroir À Légumes	Oui Plateau À Œufs Et À Glace	Oui Panier De Porte R	Oui Dimensions (L X H X P)(MM)	550x1610x547",
    reviews: [
      {
        rating: 4.6,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.9,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "152",
    productName: "35DCB 264L External Condensor",
    imgUrl: productImg152,
    category: "Hisense refrigerateur",
    price: 650,
    slider: SliderRefHisense,
    shortDesc:
      "Distributeur d'eau à faible bruit et sans gel Classe énergétique A+/ A++/ A+++",
    description:
      "Caractéristiques Couleur	Argent Capacité (Litre)	264 L Réfrigérant	R600a Technologie De Contrôle Mécanique De La Température	Oui Faible Bruit	Oui Fontaine À Eau	Oui Capacité Nette (Réfrigérateur/Congélateur)	198 L / 66 L Dimensions (LXDXH)	550×557×1800",
    reviews: [
      {
        rating: 4.7,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "1521",
    productName: "RD 49DR",
    imgUrl: productImg1521,
    category: "Hisense refrigerateur",
    price: '',
    slider: SliderRefHisense,
    shortDesc:
      "Distributeur d'eau à faible bruit et sans gel Classe énergétique A+/ A++/ A+++",
    description:
      "Description Frost Free Low Noise Environment-Friendly Tech Specifications Color	Stainless Steel Net Capacity (Liter)	375 LTR Refrigerant	R600 Cooling System	Frost Free Environment Friendly Tech	Yes LED Lamp	Yes Glass Shelf Tray	Yes Dimensions (W * D * H) Mm	704 * 636 * 1720",
    reviews: [
      {
        rating: 4.7,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "153",
    productName: "REF450 308L REF308 WATER DISPENSER",
    imgUrl: productImg153,
    category: "Hisense refrigerateur",
    price: '',
    slider: SliderRefHisense,
    shortDesc:
      "A+ Energy Saving Water Dispenser Soft LED Big Freezer Box One Line Handle Reversible Door Tempered Glass Shelves Adjustable Glass Shelf Big Bottle Rack",
    description:
      "Caractéristiques -Profil De Porte	Arrondi -Poignée	Intégré -Couleurs Disponibles	Miroir Noir -Tension/Fréquence	220 ~ 240/50 Hz -Capacité Nette	305 -Capacité Nette Du Compartiment Réfrigérateur (Fridge/Chill)	210 -Capacité Nette Du Compartiment Congélateur	95 -Classe D'efficacité Énergétique	A+ -Classe Climatique (SN=10~32°C, N=16~32°C, ST=16~38°C, T=16~43°C)	N～ST -Dimensions De L'unité Avec Poignée ( L / P / H )	595x575x1859 -Dimensions De L'emballage (L/P/H)	628×625×1935 -Poids Net	66 Kg",
    reviews: [
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },
 
  {
    id: "154",
    productName: "REF60 461L SILVER 60W R",
    imgUrl: productImg154,
    category: "Hisense refrigerateur",
    price: '',
    slider: SliderRefHisense,
    shortDesc:
      "- Capacity: 461L - Low Noise - Super Freeze - Cool Function",
    description:
      "- Capacity: 461L - Low Noise - Super Freeze - Cool Function",
    reviews: [
      {
        rating: 4.6,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.9,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "155",
    productName: "REF 66WR TM FROST FREE 510L",
    imgUrl: productImg155,
    category: "Hisense refrigerateur",
    price: '',
    slider: SliderRefHisense,
    shortDesc:
      "- 490 Ltrs capacity - Features Total No Frost - Multy Air Flow System - Adjustable Legs - Smart Control Eletronic avec thermostat - Four star Freezer Design - Reversible - Broad Climate Design - Low Noise - Environment-friendly Technology",
    description:
      "- 490 Ltrs capacity - Features Total No Frost - Multy Air Flow System - Adjustable Legs - Smart Control Eletronic avec thermostat - Four star Freezer Design - Reversible - Broad Climate Design - Low Noise - Environment-friendly Technology",
    reviews: [
      {
        rating: 4.7,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "156",
    productName: "SIDE -BY-SIDE REFRIGERATOR RC67WSI 564 L ",
    imgUrl: productImg156,
    category: "Hisense refrigerateur",
    price: 1800,
    slider: SliderRefHisense,
    shortDesc:
      "- Reversible Door T- emp control/Display - Electronic control - Defrosting - Frost Free - Door Alarm - Super Freeze Function - Multi Air Flow System - Gross Capacity - Lighting",
    description:
      "- Reversible Door T- emp control/Display - Electronic control - Defrosting - Frost Free - Door Alarm - Super Freeze Function - Multi Air Flow System - Gross Capacity - Lighting",
    reviews: [
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "157",
    productName: "REF 67WS 564L WITH WATER DISPENCER",
    imgUrl: productImg157,
    category: "Hisense refrigerateur",
    price: '',
    slider: SliderRefHisense,
    shortDesc:
      "Capacity 564 L with water dispenser",
    description:
      "Capacity 564 L with water dispenser, Hisense Réfrigérateur à porte côte à côte sans givre inverseur 564 L avec distributeur d'eau (RS564N4SSNW, finition acier inoxydable argenté), Les changements de classement par étoiles sont conformes aux directives du BEE au plus tard le 1er janvier 2023. Réfrigérateurs côte à côte sans givre : réfrigérateurs haut de gamme avec fonction de dégivrage automatique pour éviter l'accumulation de glace Capacité 564 litres : Convient aux familles de 5 membres ou plus | Capacité du congélateur: 217 litres, Capacité des aliments frais: 347 litres, avec distributeur d'eau",
    reviews: [
      {
        rating: 4.6,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.9,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "158",
    productName: "SIDE -BY-SIDE REFRIGERATOR RC67BGL",
    imgUrl: productImg158,
    category: "Hisense refrigerateur",
    price: '',
    slider: SliderRefHisense,
    shortDesc:
      "- Top Mount 326L Fridge in White - Reversible doors for left or right hand operation - Fridge capacity 253 litres - Freezer capacity 73 litres - Weight 56kg -Automatic frost-free cooling - LED lighting - 1 shelf, 2 door baskets in freezer - Dimensions 60cm wide, 65cm deep, 170cm high",
    description:
      "- Top Mount 326L Fridge in White - Reversible doors for left or right hand operation - Fridge capacity 253 litres - Freezer capacity 73 litres - Weight 56kg -Automatic frost-free cooling - LED lighting - 1 shelf, 2 door baskets in freezer - Dimensions 60cm wide, 65cm deep, 170cm high",
    reviews: [
      {
        rating: 4.8,
        text: "Fouani Congo",
      },
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "159",
    productName: "REFRIGERATOR RQ56WC",
    imgUrl: productImg159,
    category: "Hisense refrigerateur",
    price: '',
    slider: SliderRefHisense,
    shortDesc:
      "- 4 Doors No-Frost 20 cft Refrigerator - I nverter Compressor Energy Saver & High Efficiency - Computer Control with Led Touch Button - Intelligent temperature control function - Fast Cool & Fast Freeze Function - Holiday function",
    description:
      "- 4 Doors No-Frost 20 cft Refrigerator - I nverter Compressor Energy Saver & High Efficiency - Computer Control with Led Touch Button - Intelligent temperature control function - Fast Cool & Fast Freeze Function - Holiday function",
    reviews: [
      {
        rating: 4.6,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.9,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "160",
    productName: "REF 76WS",
    imgUrl: productImg160,
    category: "Hisense refrigerateur",
    price: 1700,
    slider: SliderRefHisense,
    shortDesc:
      "- Total No Frost -Nano Fresh [Optional] - Hismart Home {Optional} - Multi Air Flow - My Fresh Choice - Inverter Compressor - Electronic LED Display - Dual-Tech Cooling - Triple Zones - 6x LED Lighting - Large Door Balconies",
    description:
      "- Total No Frost -Nano Fresh [Optional] - Hismart Home {Optional} - Multi Air Flow - My Fresh Choice - Inverter Compressor - Electronic LED Display - Dual-Tech Cooling - Triple Zones - 6x LED Lighting - Large Door Balconies",
    reviews: [
      {
        rating: 4.7,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "161",
    productName: "522L REF68 WCI WITH ICE MAKER R600A SILVERCOLOR, KEY",
    imgUrl: productImg161,
    category: "Hisense refrigerateur",
    price: 3200,
    slider: SliderRefHisense,
    shortDesc:
      "- Colour Stainless Steel - Dimensions(H): 178cm; (W): 91cm; (D): 75cm - Fridge Freezer TypeFreestanding - Energy RatingF - Gross Fridge Capacity392L - Gross Freezer Capacity220L - Noise Level 43 dB(A) - Net Freezer Capacity192L - Power Failure Storage 8 Hour - Drawers4 - Frost Free - Fast Freeze",
    description:
      "- Colour Stainless Steel - Dimensions(H): 178cm; (W): 91cm; (D): 75cm - Fridge Freezer TypeFreestanding - Energy RatingF - Gross Fridge Capacity392L - Gross Freezer Capacity220L - Noise Level 43 dB(A) - Net Freezer Capacity192L - Power Failure Storage 8 Hour - Drawers4 - Frost Free - Fast Freeze",
    reviews: [
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.8,
        text: "vraiment",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "162",
    productName: "522L REF68 WCD WITH ICE MAKER BLACK COLOR DIGITAL DISPLAY",
    imgUrl: productImg162,
    category: "Hisense refrigerateur",
    price: 3500,
    slider: SliderRefHisense,
    shortDesc:
      "- Colour Stainless Steel - Dimensions(H): 178cm; (W): 91cm; (D): 75cm - Fridge Freezer TypeFreestanding - Energy RatingF - Gross Fridge Capacity392L - Gross Freezer Capacity220L - Noise Level 43 dB(A) - Net Freezer Capacity192L - Power Failure Storage 8 Hour - Drawers4 - Frost Free - Fast Freeze",
    description:
      "- Colour Stainless Steel - Dimensions(H): 178cm; (W): 91cm; (D): 75cm - Fridge Freezer TypeFreestanding - Energy RatingF - Gross Fridge Capacity392L - Gross Freezer Capacity220L - Noise Level 43 dB(A) - Net Freezer Capacity192L - Power Failure Storage 8 Hour - Drawers4 - Frost Free - Fast Freeze",
    reviews: [
      {
        rating: 4.6,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.9,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "163",
    productName: "REF 72WR WITH ICE MAKER R600A SILVER COLOR, KEY",
    imgUrl: productImg163,
    category: "Hisense refrigerateur",
    price: 1500,
    slider: SliderRefHisense,
    shortDesc:
      "- 545 Litres- Low Noise - Silver Color - Water Dispenser - Environmental Friendly Technology - Smart Control Eletronic avec thermostat - Four star Freezer Design - Reversible - Broad Climate Design - Low Noise - Environment-friendly Technology",
    description:
      "- 545 Litres- Low Noise - Silver Color - Water Dispenser - Environmental Friendly Technology - Smart Control Eletronic avec thermostat - Four star Freezer Design - Reversible - Broad Climate Design - Low Noise - Environment-friendly Technology",
    reviews: [
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },

  
  {
    id: "163",
    productName: "REF 72WR WITH ICE MAKER R600A SILVER COLOR, KEY",
    imgUrl: productImg163,
    category: "Hisense refrigerateur",
    price: 1800,
    slider: SliderRefHisense,
    shortDesc:
      "- 545 Litres- Low Noise - Silver Color - Water Dispenser - Environmental Friendly Technology - Smart Control Eletronic avec thermostat - Four star Freezer Design - Reversible - Broad Climate Design - Low Noise - Environment-friendly Technology",
    description:
      "- 545 Litres- Low Noise - Silver Color - Water Dispenser - Environmental Friendly Technology - Smart Control Eletronic avec thermostat - Four star Freezer Design - Reversible - Broad Climate Design - Low Noise - Environment-friendly Technology",
    reviews: [
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },
   {
    id: "16431",
    productName: "REF 73WCB 560 L",
    imgUrl: productImg164,
    category: "Hisense refrigerateur",
    price: 2400,
    slider: SliderRefHisense,
    shortDesc:
      "- Colour Stainless Steel - Dimensions(H): 178cm; (W): 91cm; (D): 75cm - Fridge Freezer TypeFreestanding - Energy RatingF - Gross Fridge Capacity392L - Gross Freezer Capacity220L - Noise Level 43 dB(A) - Net Freezer  - Power Failure Storage 8 Hour - Drawers4 - Frost Free - Fast Freeze",
    description:
      "- Colour Stainless Steel - Dimensions(H): 178cm; (W): 91cm; (D): 75cm - Fridge Freezer TypeFreestanding - Energy RatingF - Gross Fridge Capacity392L - Gross Freezer Capacity220L - Noise Level 43 dB(A) - Net Freezer  - Power Failure Storage 8 Hour - Drawers4 - Frost Free - Fast Freeze",
    reviews: [
      {
        rating: 4.6,
        text: "de l'eau froide",
      },
      {
        rating: 4.9,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },
{/**
  
  {
    id: "165",
    productName: "INVERTER AC 9000 BTU",
    imgUrl: productImg165,
    category: "Hisense climatiseur",
    price: 290,
    shortDesc:
      "- Wi-Fi Ready -NIonization filter - 3 Antibacterial filters  - Eco-friendly coolant R32 - i-feel & Smart Function - Antimicrobial coating on the inner wing - Automatic restart in case of power failure - Silent Mode 19db - Automatic operation at 8⁰C, fast cooling",
    description:
      "- Wi-Fi Ready -NIonization filter - 3 Antibacterial filters  - Eco-friendly coolant R32 - i-feel & Smart Function - Antimicrobial coating on the inner wing - Automatic restart in case of power failure - Silent Mode 19db - Automatic operation at 8⁰C, fast cooling",
    reviews: [
      {
        rating: 4.7,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "166",
    productName: "INVERTER AC 12000 BTU",
    imgUrl: productImg166,
    category: "Hisense climatiseur",
    price: 299,
    shortDesc:
      "- Wi-Fi Ready -NIonization filter - 3 Antibacterial filters  - Eco-friendly coolant R32 - i-feel & Smart Function - Antimicrobial coating on the inner wing - Automatic restart in case of power failure - Silent Mode 19db - Automatic operation at 8⁰C, fast cooling",
    description:
      "- Wi-Fi Ready -NIonization filter - 3 Antibacterial filters  - Eco-friendly coolant R32 - i-feel & Smart Function - Antimicrobial coating on the inner wing - Automatic restart in case of power failure - Silent Mode 19db - Automatic operation at 8⁰C, fast cooling",
    reviews: [
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  }, */},

  {
    id: "167",
    productName: "9 000 BTU",
    imgUrl: productImg167,
    category: "Hisense climatiseur",
    price: 310,
    slider: SliderSplitHisense,
    shortDesc:
      "Finish Colour White - Reverse Cycle, Max Air Flow 620 m3/hr - Heating Capacity 4 kW - Cooling Capacity 3.5 kW - Inverter Weight (kg) 8.5 Kg - Remote Control - Net weight of outdoor unit 33kg",
    description:
      "Finish Colour White - Reverse Cycle, Max Air Flow 620 m3/hr - Heating Capacity 4 kW - Cooling Capacity 3.5 kW - Inverter Weight (kg) 8.5 Kg - Remote Control - Net weight of outdoor unit 33kg",
    reviews: [
      {
        rating: 4.6,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.9,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "168",
    productName: "12 000 BTU ",
    imgUrl: productImg168,
    category: "Hisense climatiseur",
    price: 330,
    slider: SliderSplitHisense,
    shortDesc:
      "Finish Colour White - Reverse Cycle, Max Air Flow 620 m3/hr - Heating Capacity 4 kW - Cooling Capacity 3.5 kW - Inverter Weight (kg) 8.5 Kg - Remote Control - Net weight of outdoor unit 33kg",
    description:
      "Finish Colour White - Reverse Cycle, Max Air Flow 620 m3/hr - Heating Capacity 4 kW - Cooling Capacity 3.5 kW - Inverter Weight (kg) 8.5 Kg - Remote Control - Net weight of outdoor unit 33kg",
    reviews: [
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "169",
    productName: "18 000 BTU",
    imgUrl: productImg170,
    category: "Hisense climatiseur",
    price: 500,
    shortDesc:
      "Finish Colour White - Reverse Cycle, Max Air Flow 620 m3/hr - Heating Capacity 4 kW - Cooling Capacity 3.5 kW - Inverter Weight (kg) 8.5 Kg - Remote Control - Net weight of outdoor unit 33kg",
    description:
      "Finish Colour White - Reverse Cycle, Max Air Flow 620 m3/hr - Heating Capacity 4 kW - Cooling Capacity 3.5 kW - Inverter Weight (kg) 8.5 Kg - Remote Control - Net weight of outdoor unit 33kg",
    reviews: [
      {
        rating: 4.7,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "170",
    productName: "24 000 BTU ",
    imgUrl: productImg170,
    category: "Hisense climatiseur",
    price: 625,
    slider: SliderSplitHisense,
    shortDesc:
      "Finish Colour White - Reverse Cycle, Max Air Flow 620 m3/hr - Heating Capacity 4 kW - Cooling Capacity 3.5 kW - Inverter Weight (kg) 8.5 Kg - Remote Control - Net weight of outdoor unit 33kg",
    description:
      "Finish Colour White - Reverse Cycle, Max Air Flow 620 m3/hr - Heating Capacity 4 kW - Cooling Capacity 3.5 kW - Inverter Weight (kg) 8.5 Kg - Remote Control - Net weight of outdoor unit 33kg",
    reviews: [
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.8,
        text: "air conditionné",
      },
    ],
    avgRating: 4.7,
  },

  {/**
    {
    id: "171",
    productName: "INVERTER AC 9000 BTU",
    imgUrl: productImg171,
    category: "Hisense climatiseur",
    price: 350,
    slider: SliderSplitHisense,
    shortDesc:
      "- Wi-Fi Ready -NIonization filter - 3 Antibacterial filters  - Eco-friendly coolant R32 - i-feel & Smart Function - Antimicrobial coating on the inner wing - Automatic restart in case of power failure - Silent Mode 19db - Automatic operation at 8⁰C, fast cooling",
    description:
      "- Wi-Fi Ready -NIonization filter - 3 Antibacterial filters  - Eco-friendly coolant R32 - i-feel & Smart Function - Antimicrobial coating on the inner wing - Automatic restart in case of power failure - Silent Mode 19db - Automatic operation at 8⁰C, fast cooling",
    reviews: [
      {
        rating: 4.6,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.9,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  }, */},
  {
    id: "172",
    productName: "INVERTER AC 12000 BTU",
    imgUrl: productImg172,
    category: "Hisense climatiseur",
    price: 380,
    slider: SliderSplitHisense,
    shortDesc:
      "- Wi-Fi Ready -NIonization filter - 3 Antibacterial filters  - Eco-friendly coolant R32 - i-feel & Smart Function - Antimicrobial coating on the inner wing - Automatic restart in case of power failure - Silent Mode 19db - Automatic operation at 8⁰C, fast cooling",
    description:
      "- Wi-Fi Ready -NIonization filter - 3 Antibacterial filters  - Eco-friendly coolant R32 - i-feel & Smart Function - Antimicrobial coating on the inner wing - Automatic restart in case of power failure - Silent Mode 19db - Automatic operation at 8⁰C, fast cooling",
    reviews: [
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "173",
    productName: "INVERTER AC 24 000 BTU",
    imgUrl: productImg173,
    category: "Hisense climatiseur",
    price: 750,
    slider: SliderSplitHisense,
    shortDesc:
      "- Wi-Fi Ready -NIonization filter - 3 Antibacterial filters  - Eco-friendly coolant R32 - i-feel & Smart Function - Antimicrobial coating on the inner wing - Automatic restart in case of power failure - Silent Mode 19db - Automatic operation at 8⁰C, fast cooling",
    description:
      "- Wi-Fi Ready -NIonization filter - 3 Antibacterial filters  - Eco-friendly coolant R32 - i-feel & Smart Function - Antimicrobial coating on the inner wing - Automatic restart in case of power failure - Silent Mode 19db - Automatic operation at 8⁰C, fast cooling",
    reviews: [
      {
        rating: 4.7,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "174",
    productName: "CASSETTE 12000 BTU",
    imgUrl: productImg174,
    category: "Hisense climatiseur",
    price: 1000,
    slider: SliderSplitHisense,
    shortDesc:
      "- Capacity Cooling: 5275 W - Power Input Cooling: 1875 W - Rated Current Cooling: 9 A - EER (Btu/H.w(W/W)): 2.81 - Air Flow Indoor: 800 M3/H - Noise Level Indoor (H/L) DB(A): 46/35 - Noise Level Outdoor DB(A): 55",
    description:
      "- Capacity Cooling: 5275 W - Power Input Cooling: 1875 W - Rated Current Cooling: 9 A - EER (Btu/H.w(W/W)): 2.81 - Air Flow Indoor: 800 M3/H - Noise Level Indoor (H/L) DB(A): 46/35 - Noise Level Outdoor DB(A): 55",
    reviews: [
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "175",
    productName: "CASSETTE 18000 BTU",
    imgUrl: productImg175,
    category: "Hisense climatiseur",
    price: 1250,
    slider: SliderSplitHisense,
    shortDesc:
      "- Capacity Cooling: 5275 W - Power Input Cooling: 1875 W - Rated Current Cooling: 9 A - EER (Btu/H.w(W/W)): 2.81 - Air Flow Indoor: 800 M3/H - Noise Level Indoor (H/L) DB(A): 46/35 - Noise Level Outdoor DB(A): 55",
    description:
      "- Capacity Cooling: 5275 W - Power Input Cooling: 1875 W - Rated Current Cooling: 9 A - EER (Btu/H.w(W/W)): 2.81 - Air Flow Indoor: 800 M3/H - Noise Level Indoor (H/L) DB(A): 46/35 - Noise Level Outdoor DB(A): 55",
    reviews: [
      {
        rating: 4.6,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.9,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },
  //ajout
  {
    id: "175",
    productName: "CASSETTE 24000 BTU",
    imgUrl: productImg175,
    category: "Hisense climatiseur",
    price: 1600,
    slider: SliderSplitHisense,
    shortDesc:
      "- Capacity Cooling: 5275 W - Power Input Cooling: 1875 W - Rated Current Cooling: 9 A - EER (Btu/H.w(W/W)): 2.81 - Air Flow Indoor: 800 M3/H - Noise Level Indoor (H/L) DB(A): 46/35 - Noise Level Outdoor DB(A): 55",
    description:
      "- Capacity Cooling: 5275 W - Power Input Cooling: 1875 W - Rated Current Cooling: 9 A - EER (Btu/H.w(W/W)): 2.81 - Air Flow Indoor: 800 M3/H - Noise Level Indoor (H/L) DB(A): 46/35 - Noise Level Outdoor DB(A): 55",
    reviews: [
      {
        rating: 4.6,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.9,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },
  {/**  {
    id: "175",
    productName: "CASSETTE 42000 BTU",
    imgUrl: productImg175,
    category: "Hisense climatiseur",
    price: 2900,
    slider: SliderSplitHisense,
    shortDesc:
      "- Capacity Cooling: 5275 W - Power Input Cooling: 1875 W - Rated Current Cooling: 9 A - EER (Btu/H.w(W/W)): 2.81 - Air Flow Indoor: 800 M3/H - Noise Level Indoor (H/L) DB(A): 46/35 - Noise Level Outdoor DB(A): 55",
    description:
      "- Capacity Cooling: 5275 W - Power Input Cooling: 1875 W - Rated Current Cooling: 9 A - EER (Btu/H.w(W/W)): 2.81 - Air Flow Indoor: 800 M3/H - Noise Level Indoor (H/L) DB(A): 46/35 - Noise Level Outdoor DB(A): 55",
    reviews: [
      {
        rating: 4.6,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.9,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  }, */},
  {
    id: "176",
    productName: "CASSETTE 5HP 48000 BTU",
    imgUrl: productImg175,
    category: "Hisense climatiseur",
    price: 3250,
    shortDesc:
      "- Capacity Cooling: 5275 W - Power Input Cooling: 1875 W - Rated Current Cooling: 9 A - EER (Btu/H.w(W/W)): 2.81 - Air Flow Indoor: 800 M3/H - Noise Level Indoor (H/L) DB(A): 46/35 - Noise Level Outdoor DB(A): 55",
    description:
      "- Capacity Cooling: 5275 W - Power Input Cooling: 1875 W - Rated Current Cooling: 9 A - EER (Btu/H.w(W/W)): 2.81 - Air Flow Indoor: 800 M3/H - Noise Level Indoor (H/L) DB(A): 46/35 - Noise Level Outdoor DB(A): 55",
    reviews: [
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.8,
        text: "air conditionné",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "177",
    productName: "FLOOR CEILING 18 000 BTU",
    imgUrl: productImg177,
    category: "Hisense climatiseur",
    price: 1000,
    slider: SliderSplitHisense,
    shortDesc:
      "- 3 Antibacterial filters -NIonization filter  - i-feel & Smart Function , - fast cooling - Antimicrobial coating on the inner wing - Automatic restart in case of power failure - Silent Mode 19db - Eco-friendly coolant R32  - Automatic operation at 8⁰C - Wi-Fi Ready",
    description:
      "- 3 Antibacterial filters -NIonization filter  - i-feel & Smart Function , - fast cooling - Antimicrobial coating on the inner wing - Automatic restart in case of power failure - Silent Mode 19db - Eco-friendly coolant R32  - Automatic operation at 8⁰C - Wi-Fi Ready",
    reviews: [
      {
        rating: 4.7,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.5,
  },

 /* {
    id: "178",
    productName: "FLOOR CEILING 24 000 BTU",
    imgUrl: productImg178,
    category: "Hisense climatiseur",
    price: 1200,
    shortDesc:
      "- 3 Antibacterial filters -NIonization filter  - i-feel & Smart Function , - fast cooling - Antimicrobial coating on the inner wing - Automatic restart in case of power failure - Silent Mode 19db - Eco-friendly coolant R32  - Automatic operation at 8⁰C - Wi-Fi Ready",
    description:
      "- 3 Antibacterial filters -NIonization filter  - i-feel & Smart Function , - fast cooling - Antimicrobial coating on the inner wing - Automatic restart in case of power failure - Silent Mode 19db - Eco-friendly coolant R32  - Automatic operation at 8⁰C - Wi-Fi Ready",
    reviews: [
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.8,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "179",
    productName: "FLOOR STAND 20000 BTU",
    imgUrl: productImg179,
    category: "Hisense climatiseur",
    price: 1200,
    shortDesc:
      "- 3 Horse Power (HP), - Floor AC - Super Cooling, - Gold Fin - Long Distance Air supply - Easy clean design - Unique design blends in with any decor",
    description:
      "- 3 Horse Power (HP), - Floor AC - Super Cooling, - Gold Fin - Long Distance Air supply - Easy clean design - Unique design blends in with any decor",
    reviews: [
      {
        rating: 4.6,
        text: "Une qualité supérieure",
      },
      {
        rating: 4.9,
        text: "Une qualité supérieure",
      },
    ],
    avgRating: 4.7,
  },{
    id: "180",
    productName: "STAND 24000 BTU INVERTER",
    imgUrl: productImg180,
    category: "Hisense climatiseur",
    price: 1500,
    slider: SliderSplitHisense,
    shortDesc:
      "- 3 Horse Power (HP), - Floor AC - Super Cooling, - Gold Fin - Long Distance Air supply - Easy clean design - Unique design blends in with any decor",
    description:
      "- 3 Horse Power (HP), - Floor AC - Super Cooling, - Gold Fin - Long Distance Air supply - Easy clean design - Unique design blends in with any decor",
    reviews: [
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },*/
 {
    id: "181",
    productName: "FLOOR STAND 20000 BTU",
    imgUrl: productImg180,
    category: "Hisense climatiseur",
    price: 1200,
    shortDesc:
      "- 3 Horse Power (HP), - Floor AC - Super Cooling, - Gold Fin - Long Distance Air supply - Easy clean design - Unique design blends in with any decor",
    description:
      "- 3 Horse Power (HP), - Floor AC - Super Cooling, - Gold Fin - Long Distance Air supply - Easy clean design - Unique design blends in with any decor",
    reviews: [
      {
        rating: 4.7,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "182",
    productName: "STAND iNVERTER 30 000 BTU",
    imgUrl: productImg182,
    category: "Hisense climatiseur",
    price: 1500,
    slider: SliderSplitHisense,
    shortDesc:
      "- 3 Horse Power (HP), - Floor AC - Super Cooling, - Gold Fin - Long Distance Air supply - Easy clean design - Unique design blends in with any decor",
    description:
      "- 3 Horse Power (HP), - Floor AC - Super Cooling, - Gold Fin - Long Distance Air supply - Easy clean design - Unique design blends in with any decor",
    reviews: [
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },

 {
    id: "183",
    productName: "FLOOR STAND 50000 BTU",
    imgUrl: productImg182,
    category: "Hisense climatiseur",
    price: 2200,
    shortDesc:
      "- 3 Horse Power (HP), - Floor AC - Super Cooling, - Gold Fin - Long Distance Air supply - Easy clean design - Unique design blends in with any decor",
    description:
      "- 3 Horse Power (HP), - Floor AC - Super Cooling, - Gold Fin - Long Distance Air supply - Easy clean design - Unique design blends in with any decor",
    reviews: [
      {
        rating: 4.6,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.9,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },
  
  {
    id: "184",
    productName: "FLOOR STAND 100 000 BTU",
    imgUrl: productImg182,
    category: "Hisense climatiseur",
    price: 7500,
    shortDesc:
      "- 3 Horse Power (HP), - Floor AC - Super Cooling, - Gold Fin - Long Distance Air supply - Easy clean design - Unique design blends in with any decor",
    description:
      "- 3 Horse Power (HP), - Floor AC - Super Cooling, - Gold Fin - Long Distance Air supply - Easy clean design - Unique design blends in with any decor",
    reviews: [
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "185",
    productName: "TV 32'' 32A5200 / LED TV",
    imgUrl: productImg185,
    category: "Hisense tv",
    price: 125,
 
    slider: SliderTvHisense,
    shortDesc:
      "",
    description:
      "Résolution : 1366 x 768 Fréquence de rafraîchissement de l'image : 60 Hz 120 Taux de mouvement fluide Profondeur de couleur : 8 bits Luminosité maximale du blanc : 300 cd/m2 Rapport de contraste 3000:1 178 Angle de vision Entrée RF Entrée AV Interface USB type 2.0 (USB 1, USB2) Sortie audio numérique optique (SPDIF) Prise casque 2 x entrées HDMI",
    reviews: [
      {
        rating: 4.7,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.5,
  },

 {
    id: "186",
    productName: "32'' 32A4G SMART A4 SERIES",
    imgUrl: productImg186,
    category: "Hisense tv",
    price: 200,
    slider: SliderTvHisense,
    shortDesc:
      "Full HD LED TV. - 2x HDMI inputs. - 1x USB input. - Resolution : 1366 x 768",
    description:
      "Full HD LED TV. - 2x HDMI inputs. - 1x USB input. - Resolution : 1366 x 768",
    reviews: [
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "187",
    productName: "TV 40'' 40A4G SMART A4 SERIES",
    imgUrl: productImg187,
    category: "Hisense tv",
    price: 225,
    slider: SliderTvHisense,
    shortDesc:
      "OS: VIDAA U4 - HDMI inputs: 2 - USB slots: 2 - Dolby Audio - Alexa built-in: - Processor: Quad-core - Sound output: 2x6W - Remote One-Touch Access: Yes",
    description:
      "OS: VIDAA U4 - HDMI inputs: 2 - USB slots: 2 - Dolby Audio - Alexa built-in: - Processor: Quad-core - Sound output: 2x6W - Remote One-Touch Access: Yes",
    reviews: [
      {
        rating: 4.6,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.9,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },
 
  /*{
    id: "188",
    productName: "TV 43'' 43A5200",
    imgUrl: productImg188,
    category: "Hisense tv",
    price: 225,
    shortDesc:
      "Full HD LED TV. - 2x HDMI inputs. - 1x USB input. - Resolution : 1366 x 768",
    description:
      "Full HD LED TV. - 2x HDMI inputs. - 1x USB input. - Resolution : 1366 x 768",
    reviews: [
      {
        rating: 4.7,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.5,
  },{
    id: "189",
    productName: "TV 43'' 43A4G SMART A4 SERIES ",
    imgUrl: productImg189,
    category: "Hisense tv",
    price: 290,
    slider: SliderTvHisense,
    shortDesc:
      "OS: VIDAA U4 - HDMI inputs: 2 - USB slots: 2 - Dolby Audio - Alexa built-in: - Processor: Quad-core - Sound output: 2x6W - Remote One-Touch Access: Yes",
    description:
      "OS: VIDAA U4 - HDMI inputs: 2 - USB slots: 2 - Dolby Audio - Alexa built-in: - Processor: Quad-core - Sound output: 2x6W - Remote One-Touch Access: Yes",
    reviews: [
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "190",
    productName: "50'' 50A6GS SMART A4 SERIES",
    imgUrl: productImg190,
    category: "Hisense tv",
    price: 390,
    slider: SliderTvHisense,
    shortDesc:
      "Taille (en pouces) : 50 - Résolution : 4K Ultra HD - Effet surround : Oui - Compatibilité HDR : Dolby Vision, HDR10+, HDR 10, Hybrid Log Gamma (HLG) - Compatible Mode Jeu Auto (ALLM) : Oui",
    description:
      "Taille (en pouces) : 50 - Résolution : 4K Ultra HD - Effet surround : Oui - Compatibilité HDR : Dolby Vision, HDR10+, HDR 10, Hybrid Log Gamma (HLG) - Compatible Mode Jeu Auto (ALLM) : Oui -LCD TV with LED and DLED backlight-Ultra HD TV (4K): 3840 x 2160-Fluidity: 50Hz panel-Ultra Dimming • Tuner: DTT • Satellite • Cable-Smart TV: VIDAA • Anyview Stream-Connected TV: Internet Browser-Wi-Fi • Bluetooth • DLNA-Assistants: Google • Vidaa Voice • Alexa",
    reviews: [
      {
        rating: 4.6,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.9,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "191",
    productName: "55A6 SMART TV 4K",
    imgUrl: productImg191,
    category: "Hisense tv",
    price: 450,
    slider: SliderTvHisense,
    shortDesc:
      "Taille (en pouces) : 55 - Résolution : 4K Ultra HD - Effet surround : Oui - Compatibilité HDR : Dolby Vision, HDR10+, HDR 10, Hybrid Log Gamma (HLG) - Compatible Mode Jeu Auto (ALLM) : Oui",
    description:
      "Taille (en pouces) : 55 - Résolution : 4K Ultra HD - Effet surround : Oui - Compatibilité HDR : Dolby Vision, HDR10+, HDR 10, Hybrid Log Gamma (HLG) - Compatible Mode Jeu Auto (ALLM) : Oui - LCD TV with LED and DLED backlight-Ultra HD TV (4K): 3840 x 2160-Fluidity: 50Hz panel-Ultra Dimming • Tuner: DTT • Satellite • Cable-Smart TV: VIDAA • Anyview Stream-Connected TV: Internet Browser-Wi-Fi • Bluetooth • DLNA-Assistants: Google • Vidaa Voice • Alexa",
    reviews: [
      {
        rating: 4.7,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "192",
    productName: "TV 55U6 ULED TV",
    imgUrl: productImg192,
    category: "Hisense tv",
    price: 650,
    slider: SliderTvHisense,
    shortDesc:
      "dimensions du produit - Dimension du produit (sans support) - 48,4 × 28,1 × 3,0 pouces - Dimension du produit (avec support) - 48,4 × 30,4 × 12,0 pouces - Poids (avec support) - 31,3 livres - Dimensions du colis (LxlxH) - 53,6 × 32,7 × 6,0 pouces - Poids du paquet - 42,3 livres - Poids (sans support) - 30,6 livres - Afficher - Taille réelle de l'écran (mesurée en diagonale) 54,6''",
    description:
      "Nous apportons Quantum Dot Color aux gens. Profitez de plus d'un milliard de couleurs, sans vous soucier du nombre de virgules figurant sur l'étiquette de prix. Vous êtes les bienven Le U6H, d’un excellent rapport qualité-prix, rend également beaucoup plus de technologies haut de gamme facilement disponibles. Comme la résolution 4K, Dolby Vision HDR et les zones de gradation locale Full Array. Cela ressemble à de la science, mais cela ressemble à un téléviseur bien plus cher.",
    reviews: [
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "193",
    productName: "TV 58A6 SMART TV 4K",
    imgUrl: productImg193,
    category: "Hisense tv",
    price: 500,
    slider: SliderTvHisense,
    shortDesc:
      "Upscaleur IA UHD Couleur de précision Dolby Vision DTS virtuel X Mode sport Wi-Fi double bande",
    description:
      " - Produit	 - Modèle d'usine	58A53FXVT - Usine	Qingdao - Description	Télévision LED directe 146 cm (58″) - Document	 - Version du formulaire de spécifications	HA220120 - Circulation	Usage interne restreint uniquement - Statut	Confirmé - Numéro de révision	1 - Date	27/01/2022 - Délivré par	Al - Affichage des images	 - Fabricant de panneaux	Hisense - Modèle de panneau	LCM : HD580Y1U92-LA, cellule ouverte : CV580U1-T01 V02 - Zone d'affichage active, dimension horizontale	1270,08 mm - Zone d'affichage active, dimension verticale	721,44 mm - Taille de l'écran (arrondie à l'entier le plus proche)	146 cm - Taille de l'écran (arrondie à l'entier le plus proche)	58 pouces - Technologie d'affichage	LCD/LED allumé",
    reviews: [
      {
        rating: 4.6,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.9,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "194",
    productName: "TV 65A6 SMART TV 4K",
    imgUrl: productImg194,
    category: "Hisense tv",
    price: 650,
    slider: SliderTvHisense,
    shortDesc:
      "Hisense Série A6 4K UHD Smart Google TV 65 pouces avec compatibilité Alexa, Dolby Vision HDR, DTS Virtual X, modes sport et jeu, télécommande vocale, Chromecast intégré (65A6H), noir",
    description:
      "Ultra haute définition 4K : plus de pixels sur l'écran produisent une image plus claire et plus succincte, ce qui rend le contenu plus réaliste et plus fidèle à la réalité. Combiné avec l'AI UHD Upscaler de Hisense, le contenu non 4K sera amélioré jusqu'à une qualité proche de la 4K. Google TV : le divertissement que vous aimez. Avec un peu d'aide de Google. Google TV rassemble des films, des émissions, des émissions de télévision en direct et bien plus encore à partir de vos applications et de vos abonnements, et les organise rien que pour vous. Dolby Vision HDR et HDR10 : la technologie visuelle innovante, Dolby Vision, donne vie aux personnages avec des couleurs plus sombres, des couleurs plus vives et une gamme de couleurs remarquable que vous ne pouvez obtenir nulle part ailleurs. HDR10 est un format HDR qui fournit du contenu HDR via des signaux hertziens compatibles.",
    reviews: [
      {
        rating: 4.7,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.5,
  }, {
    id: "195",
    productName: "TV 65U6 ULED TV",
    imgUrl: productImg195,
    category: "Hisense tv",
    price: 1000,
    slider: SliderTvHisense,
    shortDesc:
      "Hisense Classe U6 Série Mini-LED ULED 4K UHD Google Smart TV (65U6K) – QLED, Full Array Local Dimming, Dolby Vision IQ, HDR 10+, VRR Game Mode Plus, 240 Motion Rate, Compatibilité Alexa",
    description:
      "ULED 4K : les technologies ULED exclusives à Hisense améliorent la couleur, le contraste, la luminosité et le mouvement. Cette suite de technologies matérielles et logicielles brevetées offre une image attrayante et totalement unique qui ne peut être fournie que par Hisense. Mini-LED et gradation locale complète : Mini-LED est la dernière avancée en matière de technologie de rétroéclairage. En utilisant des LED nettement plus petites que les LED traditionnelles, nous sommes en mesure d'utiliser beaucoup plus de LED pour créer une image lumineuse qui répartit la lumière plus uniformément pour une image uniforme sur l'écran. Large gamme de couleurs QLED QUANTUM DOT : Quantum Dot QLED produit des couleurs plus pures, plus riches, plus brillantes et plus précises qu'un téléviseur LED ordinaire. Création de plus d'un milliard de combinaisons de couleurs qui donnent vie à des images éclatantes d'une manière que les téléviseurs non QLED ne peuvent pas",
    reviews: [
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },{
    id: "197",
    productName: "ULED TV 65'' 65U6K-PRO WITH DOLBY ATMOS",
    imgUrl: productImg197,
    category: "Hisense tv",
    price: 900,
    slider: SliderTvHisense,
    shortDesc:
      "Hisense Série U6 Mini-LED ULED 4K UHD Smart TV 65 pouces (65U6KPRO, modèle 2024) – QLED, natif 144 Hz, 1000 nits, Dolby Vision IQ, gradation locale complète, mode jeu Pro, compatibilité Alexa",
    description:
      "ULED 4K : les technologies ULED exclusives à Hisense améliorent la couleur, le contraste, la luminosité et le mouvement. Cette suite de technologies matérielles et logicielles brevetées offre une image attrayante et totalement unique qui ne peut être fournie que par Hisense. Mini-LED et gradation locale complète : Mini-LED est la dernière avancée en matière de technologie de rétroéclairage. En utilisant des LED nettement plus petites que les LED traditionnelles, nous sommes en mesure d'utiliser beaucoup plus de LED pour créer une image lumineuse qui répartit la lumière plus uniformément pour une image uniforme sur l'écran. Large gamme de couleurs QLED QUANTUM DOT : Quantum Dot QLED produit des couleurs plus pures, plus riches, plus brillantes et plus précises qu'un téléviseur LED ordinaire. Création de plus d'un milliard de combinaisons de couleurs qui donnent vie à des images éclatantes d'une manière que les téléviseurs non QLED ne peuvent pas",
    reviews: [
      {
        rating: 4.7,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.5,
  },

*/

  

 

  {
    id: "196",
    productName: "TV 65U7 ULED TV",
    imgUrl: productImg196,
    category: "Hisense tv",
    price: 1000,
    slider: SliderTvHisense,
    shortDesc:
      "Hisense Classe U7 Series Mini-LED ULED 4K UHD Google Smart TV (65U7K) – QLED, natif 144 Hz, 1000 nits, Dolby Vision IQ, gradation locale complète, mode jeu Pro, compatibilité Alexa, 65U7K",
    description:
      "ULED 4K : les technologies ULED exclusives à Hisense améliorent la couleur, le contraste, la luminosité et le mouvement. Cette suite de technologies matérielles et logicielles brevetées offre une image attrayante et totalement unique qui ne peut être fournie que par Hisense. Mini-LED et gradation locale complète : Mini-LED est la dernière avancée en matière de technologie de rétroéclairage. En utilisant des LED nettement plus petites que les LED traditionnelles, nous sommes en mesure d'utiliser beaucoup plus de LED pour créer une image lumineuse qui répartit la lumière plus uniformément pour une image uniforme sur l'écran. Large gamme de couleurs QLED QUANTUM DOT : Quantum Dot QLED produit des couleurs plus pures, plus riches, plus brillantes et plus précises qu'un téléviseur LED ordinaire. Création de plus d'un milliard de combinaisons de couleurs qui donnent vie à des images éclatantes d'une manière que les téléviseurs non QLED ne peuvent pas",
    reviews: [
      {
        rating: 4.6,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.9,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },

  
  {
    id: "198",
    productName: "TV 70A6 SMART TV 4K",
    imgUrl: productImg198,
    category: "Hisense tv",
    price: 1000,
    slider: SliderTvHisense,
    shortDesc:
      "Téléviseur intelligent Google TV LED 4K UHD Hisense série A6 de 70 po de classe A6",
    description:
      "dimensions du produit - Dimension du produit (sans support) - 62,1 × 34,8 × 3,3 pouces (L x L x H) - Dimension du produit (avec support) - 62,1 × 37,9 × 13,4 pouces - Poids (avec support) - 47,4 livres - Dimensions du colis (LxlxH) - 69,5 × 8,0 × 42,3 pouces - Poids du paquet - 67 livres - Poids (sans support) - 46,3 livres - Afficher - Taille réelle de l'écran (mesurée en diagonale) - 69,5 pouces - Type d'écran - Écran LCD - Classe - Classe 70'' - Type de téléviseur - Smart TV - Oui - Fonctionne avec Alexa - Oui - Assistant Google intégré - Oui - Magasin d'applications - Oui (Google Play App Store) - Qualité de l'image - Résolution d'écran - 3840x2160p - Gradation locale - Non - Taux de mouvement - 120 - Format d'affichage - 16:9 - Large gamme de couleurs - Non - Compatible HDR - Dolby Vision HDR et HDR10 - MEMC - Oui - Fréquence de rafraîchissement - 60 Hz - Type de résolution - 4K - l'audio - Puissance de sortie audio (watts) - 15 W x 2",
    reviews: [
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "199",
    productName: "TV 75A7 SMART TV 4K",
    imgUrl: productImg199,
    category: "Hisense tv",
    price: 1300,
    slider: SliderTvHisense,
    shortDesc:
      "Taille de l'écran : 75 pouces - Résolution : 3840x2160P - Entrées HDMI :3 - Ports USB : 2 - Système d'exploitation : VIDAA U5 - Taux de mouvement fluide : 120 - Télécommande vocale Bluetooth - Puissance de sortie audio : 10 W - Dolby Atmos - Processeur : Quad-core - Classe énergétique : 5,5",
    description:
      "Taille de l'écran : 75 pouces - Résolution : 3840x2160P - Entrées HDMI :3 - Ports USB : 2 - Système d'exploitation : VIDAA U5 - Taux de mouvement fluide : 120 - Télécommande vocale Bluetooth - Puissance de sortie audio : 10 W - Dolby Atmos - Processeur : Quad-core - Classe énergétique : 5,5",
    reviews: [
      {
        rating: 4.6,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.9,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },

  {/**{
    id: "200",
    productName: "ULED TV 75'' 75U6K-PRO WITH DOLBY ATMOS",
    imgUrl: productImg200,
    category: "Hisense tv",
    price: 1400,
    slider: SliderTvHisense,
    shortDesc:
      "Hisense 75'' Classe U6 Série Mini-LED ULED 4K UHD Google Smart TV (75U6K) – QLED, Full Array Local Dimming, Dolby Vision IQ, HDR 10+, VRR Game Mode Plus, 240 Motion Rate, Compatibilité Alexa",
    description:
      "ULED 4K : les technologies ULED exclusives à Hisense améliorent la couleur, le contraste, la luminosité et le mouvement. Cette suite de technologies matérielles et logicielles brevetées offre une image attrayante et totalement unique qui ne peut être fournie que par Hisense. Mini-LED et gradation locale complète : Mini-LED est la dernière avancée en matière de technologie de rétroéclairage. En utilisant des LED nettement plus petites que les LED traditionnelles, nous sommes en mesure d'utiliser beaucoup plus de LED pour créer une image lumineuse qui répartit la lumière plus uniformément pour une image uniforme sur l'écran. Large gamme de couleurs QLED QUANTUM DOT : Quantum Dot QLED produit des couleurs plus pures, plus riches, plus brillantes et plus précises qu'un téléviseur LED ordinaire. Création de plus d'un milliard de combinaisons de couleurs qui donnent vie à des images éclatantes d'une manière que les téléviseurs non QLED ne peuvent pas",
    reviews: [
      {
        rating: 4.7,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.5,
  },
 */},
  {
    id: "201",
    productName: "TV 85A7 SMART TV 4K",
    imgUrl: productImg201,
    category: "Hisense tv",
    price: 1800,
    slider: SliderTvHisense,
    shortDesc:
      "Téléviseur intelligent Google TV LED 4K UHD Hisense série A7 de 85 pouces",
    description:
      "dimensions du produit - Dimension du produit (sans support) - 74,9 × 42,8 × 3,5 pouces » (L x L x H) - Dimension du produit (avec support) - 74,9 × 46,6 × 18,7 pouces - Poids (avec support) - 95,7 livres - Dimensions du colis (LxlxH) - 82,4 × 10,6 × 50,3 pouces - Poids du paquet - 133,2 livres - Poids (sans support) - 90,4 livres - Afficher - Taille réelle de l'écran (mesurée en diagonale) '' - 85'' pouces - Type d'écran - Écran LCD - Classe - Classe 85'' - Type de téléviseur - Smart TV - Oui - Fonctionne avec Alexa - Oui - Assistant Google intégré - Oui - Magasin d'applications - Oui (Google Play App Store) - Qualité de l'image - Résolution d'écran - 3840x2160p - Gradation locale - Non - Taux de mouvement - 120 - Format d'affichage - 16:9 - Large gamme de couleurs - Oui - Compatible HDR - Dolby Vision HDR et HDR10 - Fréquence de rafraîchissement - 60 Hz - Type de résolution - 4K - l'audio - Puissance de sortie audio (watts) - 15 W x 2",
    reviews: [
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "202",
    productName: "85'' 85U7H ULTRA HD",
    imgUrl: productImg202,
    category: "Hisense tv",
    price: 2200,
    slider: SliderTvHisense,
    shortDesc:
      "L'U7H apporte la couleur Quantum Dot et la technologie haut de gamme au grand public. Profitez de plus d'un milliard de couleurs, d'une résolution 4K, d'une luminosité maximale de 1 000 nits, du Dolby Vision HDR et du son Dolby Atmos. Une technologie auparavant réservée à quelques-uns est désormais accessible à tous. Vous êtes les bienvenus.",
    description:
      "dimensions du produit - Dimension du produit (sans support) - 74,9 × 46,1 × 17,8 pouces - Dimension du produit (avec support) - 74,9 × 43,1 × 3,7 pouces - Poids (avec support) - 92,6 livres - Dimensions du colis (LxlxH) - 83,5 × 50,3 × 11,0 pouces - Poids du paquet - 133,2 livres - Poids (sans support) - 90,4 livres - Afficher - Taille réelle de l'écran (mesurée en diagonale) '' - 84,5 pouces - Type d'écran - Écran LCD - Classe - Classe 85'' - Type de téléviseur - Smart TV - Oui - Fonctionne avec Alexa - Oui - Navigateur Web - Oui - Assistant Google intégré - Oui - Magasin d'applications - Oui (Google Play App Store) - Qualité de l'image - Résolution d'écran - 3840x2160p - Gradation locale - Oui - Mise à l'échelle 4K - Oui - Taux de mouvement - 480 - Format d'affichage - 16:9 - Type de rétroéclairage - Gamme complète - Niveau de luminosité - jusqu'à 1000 nits de luminosité maximale - Large gamme de couleurs - Oui, point quantique - Compatible HDR - Oui (Dolby Vision/HDR10/HDR10+/HLG) - MEMC - Oui - l'audio - Puissance de sortie audio (watts) - 15 W x 2",
    reviews: [
      {
        rating: 4.6,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.9,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },

 {/** {
    id: "203",
    productName: "85'' UHD 85A6KS SMART TV",
    imgUrl: productImg203,
    category: "Hisense tv",
    price: 1300,
    slider: SliderTvHisense,
    shortDesc:
      "Hisense Smart TV 85 pouces 4K UHD LCD Dolby Vision, optimiseur de couleurs naturelles, système d'exploitation VIDAA U5, Youtube, Netflix, Disney +, Freeview Play et WiFi, modèle (85A6KS)",
    description:
      "Conception sans cadre pour une expérience visuelle immersive - Résolution 4K Ultra HD pour une qualité d'image époustouflante - Fonctionnalités Smart TV avec Google TV pour le streaming - Écran LED de haute qualité pour des couleurs éclatantes et une clarté - Plusieurs options de connectivité pour un divertissement polyvalent",
    reviews: [
      {
        rating: 4.7,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.5,
  }, */},

  {
    id: "204",
    productName: "98'' 98U7H QUANTUM LEDSMART TV",
    imgUrl: productImg204,
    category: "Hisense tv",
    price: 4500,
    slider: SliderTvHisense,
    shortDesc:
      "Téléviseur Hisense 98U7H MÉGA ULED 4K - Gradation locale à matrice complète Pro - Mode jeu Pro 120 Hz - Couleur du point quantique - Dolby Vision IQ - HDR10+ adaptatif - Télécommande vocale mains libres",
    description:
      "Téléviseur Hisense 98U7H MÉGA ULED 4K - Gradation locale à matrice complète Pro - Mode jeu Pro 120 Hz - Couleur du point quantique - Dolby Vision IQ - HDR10+ adaptatif - Télécommande vocale mains libres",
    reviews: [
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.8,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },

  {/**{
    id: "205",
    productName: "100'' 4K 100U7K MINI-LED U7",
    imgUrl: productImg205,
    category: "Hisense tv",
    price: 5000,
    slider: SliderTvHisense,
    shortDesc:
      "Marque Hisense - Nom du modèle 100UK - Taille de l'écran 100 pouces - Services Internet pris en charge - Technologie d'affichage QLED - Résolution 4K - Taux de rafraîchissement 120 Hz - Fonctionnalité spéciale Mode de jeu, Assistance vocale multiple, Gradation locale, Large rehausseur de couleurs",
    description:
      "Spécifications et fonctions - Marques	 - Hisense - Détails techniques - Marque	‎Hisense - Poids de l'article	‎120,7 livres - dimensions du produit	‎3 x 65,9 x 38,1 pouces - Numéro de modèle de l'article	‎100U7K - Batteries	‎2 piles AAA requises. (inclus) - Nom de la couleur	‎Noir - Fonctionnalités spéciales	‎Mode jeu, assistance vocale multiple, gradation locale, amélioration des couleurs étendues - Taille d'affichage de l'écran debout	‎100 pouces - Tension	‎120 Volts - Puissance	‎330 watts",
    reviews: [
      {
        rating: 4.6,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.9,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },
 */},
  {
    id: "206",
    productName: "85'' UX 4K 144HZ QUANTOM DOT DOLBY ",
    imgUrl: productImg206,
    category: "Hisense tv",
    price: 5000,
    slider: SliderTvHisense,
    shortDesc:
      "Hisense 85U78KM - Google TV mini-LED 4K ULED 144 Hz de 85 po avec moteur Hi-View de la technologie Quantum Dot, Dolby Vision Atmos, téléviseur intelligent amélioré IMAX avec HDR10, HDRI0+, HLG (modèle canadien) 2023",
    description:
      "Mini LED : des LED plus petites permettent d'en intégrer davantage dans notre technologie de rétroéclairage. Le nombre accru de LED offre une dispersion de lumière plus élevée et plus large qui émet jusqu'à 1 000 nits de luminosité maximale pour une image HDR plus lumineuse et plus vibrante. Gradation locale à matrice complète : en augmentant le nombre de mini-LED dans le rétroéclairage, combiné au processeur vidéo de pointe d'Hisense pour contrôler les 648 zones de gradation locale, le contraste, les détails et le rendu des couleurs sont considérablement améliorés. Large gamme de couleurs Quantum Dot : reproduit plus d'un milliard de combinaisons de couleurs pour un spectre de couleurs plus large pour des images fidèles à la réalité.",
    reviews: [
      {
        rating: 4.6,
        text: "les images vraies",
      },
      {
        rating: 4.9,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },

   {
    id: "207",
    productName: "PX1 LASER PROJECTOR ANDROID SYSTEM",
    imgUrl: productImg207,
    category: "Hisense tv",
    price: 3000,
    slider: SliderTvHisense,
    shortDesc:
      "Projecteur Hisense PX1-PRO 4K UHD triple laser UST à focale ultra courte, 2 200 lumens, Android TV, HDR10, 30 W (stéréo) Dolby Atmos, Dolby Vision, Alexa et Google Assistant intégrés",
    description:
      "Le projecteur PX1-PRO UST est doté d'un moteur laser TriChroma pour obtenir une couverture complète de l'espace colorimétrique BT.2020 pour une image réaliste ; il offre également une image 4K d'une netteté exceptionnelle de 90 à 130 pouces, eARC pour un son sans perte, le mode Filmmaker et des intégrations pour la maison intelligente. - TriChroma est vraiment magnifique en R, G, B. L'UST à triple laser PX1-PRO utilise des lasers rouge, vert et bleu purs pour atteindre de nouveaux niveaux de performances de couleur, atteignant 107 % de l'espace colorimétrique BT.2020, c'est une couleur que vous n'avez jamais vue sur un écran auparavant. - Préparez-vous à passer une soirée cinéma, votre prochaine frénésie de streaming ou à jouer avec les garçons à un tout autre niveau. Avec la mise au point variable, le projecteur à focale ultra courte PX1-PRO peut projeter des images de 90 à 130 pouces avec une mise au point d'une netteté exceptionnelle pour une immersion visuelle maximale. - La 4K Ultra HD apporte plus de 8 millions de pixels pour créer une clarté incroyable sur une projection extra-large. La plage dynamique élevée élargit les couleurs et le contraste afin que vous puissiez profiter d'une image incroyablement vibrante avec une profondeur et des détails incroyables. - Avec une luminosité maximale de 2 200 lumens, le PX1-PRO brille avec éclat afin que tout ce que vous regardez restitue ces reflets étincelants, ces couleurs volumineuses et ces noirs profonds. Le projecteur PX1-PRO UST est parfait pour le home cinéma, que ce soit dans la salle multimédia ou dans une salle de cinéma dédiée.",
    reviews: [
      {
        rating: 4.6,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.9,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },

   {
    id: "20711",
    productName: "LASER PROJECTOR TV 60'' TO 300''",
    imgUrl: productImg207,
    category: "Hisense tv",
    price: 2500,
    slider: SliderTvHisense,
    shortDesc:
      "Projecteur Hisense PX1-PRO 4K UHD triple laser UST à focale ultra courte, 2 200 lumens, Android TV, HDR10, 30 W (stéréo) Dolby Atmos, Dolby Vision, Alexa et Google Assistant intégrés",
    description:
      "Le projecteur PX1-PRO UST est doté d'un moteur laser TriChroma pour obtenir une couverture complète de l'espace colorimétrique BT.2020 pour une image réaliste ; il offre également une image 4K d'une netteté exceptionnelle de 90 à 130 pouces, eARC pour un son sans perte, le mode Filmmaker et des intégrations pour la maison intelligente. - TriChroma est vraiment magnifique en R, G, B. L'UST à triple laser PX1-PRO utilise des lasers rouge, vert et bleu purs pour atteindre de nouveaux niveaux de performances de couleur, atteignant 107 % de l'espace colorimétrique BT.2020, c'est une couleur que vous n'avez jamais vue sur un écran auparavant. - Préparez-vous à passer une soirée cinéma, votre prochaine frénésie de streaming ou à jouer avec les garçons à un tout autre niveau. Avec la mise au point variable, le projecteur à focale ultra courte PX1-PRO peut projeter des images de 90 à 130 pouces avec une mise au point d'une netteté exceptionnelle pour une immersion visuelle maximale. - La 4K Ultra HD apporte plus de 8 millions de pixels pour créer une clarté incroyable sur une projection extra-large. La plage dynamique élevée élargit les couleurs et le contraste afin que vous puissiez profiter d'une image incroyablement vibrante avec une profondeur et des détails incroyables. - Avec une luminosité maximale de 2 200 lumens, le PX1-PRO brille avec éclat afin que tout ce que vous regardez restitue ces reflets étincelants, ces couleurs volumineuses et ces noirs profonds. Le projecteur PX1-PRO UST est parfait pour le home cinéma, que ce soit dans la salle multimédia ou dans une salle de cinéma dédiée.",
    reviews: [
      {
        rating: 4.6,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.9,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "20712",
    productName: "LASER TV 100L5",
    imgUrl: productImg207,
    category: "Hisense tv",
    price: 5000,
    slider: SliderTvHisense,
    shortDesc:
      "Projecteur Hisense PX1-PRO 4K UHD triple laser UST à focale ultra courte, 2 200 lumens, Android TV, HDR10, 30 W (stéréo) Dolby Atmos, Dolby Vision, Alexa et Google Assistant intégrés",
    description:
      "Le projecteur PX1-PRO UST est doté d'un moteur laser TriChroma pour obtenir une couverture complète de l'espace colorimétrique BT.2020 pour une image réaliste ; il offre également une image 4K d'une netteté exceptionnelle de 90 à 130 pouces, eARC pour un son sans perte, le mode Filmmaker et des intégrations pour la maison intelligente. - TriChroma est vraiment magnifique en R, G, B. L'UST à triple laser PX1-PRO utilise des lasers rouge, vert et bleu purs pour atteindre de nouveaux niveaux de performances de couleur, atteignant 107 % de l'espace colorimétrique BT.2020, c'est une couleur que vous n'avez jamais vue sur un écran auparavant. - Préparez-vous à passer une soirée cinéma, votre prochaine frénésie de streaming ou à jouer avec les garçons à un tout autre niveau. Avec la mise au point variable, le projecteur à focale ultra courte PX1-PRO peut projeter des images de 90 à 130 pouces avec une mise au point d'une netteté exceptionnelle pour une immersion visuelle maximale. - La 4K Ultra HD apporte plus de 8 millions de pixels pour créer une clarté incroyable sur une projection extra-large. La plage dynamique élevée élargit les couleurs et le contraste afin que vous puissiez profiter d'une image incroyablement vibrante avec une profondeur et des détails incroyables. - Avec une luminosité maximale de 2 200 lumens, le PX1-PRO brille avec éclat afin que tout ce que vous regardez restitue ces reflets étincelants, ces couleurs volumineuses et ces noirs profonds. Le projecteur PX1-PRO UST est parfait pour le home cinéma, que ce soit dans la salle multimédia ou dans une salle de cinéma dédiée.",
    reviews: [
      {
        rating: 4.6,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.9,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "214",
    productName: "CORREDO CUISINIERE 4 PLAQUES CERAMIQUES AVEC FOUR CO-60 60X60 GREY COLOR",
    imgUrl: productImg214,
    category: "corredo",
    price: 700,
    slider: '',
    shortDesc:
      " 3 Colours available: Grey, Black and White - 4 Plaques Electric - Oven Electric - Overall dimensions: 50 x 60 x 85 cm (W x D x H)- Material: SteelWith metal lid- With 4 hotplates: 2 x 1000 W + 2 x 1500 W- Oven capacity: 51 l - Oven lamp power: 15 W- With a plate and a grid - Voltage: 400V, 50Hz",
    description:
      " 3 Colours available: Grey, Black and White - 4 Plaques Electric - Oven Electric - Overall dimensions: 50 x 60 x 85 cm (W x D x H)- Material: SteelWith metal lid- With 4 hotplates: 2 x 1000 W + 2 x 1500 W- Oven capacity: 51 l - Oven lamp power: 15 W- With a plate and a grid - Voltage: 400V, 50Hz",
    reviews: [
      {
        rating: 4.6,
        text: "je valide, c'est un bon article",
      },
      {
        rating: 4.9,
        text: "je valide, c'est un bon article",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "215",
    productName: "CORREDO CUISINIERE 4 PLAQUES AVEC FOUR CO-6057",
    imgUrl: productImg215,
    category: "corredo",
    price: 375,
    slider: '',
    shortDesc:
      " 3 Colours available: Grey, Black and White - 4 Plaques Electric - Oven Electric - Overall dimensions: 50 x 60 x 85 cm (W x D x H)- Material: SteelWith metal lid- With 4 hotplates: 2 x 1000 W + 2 x 1500 W- Oven capacity: 51 l - Oven lamp power: 15 W- With a plate and a grid - Voltage: 400V, 50Hz",
    description:
      " 3 Colours available: Grey, Black and White - 4 Plaques Electric - Oven Electric - Overall dimensions: 50 x 60 x 85 cm (W x D x H)- Material: SteelWith metal lid- With 4 hotplates: 2 x 1000 W + 2 x 1500 W- Oven capacity: 51 l - Oven lamp power: 15 W- With a plate and a grid - Voltage: 400V, 50Hz",
    reviews: [
      {
        rating: 4.6,
        text: "je valide",
      },
      {
        rating: 4.9,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },
  //Ajout 2


    {
    id: "21511",
    productName: "CORREDO 60 * 60 GAS 4 BURNERS",
    imgUrl: '',
    category: "corredo",
    price: 290,
    slider: '',
    shortDesc:
      " 3 Colours available: Grey, Black and White - 4 Plaques Electric - Oven Electric - Overall dimensions: 50 x 60 x 85 cm (W x D x H)- Material: SteelWith metal lid- With 4 hotplates: 2 x 1000 W + 2 x 1500 W- Oven capacity: 51 l - Oven lamp power: 15 W- With a plate and a grid - Voltage: 400V, 50Hz",
    description:
      " 3 Colours available: Grey, Black and White - 4 Plaques Electric - Oven Electric - Overall dimensions: 50 x 60 x 85 cm (W x D x H)- Material: SteelWith metal lid- With 4 hotplates: 2 x 1000 W + 2 x 1500 W- Oven capacity: 51 l - Oven lamp power: 15 W- With a plate and a grid - Voltage: 400V, 50Hz",
    reviews: [
      {
        rating: 4.6,
        text: "je valide",
      },
      {
        rating: 4.9,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "21512",
    productName: "CORREDO CUISINIERE 2 PLAQUES ELECTRIQUE + 2 PLAQUES GAZ AVEC FOUR CO-6057",
    imgUrl: productImg215,
    category: "corredo",
    price: 390,
    slider: '',
    shortDesc:
      " 3 Colours available: Grey, Black and White - 4 Plaques Electric - Oven Electric - Overall dimensions: 50 x 60 x 85 cm (W x D x H)- Material: SteelWith metal lid- With 4 hotplates: 2 x 1000 W + 2 x 1500 W- Oven capacity: 51 l - Oven lamp power: 15 W- With a plate and a grid - Voltage: 400V, 50Hz",
    description:
      " 3 Colours available: Grey, Black and White - 4 Plaques Electric - Oven Electric - Overall dimensions: 50 x 60 x 85 cm (W x D x H)- Material: SteelWith metal lid- With 4 hotplates: 2 x 1000 W + 2 x 1500 W- Oven capacity: 51 l - Oven lamp power: 15 W- With a plate and a grid - Voltage: 400V, 50Hz",
    reviews: [
      {
        rating: 4.6,
        text: "je valide",
      },
      {
        rating: 4.9,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "216",
    productName: "CORREDO COOKER 60X90 PROFESSIONAL CERAMIC",
    imgUrl: productImg216,
    category: "corredo",
    price: 1750,
    slider: '',
    shortDesc:
      " Product Dimensions : 104 x 90 x 126 cm- Capacity :110 Liters - - Fuel type : lectric- Installation Type : Free Standing- Burner type : nill - Heating Elements 4- Color grey - Control Console : Knob- Voltage : 240 Volts - Wattage : 2800 watts",
    description:
      " Product Dimensions : 104 x 90 x 126 cm- Capacity :110 Liters - - Fuel type : lectric- Installation Type : Free Standing- Burner type : nill - Heating Elements 4- Color grey - Control Console : Knob- Voltage : 240 Volts - Wattage : 2800 watts",
    reviews: [
      {
        rating: 4.6,
        text: "je valide",
      },
      {
        rating: 4.9,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "217",
    productName: "CORREDO ENCASTRE CERAMIQUE 2 PLAQUES 30CM COR-2VTC-BH",
    imgUrl: productImg217,
    category: "corredo",
    price: 290,
    slider: '',
    shortDesc:
      "- Electric, stove controlled - with 1 cooking/roasting zone and 1 c. Vario - Nice design - 574mm 574mm wide with flat top  - stainless steel frame- Flexibility: 4 cooking zones with 1 roasting zone, - 1 Vario zone - Safety: residual heat indication for  - each heating zone",
    description:
      "- Electric, stove controlled - with 1 cooking/roasting zone and 1 c. Vario - Nice design - 574mm 574mm wide with flat top  - stainless steel frame- Flexibility: 4 cooking zones with 1 roasting zone, - 1 Vario zone - Safety: residual heat indication for  - each heating zone",
    reviews: [
      {
        rating: 4.6,
        text: "je valide",
      },
      {
        rating: 4.9,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "218",
    productName: "PLAQUES CERAMIQUE ENCASTRE 60X60 H6040",
    imgUrl: productImg218,
    category: "corredo",
    price: 400,
    slider: '',
    shortDesc:
      " 9 Multi-Functions - Contained Cavity Cooling - Fully Programmed Electronic Touch Control- Contained Cavity Cooling - Triple Glazed Removable Inner Glass- Removable Rack Support- Telescopic Runner - Enamel Oven Interior- 2 Oven Shelves & 1 Baking Tray- Black Glass / Stainless Steel Finish",
    description:
      " 9 Multi-Functions - Contained Cavity Cooling - Fully Programmed Electronic Touch Control- Contained Cavity Cooling - Triple Glazed Removable Inner Glass- Removable Rack Support- Telescopic Runner - Enamel Oven Interior- 2 Oven Shelves & 1 Baking Tray- Black Glass / Stainless Steel Finish",
    reviews: [
      {
        rating: 4.6,
        text: "je valide",
      },
      {
        rating: 4.9,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "219",
    productName: "PLAQUES CERAMIQUE ENCASTRE 70X70 H7040",
    imgUrl: productImg219,
    category: "corredo",
    price: 600,
    slider: '',
    shortDesc:
      "- Electric, stove controlled - with 1 cooking/roasting zone and 1 c. Vario - Nice design - 574mm 574mm wide with flat top  - stainless steel frame- Flexibility: 4 cooking zones with 1 roasting zone, - 1 Vario zone - Safety: residual heat indication for  - each heating zone",
    description:
      "- Electric, stove controlled - with 1 cooking/roasting zone and 1 c. Vario - Nice design - 574mm 574mm wide with flat top  - stainless steel frame- Flexibility: 4 cooking zones with 1 roasting zone, - 1 Vario zone - Safety: residual heat indication for  - each heating zone",
    reviews: [
      {
        rating: 4.6,
        text: "je valide",
      },
      {
        rating: 4.9,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "220",
    productName: "CORREDO (OVEN) ENCASTRE 60X60 60BIO",
    imgUrl: productImg220,
    category: "corredo",
    price: 650,
    slider: '',
    shortDesc:
      "ulti-Functions - Contained Cavity Cooling - Fully Programmed Electronic Touch Control- Contained Cavity Cooling - Triple Glazed Removable Inner Glass- Removable Rack Support- Telescopic Runner - Enamel Oven Interior- 2 Oven Shelves & 1 Baking Tray- Black Glass / Stainless Steel Finish",
    description:
      "ulti-Functions - Contained Cavity Cooling - Fully Programmed Electronic Touch Control- Contained Cavity Cooling - Triple Glazed Removable Inner Glass- Removable Rack Support- Telescopic Runner - Enamel Oven Interior- 2 Oven Shelves & 1 Baking Tray- Black Glass / Stainless Steel Finish",
    reviews: [
      {
        rating: 4.6,
        text: "je valide",
      },
      {
        rating: 4.9,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "221",
    productName: "CORREDO FOUR (OVEN) ENCASTRE 60X90 9104MERM",
    imgUrl: productImg221,
    category: "corredo",
    price: 1000,
    slider: '',
    shortDesc:
      "- 3 Colours available: Grey, Black and White- Stainless steel - Auto ignition - Spit- Built-in fan -Gas Bottle Holder Available- Glass Oven Lid- Dimensions: 60×90cm- Oven temperature: 150°c – 250°c- Grill on top a",
    description:
      "- 3 Colours available: Grey, Black and White- Stainless steel - Auto ignition - Spit- Built-in fan -Gas Bottle Holder Available- Glass Oven Lid- Dimensions: 60×90cm- Oven temperature: 150°c – 250°c- Grill on top a",
    reviews: [
      {
        rating: 4.6,
        text: "je valide",
      },
      {
        rating: 4.9,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "222",
    productName: "MINIFOUR 4250MF 42LT OVEN",
    imgUrl: productImg222,
    category: "corredo",
    price: '',
    slider: '',
    shortDesc:
      "Mini Oven - 2 Plaques  -  42 Litre- Color : Grey - Voltage :220- Power source : electric- Temperature: 100-250 degree c adjustable",
    description:
      "Mini Oven - 2 Plaques  -  42 Litre- Color : Grey - Voltage :220- Power source : electric- Temperature: 100-250 degree c adjustable",
    reviews: [
      {
        rating: 4.6,
        text: "je valide",
      },
      {
        rating: 4.9,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },

{/**  {
    id: "223",
    productName: "GENERATEUR 1500L GAS",
    imgUrl: '',
    category: "corredo",
    price: 125,
    slider: '',
    shortDesc:
      "Nous pouvons faire fonctionner ces moteurs à gaz au biogaz, au gaz naturel, au butane, au GPL. Nos générateurs de gaz sont disponibles en version ouverte ainsi qu’en boîtier insonorisé.",
    description:
      "Nous pouvons faire fonctionner ces moteurs à gaz au biogaz, au gaz naturel, au butane, au GPL. Nos générateurs de gaz sont disponibles en version ouverte ainsi qu’en boîtier insonorisé.",
    reviews: [
      {
        rating: 4.6,
        text: "je valide",
      },
      {
        rating: 4.9,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  }, 
   {
    id: "224",
    productName: "MINIFOUR 3541 35LT OVEN",
    imgUrl: productImg224,
    category: "corredo",
    price: 150,
    slider: '',
    shortDesc:
      " Mini Oven - 2 Plaques - 35 Litre- Color : Grey - Voltage :220- Power source : electric- Temperature: 100-250 degree c adjustable",
    description:
      " Mini Oven - 2 Plaques - 35 Litre- Color : Grey - Voltage :220- Power source : electric- Temperature: 100-250 degree c adjustable",
    reviews: [
      {
        rating: 4.6,
        text: "je valide",
      },
      {
        rating: 4.9,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },*/},
  
 
 
  {
    id: "225",
    productName: "MINIOVEN 4250MF 42LT OVEN",
    imgUrl: productImg225,
    category: "corredo",
    price: '',
    shortDesc:
      "ini Oven - 2 Plaques  -  42 Litre- Color : Grey - Voltage :220- Power source : electric- Temperature: 100-250 degree c adjustable",
    description:
      "ini Oven - 2 Plaques  -  42 Litre- Color : Grey - Voltage :220- Power source : electric- Temperature: 100-250 degree c adjustable",
    reviews: [
      {
        rating: 4.6,
        text: "je valide",
      },
      {
        rating: 4.9,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },
 
  {
    id: "226",
    productName: "CORREDO CHEMNY HOOD 60CM 8658SM",
    imgUrl: productImg226,
    category: "corredo",
    price: 400,
    shortDesc:
      "Color: Black - 3 Speeds level- Control Panel : Push button - Carbon FilterOptional- FilterWashable Aluminum Cassette Filter- Air Outlet Flow : Max 420 m3- Height Adjustable Chimney- Chimney Outlet Diameter 120 mm- Lighting Lightbulb E14 Max.40 W",
    description:
      "Color: Black - 3 Speeds level- Control Panel : Push button - Carbon FilterOptional- FilterWashable Aluminum Cassette Filter- Air Outlet Flow : Max 420 m3- Height Adjustable Chimney- Chimney Outlet Diameter 120 mm- Lighting Lightbulb E14 Max.40 W",
    reviews: [
      {
        rating: 4.6,
        text: "je valide",
      },
      {
        rating: 4.9,
        text: "En quelques minutes le resultat",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "227",
    productName: "CORREDO WINECOOLER 385WC 5 WOODEN SHELF",
    imgUrl: productImg227,
    category: "corredo",
    price: 1300,
    slider: '',
    shortDesc:
      "The CORREDO WINECOOLER 385WC 5 WOODEN SHELFis an ageing or conditioning cellar (La Sommelière PRESTIGE range). Manufactured in Europe, the VIP150 lets you, depending on your settings, create up to 5 temperature zones inside the cellar. It is designed for free standing installation and accommodates up to 160 bottles. Supplied with 4 solid oak shelves",
    description:
      "A glass door of understated elegance for a highly effective visual effect; - LED lighting to showcase your bottles; - 4 solid oak fixed shelves included; - 3 digital thermometers; - 1 active charcoal filter to neutralise odours and purify the air; - Excellent cold air distribution (mixed cooling); - 1 visual and audible temperature alarm ensuring a prompt warning in the event of fluctuation; - The Big Chill security option to prevent your bottles from freezing should the external temperature fall between 5°C and 0°C",
    reviews: [
      {
        rating: 4.6,
        text: "Fouani Congo pour tous",
      },
      {
        rating: 4.9,
        text: "Fouani Congo pour tous",
      },
    ],
    avgRating: 4.7,
  },
    // PACO
  {
    id: "228",
    productName: "PACO 1500VA",
    imgUrl: productImg228,
    category: "paco",
    price: 40,
    slider: '',
    shortDesc:
      "Type de relais PACO, régulateur de tension automatique, mètre 1500VA pour les appareils ménagers",
    description:
      "1.Paco est le fabricant spécialisé de régulateur/stabilisateur de tension automatique depuis 20 ans.Nous avons une expérience de fabrication pratique et abondante. 2. Nos produits ont été certifiés CE/CB/ROHS/ISO. Très environnemental et populaire en Afrique, en Australie, en Russie, en Asie du Sud et du Sud-Est, en Amérique du Sud et dans de nombreux autres pays et régions. 3. Notre stabilisateur/régulateur de tension automatique a une large plage de régulation de tension de 140 à 260 V AC/80 à 140 V AC. 4. Indicateurs LED avec affichage numérique d'entrée et de sortie 5. Circuit court et protection contre les surcharges et les surtensions 6. Circuit numérique + transformateur 7.Contrôle du processeur",
    reviews: [
      {
        rating: 4.6,
        text: "je valide",
      },
      {
        rating: 4.9,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "2281",
    productName: "PACO 1000VA",
    imgUrl: productImg2281,
    category: "paco",
    price: 30,
    slider: SliderPaco,
    shortDesc:
      "Type de relais PACO, régulateur de tension automatique, mètre 1500VA pour les appareils ménagers",
    description:
      "1.Paco est le fabricant spécialisé de régulateur/stabilisateur de tension automatique depuis 20 ans.Nous avons une expérience de fabrication pratique et abondante. 2. Nos produits ont été certifiés CE/CB/ROHS/ISO. Très environnemental et populaire en Afrique, en Australie, en Russie, en Asie du Sud et du Sud-Est, en Amérique du Sud et dans de nombreux autres pays et régions. 3. Notre stabilisateur/régulateur de tension automatique a une large plage de régulation de tension de 140 à 260 V AC/80 à 140 V AC. 4. Indicateurs LED avec affichage numérique d'entrée et de sortie 5. Circuit court et protection contre les surcharges et les surtensions 6. Circuit numérique + transformateur 7.Contrôle du processeur",
    reviews: [
      {
        rating: 4.6,
        text: "je valide",
      },
      {
        rating: 4.9,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "2283",
    productName: "PACO 2000VA",
    imgUrl: productImg2283,
    category: "paco",
    price: 50,
    slider: '',
    shortDesc:
      "Type de relais PACO, régulateur de tension automatique, mètre 1500VA pour les appareils ménagers",
    description:
      "1.Paco est le fabricant spécialisé de régulateur/stabilisateur de tension automatique depuis 20 ans.Nous avons une expérience de fabrication pratique et abondante. 2. Nos produits ont été certifiés CE/CB/ROHS/ISO. Très environnemental et populaire en Afrique, en Australie, en Russie, en Asie du Sud et du Sud-Est, en Amérique du Sud et dans de nombreux autres pays et régions. 3. Notre stabilisateur/régulateur de tension automatique a une large plage de régulation de tension de 140 à 260 V AC/80 à 140 V AC. 4. Indicateurs LED avec affichage numérique d'entrée et de sortie 5. Circuit court et protection contre les surcharges et les surtensions 6. Circuit numérique + transformateur 7.Contrôle du processeur",
    reviews: [
      {
        rating: 4.6,
        text: "je valide",
      },
      {
        rating: 4.9,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "2284",
    productName: "PACO 3000VA",
    imgUrl: productImg2284,
    category: "paco",
    price: '130',
    slider: '',
    shortDesc:
      "Type de relais PACO, régulateur de tension automatique, mètre 2000VA pour les appareils ménagers",
    description:
      "1.Paco est le fabricant spécialisé de régulateur/stabilisateur de tension automatique depuis 20 ans.Nous avons une expérience de fabrication pratique et abondante. 2. Nos produits ont été certifiés CE/CB/ROHS/ISO. Très environnemental et populaire en Afrique, en Australie, en Russie, en Asie du Sud et du Sud-Est, en Amérique du Sud et dans de nombreux autres pays et régions. 3. Notre stabilisateur/régulateur de tension automatique a une large plage de régulation de tension de 140 à 260 V AC/80 à 140 V AC. 4. Indicateurs LED avec affichage numérique d'entrée et de sortie 5. Circuit court et protection contre les surcharges et les surtensions 6. Circuit numérique + transformateur 7.Contrôle du processeur",
    reviews: [
      {
        rating: 4.6,
        text: "Fouani congo pour tout le monde",
      },
      {
        rating: 4.9,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "2285",
    productName: "PACO 5000VA",
    imgUrl: productImg2285,
    category: "paco",
    price: 150,
    slider: '',
    shortDesc:
      "Type de relais PACO, régulateur de tension automatique, mètre 1500VA pour les appareils ménagers",
    description:
      "1.Paco est le fabricant spécialisé de régulateur/stabilisateur de tension automatique depuis 20 ans.Nous avons une expérience de fabrication pratique et abondante. 2. Nos produits ont été certifiés CE/CB/ROHS/ISO. Très environnemental et populaire en Afrique, en Australie, en Russie, en Asie du Sud et du Sud-Est, en Amérique du Sud et dans de nombreux autres pays et régions. 3. Notre stabilisateur/régulateur de tension automatique a une large plage de régulation de tension de 140 à 260 V AC/80 à 140 V AC. 4. Indicateurs LED avec affichage numérique d'entrée et de sortie 5. Circuit court et protection contre les surcharges et les surtensions 6. Circuit numérique + transformateur 7.Contrôle du processeur",
    reviews: [
      {
        rating: 4.6,
        text: "je valide",
      },
      {
        rating: 4.9,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },


  //meubles

  {/**
    {
    id: "230",
    productName: "Stone and Beam Westview ",
    imgUrl: productImg230,
    category: "sofa",
    price: '',
    slider: '',
    shortDesc:
      "Ce canapé, avec ses coussins profonds et sa structure robuste, vous aidera à ajouter un maximum de confort et de style à n'importe quel espace de vie de votre maison. Ne croyez jamais que vous devez sacrifier le confort pour le style : ce canapé a les deux à la pelle.",
    description:
      "Ce canapé, avec ses coussins profonds et sa structure robuste, vous aidera à ajouter un maximum de confort et de style à n'importe quel espace de vie de votre maison. Ne croyez jamais que vous devez sacrifier le confort pour le style : ce canapé a les deux à la pelle.",
    reviews: [
      {
        rating: 4.7,
        text: "idéal",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "231",
    productName: "Rivet Bigelow Modern ",
    imgUrl: productImg231,
    category: "sofa",
    price: '',
    slider: '',
    shortDesc:
      "L'inspiration du milieu du siècle se combine avec des touches scandinaves pour une pièce majestueuse qui se marie avec n'importe quel décor. De généreux coussins en mousse offrent un confort, avec une structure et des pieds en bois massif pour plus de soutien et de durabilité.",
    description:
      "Hors tout : 89,4''L x 37,4''P x 30,7''H ; hauteur du siège : 18,1''H ; profondeur du siège : 22,8'' de profondeur ; hauteur du dossier du siège : 18,1'' de hauteur ; hauteur des bras : 24''H ; hauteur des jambes : 5,9''H Structure en bois massif, base en plinthe et pieds coniques inclinés avec rembourrage en mousse et revêtement en tissu",
    reviews: [
      {
        rating: 4.8,
        text: "je valide",
      },
      {
        rating: 4.8,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "232",
    productName: "Amazon Brand Modern Sofa",
    imgUrl: productImg232,
    category: "sofa",
    price: '',
    slider: '',
    shortDesc:
      "STYLE EN MINUTES avec un canapé moderne et intemporel pour s'adapter à n'importe quelle pièce avec la polyvalence que vous souhaitez. C'est la taille idéale pour les petits espaces, notamment les dortoirs universitaires, les bureaux et les petits appartements.",
    description:
      "Cadre et pieds en véritable bois d'eucalyptus pour un soutien robuste et un look raffiné.L'ASSEMBLAGE SANS OUTIL garantit que vous aurez votre canapé en 5 étapes simples.DIMENSIONS : 29''L x 73,5''L x 31,89''H (y compris les coussins). Également disponible dans une causeuse assortie.",
    reviews: [
      {
        rating: 4.6,
        text: "Magnifique",
      },
      {
        rating: 4.9,
        text: "Magnifique",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "233",
    productName: "Stone and Beam Westview ",
    imgUrl: productImg232,
    category: "sofa",
    price: '',
    slider: '',
    shortDesc:
      "Marque Amazon – Stone & Beam Westview Canapé garni de duvet très profond, canapé 89'' de largeur",
    description:
      "Remarque : Pour garantir un assemblage correct, veuillez suivre toutes les étapes fournies dans le manuel d'utilisation (fourni en pdf ci-dessous) Ce canapé, avec ses coussins profonds et sa structure robuste, vous aidera à ajouter un maximum de confort et de style à n'importe quel espace de vie de votre maison. Ne croyez jamais que vous devez sacrifier le confort pour le style : ce canapé a les deux à la pelle. 89''L x 42,1''P x 31,5''H Structure et pieds en bois massif avec revêtement en tissu 100 % polyester durable, sangles robustes et coussins rembourrés en duvet.",
    reviews: [
      {
        rating: 4.7,
        text: "je valide",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "234",
    productName: "Rivet Bigelow Modern ",
    imgUrl: productImg234,
    category: "sofa",
    price: '',
    slider: '',
    shortDesc:
      "Marque Amazon – Canapé moderne Rivet Bigelow avec base en bois, 89,4'' de largeur, gris clair/blond/blue...",
    description:
      "Remarque : Pour garantir un assemblage correct, veuillez suivre toutes les étapes fournies dans le manuel d'utilisation (fourni en pdf ci-dessous) - L'inspiration du milieu du siècle se combine avec des touches scandinaves pour une pièce majestueuse qui se marie avec n'importe quel décor. De généreux coussins en mousse offrent un confort, avec une structure et des pieds en bois massif pour plus de soutien et de durabilité. - Hors tout : 89,4''L x 37,4''P x 30,7''H ; hauteur du siège : 18,1''H ; profondeur du siège : 22,8'' de profondeur ; hauteur du dossier du siège : 18,1'' de hauteur ; hauteur des bras : 24''H ; hauteur des jambes : 5,9''H - Structure en bois massif, base en plinthe et pieds coniques inclinés avec rembourrage en mousse et revêtement en tissu",
    reviews: [
      {
        rating: 4.8,
        text: "A bordable",
      },
      {
        rating: 4.8,
        text: "A bordable",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "235",
    productName: "Amazon Brand Modern Sofa",
    imgUrl: productImg235,
    category: "sofa",
    price: '',
    slider: '',
    shortDesc:
      "Canapé-lit futon convertible, canapé-lit futon en mousse à mémoire de forme, causeuse-lit, petit canapé moderne en lin à dossier divisé pour salon, bureau, appartement, bleu",
    description:
      "【MATÉRIAUX DE HAUTE QUALITÉ】: Notre canapé-lit est rempli de mousse à mémoire de forme de 2,5 ''et d'un rembourrage en coton de soutien, doux et qui ne s'effondre pas, et peut répartir la pression pour améliorer votre confort de sommeil. Le similicuir de qualité supérieure est doux au toucher et pas facile pour se détériorer, la couleur marron classique et élégante permet au canapé de se fondre parfaitement avec votre salon, vous profiterez d'une expérience confortable pour les années à venir. 【CONCEPTION MULTIFONCTIONNELLE】: Nos canapés-lits sont conçus pour vous offrir un confort ultime. Le canapé-lit multifonctionnel peut être divisé en deux moitiés pour une utilisation séparée ou un contrôle simultané, et en ajustant l'angle des dossiers et des accoudoirs, le futon peut être facilement converti d'un canapé en chaise longue ou en lit",
    reviews: [
      {
        rating: 4.6,
        text: "je valide",
      },
      {
        rating: 4.9,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "236",
    productName: "Stone and Beam Westview ",
    imgUrl: productImg236,
    category: "sofa",
    price: '',
    slider: '',
    shortDesc:
      "Marque Amazon – Stone & Beam Westview Canapé garni de duvet très profond, canapé 89'' de largeur, crème, blue ...",
    description:
      "Ce canapé, avec ses coussins profonds et sa structure robuste, vous aidera à ajouter un maximum de confort et de style à n'importe quel espace de vie de votre maison. Ne croyez jamais que vous devez sacrifier le confort pour le style : ce canapé a les deux à la pelle.",
    reviews: [
      {
        rating: 4.7,
        text: "je valide",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "237",
    productName: "Rivet Bigelow Modern",
    imgUrl: productImg237,
    category: "sofa",
    price: '',
    slider: '',
    shortDesc:
      "Marque Amazon – Chaise d'appoint moderne surdimensionnée Rivet Bigelow avec base en bois, 44,1'' de largeur, bleu marine/blond",
    description:
      "L'inspiration du milieu du siècle se combine avec des touches scandinaves pour une pièce majestueuse qui se marie avec n'importe quel décor. De généreux coussins en mousse offrent un confort, avec une structure et des pieds en bois massif pour plus de soutien et de durabilité.",
    reviews: [
      {
        rating: 4.8,
        text: "je valide",
      },
      {
        rating: 4.8,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  }, */},

  {
    id: "250",
    productName: "Perkins 10KVA - 25KVA DIESEL GENERATOR",
    imgUrl: productImg250,
    category: "perkins",
    price: '',
    slider: '',
    shortDesc:
      "10KVA Diesel GENERATOR 6000$, 12KVA Diesel GENERATOR 8000$, 15KVA Diesel GENERATOR 8500$, 20KVA Diesel GENERATOR 9800$,",
    description:
      "Les générateurs diesel sont connus pour leur efficacité énergétique. Ils sont conçus pour convertir une grande partie du carburant en énergie utile, ce qui signifie que vous obtenez plus d'énergie pour chaque litre de carburant que vous utilisez. C'est un avantage majeur pour ceux qui cherchent à réduire leurs coûts de carburant. Beaucoup de gens sont surpris d'apprendre que les générateurs diesel sont souvent plus silencieux que leurs homologues à essence. C'est parce qu'ils fonctionnent à des vitesses de moteur plus basses, ce qui réduit le niveau de bruit qu'ils produisent.",
    reviews: [
      {
        rating: 4.8,
        text: "Les groupes électrogènes diesel sont devenus un choix populaire pour ceux qui cherchent une source d'énergie fiable et durable. ",
      },
      {
        rating: 4.8,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "251",
    productName: "Perkins 25KVA - 45KVA DIESEL GENERATOR",
    imgUrl: productImg251,
    category: "pekins",
    price: '',
    slider: '',
    shortDesc:
      "25KVA Diesel GENERATOR 11000$, 33KVA Diesel GENERATOR 14200$,",
    description:
      "Les générateurs diesel sont connus pour leur efficacité énergétique. Ils sont conçus pour convertir une grande partie du carburant en énergie utile, ce qui signifie que vous obtenez plus d'énergie pour chaque litre de carburant que vous utilisez. C'est un avantage majeur pour ceux qui cherchent à réduire leurs coûts de carburant. Beaucoup de gens sont surpris d'apprendre que les générateurs diesel sont souvent plus silencieux que leurs homologues à essence. C'est parce qu'ils fonctionnent à des vitesses de moteur plus basses, ce qui réduit le niveau de bruit qu'ils produisent.",
    reviews: [
      {
        rating: 4.8,
        text: "Les groupes électrogènes diesel sont devenus un choix populaire pour ceux qui cherchent une source d'énergie fiable et durable. ",
      },
      {
        rating: 4.8,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "252",
    productName: "Perkins 45KVA - 70KVA DIESEL GENERATOR",
    imgUrl: productImg252,
    category: "perkins",
    price: '',
    slider: '',
    shortDesc:
      "45KVA Diesel GENERATOR 15700$, 50KVA Diesel GENERATOR 17500$, 60KVA Diesel GENERATOR 19000$,",
    description:
      "Les générateurs diesel sont connus pour leur efficacité énergétique. Ils sont conçus pour convertir une grande partie du carburant en énergie utile, ce qui signifie que vous obtenez plus d'énergie pour chaque litre de carburant que vous utilisez. C'est un avantage majeur pour ceux qui cherchent à réduire leurs coûts de carburant. Beaucoup de gens sont surpris d'apprendre que les générateurs diesel sont souvent plus silencieux que leurs homologues à essence. C'est parce qu'ils fonctionnent à des vitesses de moteur plus basses, ce qui réduit le niveau de bruit qu'ils produisent.",
    reviews: [
      {
        rating: 4.8,
        text: "Les groupes électrogènes diesel sont devenus un choix populaire pour ceux qui cherchent une source d'énergie fiable et durable. ",
      },
      {
        rating: 4.8,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "253",
    productName: "Perkins 70KVA - 200KVA DIESEL GENERATOR",
    imgUrl: productImg253,
    category: "perkins",
    price: '',
    slider: '',
    shortDesc:
      "70KVA Diesel GENERATOR 20000$,100KVA Diesel GENERATOR 24000$, 125KVA Diesel GENERATOR 26000$, 150KVA Diesel GENERATOR 29000$, ",
    description:
      "Les générateurs diesel sont connus pour leur efficacité énergétique. Ils sont conçus pour convertir une grande partie du carburant en énergie utile, ce qui signifie que vous obtenez plus d'énergie pour chaque litre de carburant que vous utilisez. C'est un avantage majeur pour ceux qui cherchent à réduire leurs coûts de carburant. Beaucoup de gens sont surpris d'apprendre que les générateurs diesel sont souvent plus silencieux que leurs homologues à essence. C'est parce qu'ils fonctionnent à des vitesses de moteur plus basses, ce qui réduit le niveau de bruit qu'ils produisent.",
    reviews: [
      {
        rating: 4.8,
        text: "Découvrez les avantages des groupes électrogènes diesel et notre sélection des meilleurs générateurs ",
      },
      {
        rating: 4.8,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "254",
    productName: "Perkins 200KVA - 500KVA DIESEL GENERATOR",
    imgUrl: productImg254,
    category: "perkins",
    price: '',
    slider: '',
    shortDesc:
      "200KVA Diesel GENERATOR 45000$, 250KVA Diesel GENERATOR 57000$, 350KVA Diesel GENERATOR 80000$, CUMMINS 500KVA 98000$,",
    description:
      "Le générateur diesel est une solution d’alimentation électrique fiable et efficace, largement utilisée dans divers secteurs industriels et résidentiels.",
    reviews: [
      {
        rating: 4.8,
        text: "Aujourd'hui, avec l'augmentation rapide de la consommation d'électricité dans tous les domaines de la vie",
      },
      {
        rating: 4.8,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "255",
    productName: "SACO DIESEL GENERATOR",
    imgUrl: productImg255,
    category: "saco",
    price: '',
    slider: '',
    shortDesc:
      "- SA 3500E  with time meter 450$, -SA5500E With Wheel and Handle, time 600$, - SA6500EW With Welding Wheel 1600$, -SA6500E With Wheel  900$ - 8000S Diesel GENERATOR 1800$,  - 8000T Diesel GENERATOR 3 Phases 2000$, - 8500S Diesel GENERATOR 1400$, - 10000S Diesel GENERATOR 3300$ ",
    description:
      "Les moteurs diesel sont connus pour leur robustesse et leur durabilité, ce qui rend les générateurs diesel particulièrement fiables pour une utilisation continue ou d’urgence.  Le générateur diesel est une solution d’alimentation électrique fiable et efficace, largement utilisée dans divers secteurs industriels et résidentiels. ",
    reviews: [
      {
        rating: 4.8,
        text: "Découvrez les avantages des groupes électrogènes diesel et notre sélection des meilleurs générateurs ",
      },
      {
        rating: 4.8,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "256",
    productName: "SACO DIESEL GENERATOR",
    imgUrl: productImg256,
    category: "saco",
    price: '',
    slider: '',
    shortDesc:
      "- SA 3500E  with time meter 450$, -SA5500E With Wheel and Handle, time 600$, - SA6500EW With Welding Wheel 1600$, -SA6500E With Wheel  900$ - 8000S Diesel GENERATOR 1800$,  - 8000T Diesel GENERATOR 3 Phases 2000$, - 8500S Diesel GENERATOR 1400$, - 10000S Diesel GENERATOR 3300$",
    description:
      "Les moteurs diesel sont connus pour leur robustesse et leur durabilité, ce qui rend les générateurs diesel particulièrement fiables pour une utilisation continue ou d’urgence.  Le générateur diesel est une solution d’alimentation électrique fiable et efficace, largement utilisée dans divers secteurs industriels et résidentiels.",
    reviews: [
      {
        rating: 4.8,
        text: "je valide",
      },
      {
        rating: 4.8,
        text: "je valide",
      },
    ],
    avgRating: 4.7,
  },
];

export default products;
